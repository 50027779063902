import { Obj } from "./obj";

// import { router } from "./router";
import { abort_promise } from "./apromise";
import { clone, ocopy } from "./util";
import { router } from "../api/router";
import { source } from "./source";
import { SearchArg } from "./faces";
import { DynamicScroll } from "../components/dynamic-scroll";
import { watch } from "./watch";
import { form } from "./form";


export interface search_result { detail: any[], resume: any };

export class Search extends Obj {
    data: any[] = [];
    resume: any = {}

    buffer: any[] = []
    sub_data: string;
    url: string
    url_add: string;
    url_edit: string;
    url_detail: string;

    scroll: DynamicScroll;
    items = 0;
    active_search = true;

    private off_request = false;
    private started = false
    private promise: Promise<any> | undefined;

    params: any = {}


    constructor(url?: string, opt: { add?: string, edit?: string, detail?: string } = {}) {
        super()
        this.url = url
        this.url_add = opt.add
        this.url_edit = opt.edit
        this.url_detail = opt.detail

        this.on('detail', (x) => {

            if (this.url_detail)
                router.modal(this.url_detail, x)
                    .then(x => this.request())
                    .catch(e => e)

            if (this.url_edit) {
                router.modal(this.url_edit, x)
                    .then(x => this.request())
                    .catch(e => e)
            }

        })


        this.on('add', (x) => {
            if (this.url_add) {
                router.modal(this.url_add)
                    .then(x => this.request())
                    .catch(e => console.error(e))

            }
        })

        this.on('search', (v) => {
            this.params.search = v
        })
    }

    active_scroll(active: boolean = true) {
        if (this.scroll) this.scroll.active = active
    }

    start(x?) {
        if (this.started) return
        var search: any = this;
        search.params = search.params || {};
        search.params.search = search.params.search || ''
        search.params.page = search.params.page || 1
        search.params.pages = search.params.pages || 0
        search.params.limit = search.params.limit || 50

        Object.keys(this.params).forEach(param => {
            watch(this.params, param, () => {
                if (!this.off_request) {
                    this.active_scroll()
                    this.request()
                }
            })
        })
        this.started = true
        return this.request(x)
    }

    save(data: any) {
        if (!data) return this
        let i = this.buffer.findIndex(x => x.key == data.key);
        if (i < 0) this.buffer.push(data);
        this.trigger('valid', data)
        return this
    }

    more() {

        this.set_params({ limit: this.params.limit * 2 })
        return this.request({ page: this.params.page + 1, more: true })
    }

    refresh() {
        for (let buffer of this.buffer) {
            let row = this.data.find(x => x.key == buffer.key);
            if (row) {
                for (let i of Object.keys(buffer)) {
                    row[i] = buffer[i]
                }
            }
        }
    }


    set_params(args: any) {
        this.off_request = true;
        ocopy(args, this.params)
        this.off_request = false;
    }

    request(args: SearchArg = {}, data?: any[]) {
        if (!this.started) return
        data = data || this.data
        const more = args.more
        delete args.more;

        if (!more) args.page = 1

        //  aplicando los parametros recibidos a las propiedades de search
        this.set_params(args)

        this.params.updates = '{}'
        if (this.params.only_updates) {
            this.params.updates = '{' + this.buffer.map(x => x.key).join(',') + '}'
        }

        this.params.master = this.params.master || ''

        //  cancelo la promesa anterior 
        if (this.promise) {
            abort_promise(this.promise);
            this.promise = undefined
        }

        //  realizo la consulta
        this.promise = source().request(this.url, clone(this.params))

        this.promise.then(async (r: search_result) => {
            this.promise = undefined

            r.detail = r.detail || []

            //  si se esta paginando se limpia los datos anteriores
            if (!more) while (data.length) data.shift();
            if (!r.detail && r.detail.length) this.active_scroll(false)
            await this.trigger('pre-load', r)

            //  se agregan los resultados a data

            var fn = (row) => {
                let i = this.buffer.findIndex(x => x.key == row.key)
                if (i >= 0) {
                    var data = clone(row)
                    this.buffer[i] = ocopy(this.buffer[i], row)
                }
            }
            r.detail.forEach((x) => {
                if (this.sub_data) x[this.sub_data].forEach(fn)
                else fn(x)
                data.push(x)
            })

            //  se obtiene el total de registros
            this.items = r.resume.items
            if (typeof r.resume == 'object' && r.resume instanceof Array == false) ocopy(r.resume, this.resume)
            //  se dispara el trigger request
            this.trigger('request', r)
            return r;
        })
        //  retorno los resultados
        return this.promise;
    }

    async update_buffer(fn: (buffer, row) => void) {
        var args = clone(this.params)
        args.limit = 99999999999
        args.page = 1
        args.updates = '{' + this.buffer.map(x => x.key).join(',') + '}'
        var data = await source().request(this.url, args)
        if (this.buffer.length) {
            data.detail.forEach((row) => {
                var buffer;
                if (this.sub_data) row[this.sub_data].forEach((row) => {

                    buffer = this.buffer.find(x => x.key == row.key)
                    fn(buffer, row)
                })
                else {
                    buffer = this.buffer.find(x => x.key == row.key)
                    fn(buffer, row)
                }
            })
        }
    }

    async request_all(arg?: SearchArg, data?: any[]) {
        arg = arg || {}
        var copia = clone(this.params)

        arg.limit = 9999999999
        arg.page = 1
        await this.request(arg, data)
        this.set_params(arg)
        return data
    }


}
