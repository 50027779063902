import { upload } from '../../../api/http'
import { Obj } from '../../../api/obj'
import { move } from '../../../api/util'
import isMobile from '../../../node_modules/is-mobile/index'
import template from './camera.form.html'

export default class extends Obj {
    static template = template
    title = "Tomar foto"
    dom: HTMLElement
    confirmar = false;
    nota_display = false;
    nota = 'asdfasd'

    init() {

        const marco: HTMLDivElement = this.dom.getElementsByTagName('div')[0]
        const video = document.getElementsByTagName('video')[0]
        const canvas = document.getElementsByTagName('canvas')[0];
        var context = canvas.getContext('2d');

        video.addEventListener("click", () => {
            this.confirmar = true
            setTimeout(() => {
                context.drawImage(video, video.getBoundingClientRect().left, video.getBoundingClientRect().top);
            }, 200);
        });
        canvas.addEventListener("click", () => {
            canvas.width = canvas.width;

            this.confirmar = false
        });

        setTimeout(async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { width: (isMobile() ? 360 : 800), height: marco.getBoundingClientRect().height } });
                video.srcObject = stream;
                canvas.setAttribute('width', '800px')
                canvas.setAttribute('height', '600px')
            } catch (e) {
                // errorMsgElement.innerHTML = `navigator.getUserMedia error:${e.toString()}`;
            }


        }, 100);

        return new Promise((ok, fail) => {




            this.on('accept', async () => {
                if (!this.nota_display) {
                    this.nota_display = true
                    return
                }
                canvas.toBlob(async (blob) => {
                    const buffer = await blob.arrayBuffer()
                    var b: any = blob
                    b.lastModifiedDate = new Date();
                    b.name = 'imagen.png';
                    var file = <File>b;
                    ok(await upload([file]));
                })
            })
            this.on('cancel', fail)
        })
    }
}