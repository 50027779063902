import { source } from "../../../api/source"
import { ocopy } from "../../../api/util"

export class Info_orden {
    id: string = ''
    referencia: string = ''
    nombre: string = ''
    entidad: string = ''
    detalle: { merc: string, nombre: string, unidad: string, ordenado: number, recogido: number }[] = []

    reset(info_orden: Info_orden) {
        if (!info_orden) return

        ocopy(info_orden, this)

    }

    async request(orden: string) {
        var r = await source().request('/wms.despacho/finalizar-orden', { orden })
        this.reset(r)

    }
}