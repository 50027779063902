import { form } from '../../../api/form'
import { search_result } from '../../../api/search'
import { source } from '../../../api/source'
import { filter } from '../../sistema/filtro/filtro'
import template from './wms.consulta-vencimiento.html'
import Excel from 'exceljs'
import { dformat, download } from '../../../api/util'
import { router } from '../../../api/router'

export default class extends form.search(template, '/wms.consulta/consulta-vencimiento') {

    active_filter = true
    active_download = true
    params = { vence_status: '', fecdes: new Date(), fechas: new Date() }
    init() {

        this.on('download', async (r) => {
            this.download()
        })
        this.on('filter', async (r) => {
            var r = await filter(this.params)
        })

        this.on('pre-load', (r: search_result) => {
            r.detail.forEach((x) => {
                x.cantidad = parseInt(x.cantidad + '')
                x.danger = parseInt(x.danger + '')
                x.warning = parseInt(x.warning + '')
            })
        })
        return form.done(this)
    }
    ver_detalle(x) {
        router.modal('/wms/inventario-merc', x)
    }
    async download() {
        var r = await source().request('/wms.consulta/consulta-vencimiento-download', this.params)
        var wb = new Excel.Workbook();
        // PRODUCTOS
        var productos = wb.addWorksheet('Productos')

        // columnas
        productos.columns = [
            { header: 'Codigo', key: 'id', width: 15 },
            { header: 'Nombre', key: 'nombre', width: 40 },
            { header: 'Vence', key: 'vence', width: 15 },
            { header: 'Dias', key: 'dias', width: 15 },
            { header: 'Cantidad', key: 'cantidad', width: 15 }
        ];

        // formato a columnas
        productos.getRow(1).font = { bold: true }
        productos.getCell('D1').alignment = { horizontal: 'right' };
        productos.getCell('E1').alignment = { horizontal: 'right' };

        // agregando contenido
        r.forEach((producto) => {
            producto.detalle.forEach((vence) => {
                vence.id = producto.id
                vence.nombre = producto.nombre
                productos.addRow(vence)
            })
        })

        // titulo
        var title = productos.insertRow(1, ['Relacion de Vencimiento'])
        title.font = { bold: true, size: 16 }

        var status: any = {};
        status[""] = 'Todo'
        status["vencido"] = 'Ya vencido'
        status["danger"] = 'En Emergencia'
        status["warning"] = 'Proximo a Vencerse'
        status["3"] = 'En 3 semanas'
        status["4"] = 'En 4 semanas'
        status["5"] = 'En 5 semanas'
        status["6"] = 'En 6 semanas'
        status["7+"] = 'En 7 semanas o mas'

        // //  parametros
        productos.insertRow(2, ['Filtro Vencimiento: ' + status[this.params.vence_status]])

        //  descargar
        var data = await wb.xlsx.writeBuffer()
        download(new File([data], 'consulta-vencimiento.xlsx'));
    }
}