import { Data } from '../../../api/data';
import { dia_mes_list, mes_list } from '../../../api/list';
import { Obj } from '../../../api/obj'
import { Type } from '../../../api/type';
import { anio_list } from './wms.recepcion.list';
import template from './wms.recepcion.contar.html'
import { router } from '../../../api/router';
import { date_diff, ocopy } from '../../../api/util';
import { create_dialog } from '../../../api/dialog';
import { close_wait, layout, notify, wait } from '../../../components/layout';
import { source } from '../../../api/source';
import { get_impresora, set_impresora } from '../../sistema/impresion/impresion.seleccionar-impresora';

const confirmar_vencidas = create_dialog({
    title: 'Confirmacion de Vencimiento',
    message: 'Esta mercancia está vencida o proxima a vencerse, especifique si la va recibirlo como RETENIDO o de forma NORMAL',
    no_btn_close: true,
    no_close: true,
    btns: [
        { value: 'retener', title: 'RETENIDO', class: "btn-success" },
        { value: 'normal', title: 'NORMAL', class: "btn-warning" },
        { value: 'cancelar', title: 'Cancelar', class: "btn-default" },
    ]
});



export default class extends Data {
    static template = template

    id = new Type.String({ title: 'ID' });
    descrip = new Type.String({ title: 'Nombre' })
    unidad = new Type.String({ title: 'Unidad' })
    vence = new Type.Day({ title: 'Vence' })
    lote = new Type.String({ title: 'Lote' })
    cantidad = new Type.Number({ title: 'Cantidad' })
    averiadas = new Type.Number({ title: 'Averiadas' })
    dia = new Type.Link({ list: dia_mes_list, title: 'Dia' })
    mes = new Type.Link({ list: mes_list, title: 'Mes' })
    anio = new Type.Link({ list: anio_list, title: 'Año' })
    referencia = new Type.String()
    orden = new Type.String()
    entidad = new Type.String()

    maneja_vence = new Type.Boolean();
    maneja_lote = new Type.Boolean();
    vida_util = new Type.Number();
    active_note = true
    layout = layout

    init(x) {
        //  cargando los datos

        this.reset(x)
        this.layout.title = 'Orden: ' + x.referencia

        // configurando combos de fecha
        var valida_fecha = () => {

            if (['1', '3', '5', '9', '10'].find(x => x == this.mes.value)) {
                if (this.dia.value == '31') {
                    this.dia.value = '30'
                }

                if (this.mes.value == '1') {
                    if (this.dia.value == '30') {
                        this.dia.value = '29'
                    }

                    if (this.dia.value == '29' && this.anio.value && parseInt(this.anio.value) % 4 > 0) {
                        this.dia.value = '28'
                    }
                }
            }
        }

        this.mes.watch('value', valida_fecha)
        this.dia.watch('value', valida_fecha)
        this.anio.watch('value', valida_fecha)



        if (x.maneja_lote) {
            this.lote.required = true
        }
        if (x.maneja_vence) {
            setTimeout(() => {
                var part = x.vence.split('-')

                var vence = new Date(parseInt(part[0]), parseInt(part[1]) - 1, parseInt(part[2]))
                this.dia.value = vence.getDate() + ''
                this.mes.value = vence.getMonth() + ''
                this.anio.value = vence.getFullYear() + ''

                this.anio.required = true
                this.mes.required = true
                this.dia.required = true

            }, 100);
        }

        this.on('nota', (r) => {
            router.modal('/nota', { control: x.nota, referencia: x.id, tipos: ['Nota', 'Averia', 'Vencimiento', 'Diferencia'] })
        })


        //  retornando promesa
        return new Promise<void>(async (ok, fail) => {
            this.on('accept', async () => {



                try {
                    await this.valid()
                    if (!this.cantidad.value && !this.averiadas.value) {
                        this.cantidad.error('Cantidad es Requerida')
                        return Promise.reject('Especifique la cantidad')
                    }

                    //  calcula la fecha de vencimiento
                    this.vence.value = new Date(parseFloat(this.anio.value), parseFloat(this.mes.value), parseFloat(this.dia.value))


                    //  obteniendo los datos
                    var data = this.get()
                    data.tipo_retencion = null

                    //  calcula los dias que faltan para de vencimiento
                    var dias_vence = date_diff(new Date(), this.vence.value).days()

                    //  determina si hay vencimiento
                    data.retenido_vence = false;
                    if (dias_vence <= 0 || dias_vence < this.vida_util.value / 2) {
                        alert('Esta tratando de ingresar mercancias con vencimiento menor a la util de la mercancia\n\nEsto no esta permitido!\n\nConfirme los datos o comuniquese con su supervisor')
                        return
                        var confirm = await confirmar_vencidas()
                        if (confirm == 'cancelar') return

                        if (confirm == 'retener') {
                            data.tipo_retencion = 'VENCIMIENTO'
                        }
                    }

                    //  procesa los datos
                    try {
                        var impresora = await get_impresora()
                        if (!impresora) impresora = await set_impresora()

                        data.impresora = impresora

                        wait('Espere mientras se procesan los datos')
                        await source().request('/wms.recepcion/contar', data)
                        close_wait()
                        notify.success('Operacion completada!!!')
                        ok()
                    } catch (e) {
                        close_wait()
                        notify.error(e)
                    }
                }

                catch (e) {
                    this.error(e)
                }

            })


            this.on('cancel', fail)
        })
    }
}




