import { get_control } from "./get_control";
import { Type } from "./type";

export interface GroupArg {
    name: string
    expr?: string
    header?: string[]
    detail?: string[]
    order?: string
}

export function group(data: any[], arg: GroupArg[]) {
    var g: any = {}
    var x = arg[0]
    x.expr = x.expr || x.name

    x.header = x.header || (x.name == 'root' ? [] : [x.expr]);

    data.forEach(function (row) {
        var index: any = get_control(x.expr, row).value
        if (index instanceof Type.Instance) {
            index = get_control('value', index).value
        }

        g[index] = g[index] || []
        g[index].push(row)
    })

    var ordered = Object.keys(g).sort().map(x => g[x])

    var detail = ordered.map(function (i: any[]) {
        var r: any = {}
        for (let h of x.header) {

            r[h] = i[0][h]
        }
        var sub = arg[1]

        if (sub) {
            if (x.detail) console.error(`group: La propiedad detail debe ser especificada en el ultimo grupo, no sera tomada en cuenta!!`)
            r[sub.name] = group(i, arg.slice(1))
        }
        else {
            if (x.detail) {
                r.detail = i.map(function (row) {
                    var result: any = {}
                    for (let c of x.detail) result[c] = row[c]
                    return result
                })
            }
            else r.detail = i
        }

        return r
    })



    return detail;
}
