import { form, Form } from '../../../api/form'
import session from '../../../api/session'
import { dformat, replace_array } from '../../../api/util'
import { close_wait, notify, wait } from '../../../components/layout'
import { importer } from '../../sistema/import/import.form'
import { precio } from './precio.class'
import template from './precio.add.html'
import { Obj } from '../../../api/obj'
import { Type } from '../../../api/type'
import { tcliente_list } from '../../cliente/tcliente/tcliente.list'
import { Data } from '../../../api/data'
import { source } from '../../../api/source'

export default class extends Data {
    static template = template
    title = 'Agregando lista de precio'
    tcliente = new Type.Link({ list: tcliente_list, title: 'Tipo de Cliente', required: true })
    fecha = new Type.Day({ required: true })
    data = []
    constructor() {
        super()
        this.fecha.value = new Date()
        this.on('error', (x) => notify.error(x))
        this.on('notify', (x) => notify.success(x))
        this.on('accept', async () => {
            await this.valid()
            if (!this.data.length) return Promise.reject('Se requiere que cargue la lista de precio')
            var send = this.get();
            send.lista = this.data;
            await source().request('/precio/crear', send)
            notify.success('Lista creada con exito')
        })
    }
    init() {

        return form.done(this)
    }

    async cargar_lista() {
        var r = await importer({
            columns: [
                { header: 'Tipo', key: 'tipo', width: 15 },
                { header: 'Codigo', key: 'merc', width: 15 },
                { header: 'Nombre', key: 'nombre', width: 40 },
                { header: 'Unidad', key: 'unidad', width: 15 },
                { header: 'Precio', key: 'precio', width: 15 },
            ],
            data: await source().request('precio/plantilla'),
            file_name: 'carga-lista-precio.xlsx'
        })
        replace_array(this.data, r)
    }
}