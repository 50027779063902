import { wrap } from "module";
import { domain } from "process";
import { ControlSource, get_control } from "../api/get_control";
import { upload } from "../api/http";
import { input } from "../api/input";
import { Obj } from "../api/obj";
import { router } from "../api/router";
import { source } from "../api/source";
import { Type } from "../api/type";
import { create_element, ocopy, replace_array } from "../api/util";

//  Este es el textbox del sistema
export namespace Control {

    export class Input extends Obj {
        props = ['title', 'error_msg']
        control: ControlSource;
        title = 'Texto'
        error_msg = ''
        visible = true
        content = []
        dom: HTMLElement
        setup(dom: HTMLElement) {
            this.watch('control', (v) => {
                var box: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement = dom.querySelector('input') || dom.querySelector('select') || dom.querySelector('textarea');

                //  poniendo la envoltura a escuchar las propiedades del campo poniendo :+la propiedad
                if (this.control instanceof Type.Instance) {

                    this.title = this.control.title
                    this.error_msg = this.error_msg

                    this.control.watch('title', v => this.title = v)
                    this.control.watch('visible', v => this.visible = v)
                    this.control.watch('error_msg', v => this.error_msg = v)

                    if (this.control instanceof Type.Link && this.control.list) {
                        var control: Type.Link = this.control;
                        var refresh = () => {
                            replace_array(this.content, control.list.data)
                        }
                        refresh()
                        this.control.list.watch('data', refresh)
                    }

                    var tag = dom.getAttribute('tag')
                    if (tag) box.setAttribute('tag', tag)
                    var disabled = dom.getAttribute('disabled')
                    if (disabled) box.setAttribute('disabled', disabled)
                }


                // //  conectando con el input
                if (box instanceof HTMLInputElement) {

                    if (box.type == 'checkbox') {

                        if (this.control instanceof Type.Array) input.array_check(box, this.control)
                        else {
                            input.check(box, this.control)
                        }
                    }
                    else input.textbox(box, this.control)
                }
                else if (box instanceof HTMLTextAreaElement) {
                    input.textarea(box, this.control)
                }
                else if (box instanceof HTMLSelectElement) {
                    input.listbox(box, this.control)
                }

            })
        }

    }


    export class Capture extends Obj {
        static template =/*html*/ `
            <div class="capture">
                <img name="imagen" >
                <span class="capture-file" @click="file()">
                    <icon name="folder-open"></icon>
                </span>
                <span class="capture-camera" @click="imagen()">
                    <icon name="camera"></icon>
                </span>
                <input type="file" hidden/>
            </div>
        `
        control: ControlSource;
        dom: HTMLElement
        file() {
            var input = this.dom.getElementsByTagName('input')[0]
            input.click()
        }

        async imagen() {
            this.control.value = (await take_photo())[0]
        }

        setup(dom: HTMLElement) {
            var input_file = dom.getElementsByTagName('input')[0];
            input_file.addEventListener('change', async () => {
                this.control.value = (await upload(input_file.files))[0]
            })
            dom.getElementsByTagName('img')[0].setAttribute('style', "background-image:url('" + source().url + '/img/camera.png' + "')")
            this.watch('control', () => {
                var refresh = () => {
                    dom.getElementsByTagName('img')[0].src = source().url + this.control.value
                }
                if (this.control instanceof Type.Instance) this.control.watch('value', refresh)
                else this.control.watch(refresh)
                refresh();
            })



        }
    }

    export class List extends Input {
        static template =/*html*/ `
            <div class="form-floating  mb-3" :visible="visible">
                <select class="form-select" c-class='{"border-danger is-invalid":!!error_msg}'>
                    <option c-for="item in content" :value="item.id" :include="include">
                    {{item.display||item.nombre}}
                    </option>
                </select>
                <label>{{title}}</label>
                <small class="form-text text-muted">{{error_msg}}</small>
            </div>
        `
        selector = 'select'
        props = ['data'];
        data = [];
        constructor() {
            super()
            this.watch('data', () => {
                while (this.content.length) this.content.shift();
                ocopy(this.data, this.content)
            })
        }
    }
    export class Text extends Input {
        static template = /*html*/`
            <div class="form-floating mb-3">
                <input type="text" class="form-control" placeholder="." c-class='{"is-invalid":error_msg}'>
                <label >{{title}}</label>
                <small class="form-text text-muted">{{error_msg}}</small>
            </div>
        `
    }


    export class Edit extends Input {
        static template = /*html*/`
            <div class="form-floating mb-3 h-100" >
                <textarea type="text" class="form-control h-100" placeholder="." c-class='{"is-invalid":error_msg}' :enabled="enabled"></textarea>
                <label >{{title}}</label>
                <small class="form-text text-muted">{{error_msg}}</small>
            </div>
        `
    }

    export class Pass extends Input {
        static template = /*html*/`
            <div class="form-floating mb-3">
                <input type="password" class="form-control" placeholder=".">
                <label >{{title}}</label>
                <small class="form-text text-muted">{{error_msg}}</small>
            </div>
        `
    }
    export class Number extends Input {
        static template = /*html*/`
            <div class="form-floating mb-3" >
                <label>{{title}}</label>
                <input type="number" class="form-control" c-class='{"border-danger is-invalid":!!error_msg}' min=0>
                <small class="form-text text-muted">{{error_msg}}</small>
            </div>
        `
    }

    export class Date extends Input {
        static template = /*html*/`
                <div class="form-floating">
                    <input type="date" class="form-control" placeholder="..." c-class='{"border-danger is-invalid":!!error_msg}'>
                    <label for="floatingInputValue">{{title}}</label>
                    <small class="form-text text-muted">{{error_msg}}</small>
                </div>

        `
        // <div class="form-floating" >
        //     <input type="date" class="form-control" c-class='{"border-danger is-invalid":!!error_msg}'>
        //     <label>{{title}}</label>
        // </div>

    }
    export class Check extends Input {
        static template = /*html*/`
        <div class="form-group form-check"  >
            <input type="checkbox" class="form-check-input">
            <label class="form-check-label">{{title}}</label>
        </div>
        `
    }
}

export class Btn {
    static template = /*html*/`
        <button c-class="btn {{color?'btn-'+color:''}}">
            <icon class="fa" :visible="!!icon" :name="icon" c-style="{'padding-right':(!!title?'6px':'0px')}" ></icon><span>{{title||''}}</span>
        </button>
    `
    props = ['title', 'color', 'icon']
    color: string = ''
    icon: string = ''
    title = ''
}

export async function take_photo() {
    return await router.modal('/camera')

}


export function get_file(): Promise<File[]> {
    return new Promise((ok, fail) => {
        var input: HTMLInputElement = <any>create_element('<input type="file" hide=true multiple>')
        document.body.append(input)
        input.addEventListener('change', () => {
            var r = []
            for (let i = 0; i < input.files.length; i++)r.push(input.files[i])
            ok(r)
        })
        input.click()
        setTimeout(() => {
            input.remove()
        }, 200);
    })
}

export function read_file(file: File) {
    return new Promise((ok, fail) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = () => {
            ok(reader.result);
        }
    })
}

export default {
    edit: Control.Edit,
    capture: Control.Capture,
    list: Control.List,
    text: Control.Text,
    pass: Control.Pass,
    number: Control.Number,
    date: Control.Date,
    check: Control.Check,
    btn: Btn
}