import { Data } from '../../../api/data';
import { form } from '../../../api/form';
import { dia_mes_list, mes_list } from '../../../api/list';
import { Obj } from '../../../api/obj'
import { Type } from '../../../api/type';
import { date_add, download, ocopy } from '../../../api/util';
import { layout } from '../../../components/layout';
import { Mercancia } from './wms.inventario.class';
import { merc_inventario_ubicacion } from './wms.inventario.list';
import Excel from 'exceljs'
import template from './wms.inventario.merc.html'

export default class extends Mercancia {
    static template = template
    title = "Mercancia"
    // merc = new Mercancia()
    layout = layout
    fecdes = date_add({ days: -15 })
    fechas = new Date()
    active_download = true
    mostrar_movimiento = "movimiento"

    init(x) {
        this.reset(x)
        this.request(x.id, this.fecdes, this.fechas)

        this.on('download', () => this.descargar())

        return form.done(this)

    }

    async filtra_fecha() {
        this.request(this.id, this.fecdes, this.fechas)
    }

    async descargar() {
        if (this.mostrar_movimiento == 'movimiento') return this.descargar_movimiento()
        if (this.mostrar_movimiento == 'ordenv') return this.descargar_ordenv()
        if (this.mostrar_movimiento == 'ordenc') return this.descargar_ordenc()
    }
    async descargar_movimiento() {
        var wb = new Excel.Workbook();

        // PRODUCTOS
        var productos = wb.addWorksheet('Productos')

        // columnas
        productos.columns = [
            { header: 'Documento', key: 'documento', width: 40 },
            { header: 'Fecha', key: 'fecha', width: 15 },
            { header: 'Entidad', key: 'entidad', width: 40 },
            { header: 'Cantidad', key: 'cantidad', width: 15 },
            { header: 'Balance', key: 'balance', width: 15 },
        ];

        productos.eachRow(row => {
            row.eachCell((cell, n) => {
                if (n > 2) cell.alignment = { horizontal: 'right' };
            })
        })
        var data = []
        for (let x of this.movimiento) {
            productos.addRow(x)
        }

        //  descargar
        var r = await wb.xlsx.writeBuffer()
        download(new File([r], 'consulta-merc-movimiento.xlsx'));
    }
    async descargar_ordenv() {
        var wb = new Excel.Workbook();

        // PRODUCTOS
        var productos = wb.addWorksheet('Productos')

        // columnas
        productos.columns = [
            { header: 'Documento', key: 'documento', width: 40 },
            { header: 'Fecha', key: 'fecha', width: 15 },
            { header: 'Entidad', key: 'entidad', width: 40 },
            { header: 'Cantidad', key: 'cantidad', width: 15 },
        ];

        productos.eachRow(row => {
            row.eachCell((cell, n) => {
                if (n > 2) cell.alignment = { horizontal: 'right' };
            })
        })
        var data = []
        for (let x of this.ordenv) {
            productos.addRow(x)
        }

        //  descargar
        var r = await wb.xlsx.writeBuffer()
        download(new File([r], 'consulta-merc-ordenv.xlsx'));
    }
    async descargar_ordenc() {
        var wb = new Excel.Workbook();

        // PRODUCTOS
        var productos = wb.addWorksheet('Productos')

        // columnas
        productos.columns = [
            { header: 'Documento', key: 'documento', width: 40 },
            { header: 'Fecha', key: 'fecha', width: 15 },
            { header: 'Entidad', key: 'entidad', width: 40 },
            { header: 'Cantidad', key: 'cantidad', width: 15 },
        ];

        productos.eachRow(row => {
            row.eachCell((cell, n) => {
                if (n > 2) cell.alignment = { horizontal: 'right' };
            })
        })
        var data = []
        for (let x of this.ordenc) {
            productos.addRow(x)
        }

        //  descargar
        var r = await wb.xlsx.writeBuffer()
        download(new File([r], 'consulta-merc-ordenc.xlsx'));
    }
}



