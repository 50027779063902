import { mail_session } from "../modules/sistema/mail/mail.api";
import { check, Schema } from "./check";
import { Db, db, db_session, Transaction } from "./db";
import { SearchArg, SearchResult, Session } from "./faces";
import { api_table, Row, TableApi, Unique, unique_constraint } from "./table.api";
export const ERROR_TEXT = 'Oops! Ha ocurrido un error'

export interface Source {
    url?: string
    request(url: string, arg?: any): Promise<any>
}

export interface ServerFace {
    scope(session: FnServiceScope): Source
}

const sources: { [index: string]: Source } = {};

export function add_source(x: Source, name: string = 'main') {
    if (sources[name]) throw `source: el sourc ${name} ya existe`
    sources[name] = x
}

export function source(name: string = 'main') {
    if (!sources[name]) throw `source: el source ${name} no existe`
    return sources[name]
}

export interface FnServiceScope {
    session?: Session
    db?: db_session
    mail?: mail_session
}
export interface FnService {
    (this: FnServiceScope, x?: any): Promise<any>
}
interface Point {
    public: boolean
    source: FnService
}



class Service {
    points: { [index: string]: Point } = {}

    constructor(readonly name: string) { }
    add(name: string, source: Point | FnService): this {
        if (this.points[name]) throw `service.add: El punto ${name} ya ha sido definido`
        if (source instanceof Function) this.points[name] = { public: false, source };
        else this.points[name] = source;
        return this
    }

    add_list(name: string, sql: string, opt: { args?: Schema, public?: boolean } = {}): this {
        return this.add(name, {
            public: opt.public,
            source: async function (this: FnServiceScope, x?: any) {
                if (opt.args) await check(x, opt.args)
                if (this.session) {
                    x.app = this.session.app
                    x.user = this.session.user.id
                    x.empresa = this.session.empresa.id

                }
                return this.db.request(sql, x)
            }
        })
    }

    add_search(name: string, sql: string, args?: Schema, publico?: boolean) {

        this.add(name, {
            public: publico,
            source: async function (x: any) {
                if (args) await check(x, args);
                return this.db.transaction<SearchResult>(async function (tr) {
                    x = normalize_args(x)
                    if (tr.session) {
                        if (tr.session.user) x.user = tr.session.user.id
                        if (tr.session.empresa) x.empresa = tr.session.empresa.id
                        if (tr.session.app) x.empresa = tr.session.app
                    }
                    return search_request(tr, sql, x)
                })
            }
        })
    }
    add_get(name: string, fn: (tr: Transaction, id?: any) => Promise<any>) {
        this.add(name, function (x) {
            return this.db.transaction(function (tr) {
                return fn(tr, x.id)
            })
        })
    }

    add_update(name: string, update: (tr: Transaction, row: Row) => Promise<any>, opt: { args?: Schema } = {}) {
        this.add(name, async function (this: FnServiceScope, x: Row): Promise<any> {
            var r = await this.db.transaction(function (tr) {
                return update(tr, x)
            })
            return r;
        })
    }

    add_unique(name: string, table: string, unique: { [index: string]: Unique } = { id: ['id'] }) {
        if (!unique.id) unique.id = ['id']
        this.add(name, function (this: FnServiceScope, x: any): Promise<any> {
            var route = 'main'

            route = x.empresa

            //  validando que exista la clave

            if (!unique[x.name]) return Promise.reject(`la clave ${x.name} no ha sido definida`)

            //  realizando la verificacion
            return this.db.transaction((tr) => {
                return unique_constraint(tr, { table, data: x.data, unique: { [x.name]: unique[x.name] } })
            })
        })

    }

    add_request(name, fn: (tr: Transaction, x: any) => Promise<any>) {
        this.add(name, function (x) {
            return this.db.transaction((tr: Transaction) => {
                return fn(tr, x)
            })
        })
    }



}



export async function search_request(tr: Transaction, sql: string, x: any, no_limit: boolean = false): Promise<SearchResult> {
    x = normalize_args(x)
    if (tr.session && tr.session.user) x.user = tr.session.user.id

    var result: any = {}
    result.detail = await tr.request(`${sql} offset $offset limit $limit`, x)
    result.resume = (await tr.request(`select count(1) as items from (${sql}) as gg`, x))[0]
    return result;
}

export function normalize_args(x: SearchArg) {
    x = x || {}
    x.search = (x.search || '').toLowerCase();
    x.search = '%' + x.search + '%'; // Si eso fue lo que hice angtes de que me llamaras ok
    x.search = x.search.toLowerCase()
    x.limit = x.limit || 50
    x.page = x.page || 1
    x.offset = x.limit * (x.page - 1)
    return x;
}


class Module {
    services: { [index: string]: Service } = {}
    constructor(readonly name: string) { };
    add(...args: Service[][]) {
        for (let x of args) {
            for (let service of x) {
                if (this.services[service.name]) throw `Ya existe el servicio ${service.name} en el modulo ${this.name}`
                this.services[service.name] = service
            }
        }
        return this
    }
}

export class Server implements ServerFace {
    routes: { [index: string]: Point } = {}
    add(...args: Module[][]) {
        let url: string;
        for (let x of args) {
            for (let module of x) {
                for (let service in module.services) {
                    for (let point in module.services[service].points) {
                        url = `/${service}/${point}`
                        if (this.routes[url]) throw `Ya existe la ruta ${url}`

                        this.routes[url] = module.services[service].points[point]
                    }
                }
            }
        }
        return this
    }
    scope(scope: FnServiceScope) {
        return {
            request: (url: string, arg?: any) => {
                if (!this.routes[url]) return Promise.reject(`La url ${url} no esta registrada`)
                return this.routes[url].source.call(scope, arg)
            }
        }
    }
}


export function create_service(name: string): Service {
    return new Service(name)
}

export function create_module(name) {
    return new Module(name)
}

export function create_table_service(table: TableApi) {
    var service = create_service(table.alias)
    service.add_get('get', table.get)
    service.add_update('update', table.update)

    service.add('pull_updates', async function (x) {
        return db().transaction((tr) => {
            return table.pull_updates(tr, x.empresa, x.sync)
        })
    })

    service.add('push_updates', async function (x) {
        return db().transaction(async (tr) => {
            for (let data of x.updates) await api_table[table.table].push_updates(tr, x.empresa, x.updates)
            return []
        })
    })
    service.add_unique('unique', table.table)
    service.add('load', async function (x: { data: any[] }) {
        return this.db.transaction<void>((tr) => table.load(tr, x.data))
    })
    return service
}


