import { router } from "../../api/router";

import '../../modules/sistema/sistema.routes'
import '../../modules/wms/wms.routes'
import '../../modules/merc/merc.routes'
import '../../modules/cliente/cliente.routes'

import Home from "../../modules/wms/tareas/wms.tarea.resumen";
router.add([
    { path: '/', source: Home },
])

