import { Form, form } from '../../../api/form'
import { Obj } from '../../../api/obj'
import { router } from '../../../api/router'
import template from './wms.consulta-main.html'

export default class extends Obj implements Form {
    static template = template
    title = 'Consultas'
    router = router
    async init(x) {
        return form.done(this)
    }
}
