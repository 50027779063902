import { form } from '../../../api/form'
import { Obj } from '../../../api/obj'
import { router } from '../../../api/router'
import session from '../../../api/session'
import { source } from '../../../api/source'
import { replace_array } from '../../../api/util'
import { layout } from '../../../components/layout'
import { get_impresora, set_impresora } from '../../sistema/impresion/impresion.seleccionar-impresora'
import template from './wms.etiqueta.html'


export default class extends Obj {
    static template = template
    layout = layout
    serial: string = ''
    serial_actual: string = ''
    init() {

    }


    async valid() {


        if (this.serial_actual !== '') {

            if (this.serial == this.serial_actual) {
                this.notify('')

                try {
                    var impresora = await get_impresora()
                    if (!impresora) impresora = await set_impresora()
                    await source().request('/wms.etiqueta/reprint', { serial: this.serial, impresora: impresora })
                    this.notify(`${this.serial} reimpreso`)
                    this.serial_actual = ''
                } catch (error) {
                    this.error(error)
                }


            }

            this.serial = ''

        } else {
            this.serial_actual = this.serial
            this.serial = ''

            this.error('Escanee de nuevo para reimprimir')

        }


    }


}

