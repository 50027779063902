import { Obj } from "../api/obj"
import { Modal as BModal } from 'bootstrap'

var current: Modal
export class Modal extends Obj {
    static template =/*html*/`
        <div class="modal" tabindex="-1" >
            <div c-class="modal-dialog {{size?'modal-'+size:''}}">
                <div class="modal-content">
                    <content></content>
                </div>
            </div>
        </div>  
    `
    props = ['open', 'no-btn-close', 'no-close', 'title', 'size']
    title = 'modal'
    open = false;
    'no-btn-close' = false;
    'no-close' = false;
    size = '';
    constructor() {
        super()
        current = this
    }
    dismiss() {
        this.trigger('dismiss')
        this.open = false
    }
    setup(dom: HTMLElement) {
        this.watch('no-close', () => {
            dom.addEventListener('hide.bs.modal', (e) => {
                if (this.open) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }
            });
        })
        var modal = new BModal(dom)
        this.watch('open', (v) => {
            if (v) modal.show();
            else modal.hide()
        })
    }

}

class Header {
    static template =/*html*/`
        <div class="modal-header">
            <content></content>
            <button class="btn-close" @click="modal.dismiss()" :visible="!modal['no-btn-close']"></button>
        </div>
    `
    modal: Modal
    constructor() {
        this.modal = current
    }
}

class Title {
    static template =/*html*/`
        <h5 class="modal-title">{{modal.title}}</h5>
    `
    modal = current
}

class Body {
    static template =/*html*/`
        <div class="modal-body">
            <content></content>
        </div>  
    `
}

class Footer extends Obj {
    static template =/*html*/`
        <div class="modal-footer">
            <content></content>
        </div>  
    `
}

export default {
    modal: Modal,
    'modal-header': Header,
    'modal-title': Title,
    'modal-body': Body,
    'modal-footer': Footer
}
