import { form } from '../../../api/form'
import { Obj } from '../../../api/obj'
import { router } from '../../../api/router'
import session from '../../../api/session'
import { source } from '../../../api/source'
import { replace_array } from '../../../api/util'
import { layout } from '../../../components/layout'
import { Serial, Ubicacion } from '../inventario/wms.inventario.class'
import template from './wms.toma_ubicacion.proceso.html'

// 1S1-T07AC08D
export default class extends Obj {
    static template = template
    barra: string = ''
    ubicacion = new Ubicacion()
    layout = layout

    started = false
    confirmar_cambio = false
    botones = false
    kek = document.getElementById('barra_serial')

    async init(x) {
        var ubicacion_actual = await source().request('/wms.toma_ubicacion/toma-ubicacion-actual')

        if (ubicacion_actual) {
            this.notify(ubicacion_actual.message)
            this.ubicacion.reset(ubicacion_actual.data.ubicacion)
            this.botones = true
        }

    }

    async valid() {
        this.notify('Por favor espere...')
        try {
            var request = await source().request('/wms.toma_ubicacion/validar', { serial: this.barra, ubicacion_actual: this.ubicacion.id })
            this.notify(request.message)
            this.ubicacion.reset(request.data.ubicacion)
            this.botones = true

        } catch (e) {
            this.error(e)
        }

        this.barra = ''


    }

    async cancelar_toma() {
        this.notify('Por favor espere...')

        await source().request('/wms.toma_ubicacion/cancelar_toma')

        this.ubicacion.reset(new Ubicacion())

        this.notify('')

        this.botones = false

    }


    async procesar() {
        try {
            this.notify('Por favor espere...')
            await source().request('/wms.toma_ubicacion/procesar')
            this.ubicacion.reset(new Ubicacion())
            this.notify('')
        } catch (e) {
            this.error(e)
        }

    }


}

