import { Type } from "../../../api/type"
import { perfil_list } from "../perfil/perfil.list"
import { Table } from "../../../api/table"

export class tmerc extends Table {

    nombre = new Type.String({ required: true, title: 'Nombre' })
    contador = new Type.Number({ title: 'Contador' })
    manual = new Type.Boolean({ title: 'Manual' })
    constructor() {
        super('tmerc')
    }
}
