import { form } from '../../../api/form'
import { Obj } from '../../../api/obj'
import { router } from '../../../api/router'
import session from '../../../api/session'
import { source } from '../../../api/source'
import { replace_array } from '../../../api/util'
import { layout } from '../../../components/layout'
import template from './wms.ajuste.html'


export default class extends form.search(template, '/wms.ajuste/list-ajuste') {
    static template = template;
    title = 'Ajuste'
    confirmar_modal = false
    comentario: string = ''
    confirmar_boton: boolean = false
    setup() {
        this.request()

        //  recibiendo evento de busqueda
        this.on('search', async (v) => {
            this.request({ search: v })

        })
    }


    async ver(merc: string) {

        this.confirmar_boton = true

        for (const x of this.data) {

            if (x.merc == merc) {
                x.validado = !x.validado
            }

        }

    }


    async confirmar() {

        let existe = []

        for (const x of this.data) {
            if (x.validado == true) {
                existe.push(x.merc)
            }
        }

        if (existe.length > 0) {
            this.confirmar_modal = true

        }


    }


    async finalizar() {


        if (this.comentario == '') {
            this.error('Tiene que poner un comentario')
            return
        }

        let validados = []

        for (const x of this.data) {
            if (x.validado == true) {
                validados.push(x.seriales)
            }
        }



        try {
            await source().request('/wms.ajuste/confirmar', { validados: validados, comentario: this.comentario })
            this.confirmar_modal = false
            this.notify('Por favor espere...')
            await this.request()
            this.notify('')
            this.comentario = ''
        } catch (error) {
            console.error(error);

        }



    }

}

