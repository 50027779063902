import { router } from '../../api/router'
import zona_search from './zona/zona.search'
import zona_edit from './zona/zona.edit'
import tcliente_search from './tcliente/tcliente.search'
import tcliente_edit from './tcliente/tcliente.edit'

import cliente_search from './cliente/cliente.search'
import cliente_edit from './cliente/cliente.edit'

router.add([
    { path: '/zona/search', source: zona_search },
    { path: '/zona/edit', source: zona_edit },
    { path: '/tcliente/search', source: tcliente_search },
    { path: '/tcliente/edit', source: tcliente_edit },
    { path: '/cliente/search', source: cliente_search },
    { path: '/cliente/edit', source: cliente_edit },
])
