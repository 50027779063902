import { form } from '../../../api/form'
import { router } from '../../../api/router'
import { clone, dformat, dia_1ro, download, ocopy } from '../../../api/util'
import { filter } from '../../sistema/filtro/filtro'
import template from './ordenv.search.html'
import Excel from 'exceljs'
import { source } from '../../../api/source'
import { tcliente_list } from '../../cliente/tcliente/tcliente.list'
import session from '../../../api/session'
import { date, now } from '../../sistema/date/date.class'

var fecha1 = date();
export default class extends form.search(template, '/ordenv/search', { no_request: true, add: '' }) {

    params = {
        search: '',
        // fecdes: date(dia_1ro()).add({ month: -1 }).to_date(),
        fecdes: dia_1ro(),
        fechas: new Date(),
        status_orden_venta: '',
        documento_ordenv: '',
        tcliente: '',
        cliente: '',
        orden: '',                //  orden en blanco para consulta general, si lo quitas falla
    }
    active_filter = true
    active_download = true
    active_add = !!['order', 'erp'].find(x => x == session.app)
    session = session
    async init(x) {



        if (x) {
            ocopy(x, this.params)
        }
        this.start()
        this.on('detail', async (x) => {
            var params: any = {}

            params.orden = x.id
            params.presentacion_ordenv = 'full'
            if (x.status == 'Entregada') params.presentacion_ordenv = 'entregado';
            else params.presentacion_ordenv = 'despachado';

            await router.modal('/ordenv/detalle', params)
        })
        this.on('filter', () => filter(this.params))

        this.on('download', () => this.download())

        this.on('add', async () => {
            await router.modal('/ordenv/edit')
            this.request()
        })
        return form.done(this)
    }
    async clonar(x) {
        await router.modal('/ordenv/edit', { clonar: x })
        this.request()
    }
    async download() {
        var wb = new Excel.Workbook();

        // ordenes
        var ordenes = wb.addWorksheet('ordenes')
        // columnas
        ordenes.columns = [
            { header: 'Status', key: 'status', width: 15 },
            { header: 'Documento', key: 'documento', width: 15 },
            { header: 'Fecha', key: 'fecha', width: 15 },
            { header: 'Cliente', key: 'cliente', width: 15 },
            { header: 'Nombre', key: 'nombre', width: 100 },
            { header: 'Entregado', key: 'ordenado_valor', width: 15 },
            { header: 'Despachado', key: 'recogido_valor', width: 15 },
            { header: 'Entregada', key: 'entregado_valor', width: 15 },
        ];

        // formato a columnas
        ordenes.getRow(1).font = { bold: true }
        ordenes.getCell('F1').alignment = { horizontal: 'right' };
        ordenes.getCell('G1').alignment = { horizontal: 'right' };
        ordenes.getCell('H1').alignment = { horizontal: 'right' };

        var params = clone(this.params)
        params.limit = 999999999
        var data = await source().request('/ordenv/download', this.params)
        var ordenado_valor = 0, recogido_valor = 0, entregado_valor = 0
        // agregando contenido
        data.forEach((orden) => {
            orden.ordenado_valor = parseFloat(orden.ordenado_valor || 0 + '')
            orden.recogido_valor = parseFloat(orden.recogido_valor || 0 + '')
            orden.entregado_valor = parseFloat(orden.entregado_valor || 0 + '')

            ordenado_valor += orden.ordenado_valor
            recogido_valor += orden.recogido_valor
            entregado_valor += orden.entregado_valor

            orden.fecha = dformat(orden.fecha)
            ordenes.addRow(orden)
        })
        var total = ordenes.addRow({ nombre: 'Total', ordenado_valor, recogido_valor, entregado_valor })
        total.font = { bold: true }
        total.getCell('nombre').alignment = { horizontal: 'right' }

        // titulo
        var title = ordenes.insertRow(1, ['Consulta de ordenes'])
        title.font = { bold: true, size: 16 }

        //  parametros
        var tcliente = this.params.status_orden_venta == '' ? 'Todos' : tcliente_list.get(tcliente).nombre
        ordenes.insertRow(2, [`Desde: ${dformat(this.params.fecdes)}  hasta:  ${dformat(this.params.fechas)}, Estado: ${this.params.status_orden_venta || 'Todos'}, Tipo Cliente:  ${tcliente}`])

        //  descargar
        var r = await wb.xlsx.writeBuffer()
        download(new File([r], 'consulta-ordenes.xlsx'));

    }


}
