import { db, Transaction } from './db';
import { check, Prop, Rule, rule_args, Schema } from './check';
import { Empresa, Session } from './faces';
import { Obj } from './obj';
import { FnServiceScope, source } from './source';

//  DEFINE UN REGISTRO
export interface Row {
    id?: string
    key?: string
    action: 'insert' | 'update' | 'sync' | 'delete'
    [index: string]: any
    detail?: { [index: string]: Row[] }
}


//  DEFINE A UNIQUE
export type Unique = string[]

//  DEFINE UN UPDATE 
export interface Update {
    table: string
    data: Row
    unique?: { [index: string]: Unique }
    empresa?: string
}

export interface Get {
    id: string
}

// //  REGLA PRA REQUERIR CAMPOS NECESARIOS PARA LA INSERCION
export const required_for_insert: Rule = function (x: rule_args) {
    if (!!x.value || (x.context.action != 'insert' && x.context.action != 'sync')) return Promise.resolve()
    else Promise.reject('se requiere para insertar')
}

export const minimal_required_field: Prop = {
    type: "string", rules: [required_for_insert]
}

export const require_field: Prop = {
    type: "string", required: true
}


//  CONTIENE EL ESQUEMA DE VALIDACION DE UN REGISTRO QUE SE VA A GRABAR
const row_schema: Schema = {
    action: { type: 'string', enum: ['update', 'insert', 'delete', 'sync'] },
    // key: {
    //     type: 'string',
    //     required: true,
    //     rules: [(x, c) => c.action == 'insert' || !!x || 'Especifique key para actualizar']
    // },
    // rules: [x => Object.keys(x).some(x => x != 'action' && x != 'key') || 'Especifique campos para la actualizacion']
}

//  CONTIENE EL ESQUEMA DE VALIDACION DE LA TABLA QUE SE DESEA GUARDAR
const update_schema: Schema = {
    table: 'string',
    data: row_schema
}
const get_schema: Schema = {
    id: { type: "string", required: true }
}
export { row_schema, get_schema, update_schema, unique_schema }


//  VERIFICA LOS CONSTRAINT DE UNIQUE DE UNA TABLA
export function unique_constraint(tr: Transaction, table: Update) {
    return new Promise<void>(async function (ok, fail) {
        if (!table.unique) return ok()
        for (let i in table.unique) {
            let unique = table.unique[i]

            //  verifico sie entre los datos enviados se encuentran los necesarios para la validacion
            var found = unique.filter(x => table.data[x] != undefined);
            if (!found.length) return ok()
            table.data.key = table.data.key || '@#$%^&*('

            //  realizo la validacion
            try {
                var r = await tr.request('select 1 from ' + table.table + ' where ' + unique.map(x => `${x}=$${x}`).join(' and ') + ' and id<>$key', table.data);
            } catch (e) {
                return fail(e)
            }
            if (r.length) return fail(`(${unique.join(',')})=${unique.map(x => table.data[x]).join(',')}: ya esta registrado en ${table.table}`)
        }

        return ok()
    })
}

//  PARA GUARDAR DATOS
export async function table_update(tr: Transaction, table: Update) {

    //  validando parametros
    try {
        //  si no hay modificaciones
        let fields = Object.keys(table.data).filter(x => x != 'action' && x != 'key' && x != 'detail');
        if (!fields) return Promise.resolve()

        //  realiza chequeo
        await check(table, update_schema, table.table)


        //  asegurando que siempre exista un constraint unique
        //  esto debido a que el api rest puede responder otros clientes que no sean chalona
        if (!table.unique) table.unique = { id: ['id'] }
        else if (!table.unique.id) table.unique.id = ['id']

        //  antes de guardar
        await check(table.data, { id: minimal_required_field }, table.table)


        //  transformado tipos
        var objs: any = {};
        for (let i in table.data) {

            if (typeof table.data[i] == 'object' && table.data[i] != null && i != 'detail') {
                objs[i] = table.data[i]
                if (table.data[i] instanceof Array && table.data[i].every(x => typeof x != 'object')) {
                    table.data[i] = '{' + table.data[i].join(',') + '}'
                }
                else {
                    table.data[i] = JSON.stringify(table.data[i])
                }
            }
        }


        //  construye el string
        var cstring: string;

        //  para insertar
        if (table.data.action == 'insert') {
            cstring = `insert into ${table.table}(${fields.join(',')}) values($${fields.join(',$')})`
        }
        //  para actualizar
        else if (table.data.action == 'update') {
            cstring = `update ${table.table} set ${fields.map(x => x + '=$' + x).join(',')} where id='${table.data.key}'`
        }

        //  para sincronizar
        else if (table.data.action == 'sync') {
            await tr.request(`update ${table.table} set ${fields.map(x => x + '=$' + x).join(',')} where id='${table.data.id}' and coalesce(sync,'')<$sync`, table.data)
            await tr.request(`insert into ${table.table}(${fields.join(',')}) select $${fields.join(',$')} where not exists(select * from ${table.table} where id=$id)`, table.data)
            return Promise.resolve()
        }

        //  para eliminar
        else if (table.data.action == 'delete') {
            cstring = `delete from  ${table.table} where id='${table.data.key}'`
        }
        else {
            return Promise.reject('Especifique action (fallo validacion check)')
        }

        //  veririfica unique constraint
        await unique_constraint(tr, table)

        await tr.request(cstring, table.data)

        return Promise.resolve()

    } catch (e) {
        return Promise.reject(e)
    }

}

const unique_schema: Schema = {
    action: { type: 'string', enum: ['update', 'insert', 'delete', 'sync'] },
    // key: {
    //     type: 'string',
    //     optional: true,
    //     rules: [(x, c) => c.action == 'insert' || !!x || 'Especifique key para actualizar']
    // },
}

export function sync_service(
    table: string,
    get: (tr: Transaction, x: any) => Promise<any>,
    update: (tr: Transaction, x: Row) => Promise<any>,
) {
    const time = {
        name: 'time',
        args: {},
        source(this: FnServiceScope, x: any): Promise<any> {

            return this.db.request(`select max(sync) as sync from ${table}`)
                .then(x => x[0] ? x[0].sync : null)
        }
    }

    const pull = {
        name: 'pull',
        args: {},
        source(this: FnServiceScope, x: any): Promise<any> {
            x.sync = x.sync || null
            return this.db.transaction(async function (tr) {
                var result: any[] = [];
                try {
                    var r = await tr.request(`select id from ${table} where $sync is null or sync>=$sync order by sync limit 50`, { sync: x.sync })
                    for (let i of r) {
                        result.push(await get(tr, i.id));
                    }
                } catch (e) {
                    Promise.reject(e)
                }
                Promise.resolve(result)
            })
        }

    }

    const push = {
        name: 'push',
        args: {},
        source(this: FnServiceScope, x: any): Promise<any> {
            x.action = 'sync'
            return this.db.transaction(function (tr) {
                return update(tr, x)
            })
        }

    }

    return [time, pull, push]
}

export function sequence(session: Session, id: string = 'main') {
    return db().session(session).transaction<string>(async function (tr) {
        var contador = (await tr.request(`select * from contador where id=$id`, { id }))[0];
        if (!contador) await tr.request(`insert into contador(id) select $id`, { id })
        await tr.request(`update contador set contador=contador+1 where id=$id`, { id })
        contador = (await tr.request(`select * from contador where id=$id`, { id }))[0];
        return id + (contador.contador + '').padStart(9, '0')
    })
}

export async function next_id(tr: Transaction, name: string) {

    let r = await tr.request(`select contador from contador where id='${name}'`)

    var contador = 1;

    if (!r.length) {
        await tr.request(`insert into contador(id,contador) select '${name}',1`);
    } else {
        await tr.request(`update contador set contador=contador+1 where id='${name}'`)
        contador = (await tr.request(`select contador from contador where id='${name}'`))[0].contador;
    }

    return (tr.session.empresa.sucursal || '01') + (contador + '').padStart(9, '0')
}

export async function next_counter(tr: Transaction, source: string, counter: string) {
    await tr.request(`update ${source} set contador=contador+1 where id=$counter`, { counter });
    var { contador } = (await tr.request(`select * from ${source} where id=$counter`, { counter }))[0]
    return counter + (contador + '').padStart(6, '0')
}


export function sequence_trigger(table: string): trigger {
    return async function (tr: Transaction, row: Row) {
        if (row.action == 'insert' && !row.id) row.id = await sequence(tr.session)
    }
}

export function detail_trigger(detail: string, link: string): trigger {
    return async function (tr: Transaction, row: Row) {
        if (row.action == 'insert') row.id = await sequence(tr.session)
    }
}


export function counter_trigger(reference: string, source: string): trigger {
    return async function (tr: Transaction, row: Row) {

        if (row.action == 'insert' && !row.id) {
            if (row[reference] == undefined) return Promise.reject(`El campo ${reference} es requerido`)

            var contador = (await tr.request(`select * from ${source} where id=$reference`, { reference: row[reference] }))[0]
            if (!contador) return Promise.reject(`El valor ${row[reference]} para ${reference} es invalido`);

            if (!contador.manual) {
                row.id = await next_counter(tr, source, row[reference])
            } else {
                return Promise.reject(`El contador de esta operacion esta configurado de forma manual, especifique el id`)
            }
        }
    }
}
export interface create_get_arg {
    master: string,
    datos?: string[]
    detail?: { [index: string]: string }
}
export function create_get(x: create_get_arg): (tr: Transaction, id?: any) => Promise<any> {
    return async function (tr: Transaction, id?: any): Promise<any> {
        var app = ''
        if (tr.session && tr.session.app) {
            app = tr.session.app
        }
        var result = (await tr.request(x.master, { id, app }))[0];

        if (x.datos && result.datos) {
            for (let i in x.datos) result[i] = result.datos[i]
        }

        if (!result) return result;
        id = id || result.id;
        if (x.detail) {
            result.detail = {}
            result.app = app
            for (let table in x.detail) {
                result.detail[table] = await tr.request(x.detail[table], result)
            }
        }
        return result
    }
}

export function sync_trigger(table: string) {
    return async function (tr: Transaction, row: Row) {
        if (row.action == 'sync') {
            var r = await tr.request(`select * from ${table} where id=$id`, row)
            if (r.length) {
                row.key = row.id
                row.action = 'update'
            } else {
                row.action = 'insert'
            }
            if (row.detail) {
                for (let i in row.detail) {
                    for (let detail of row.detail[i]) {
                        detail.action = 'sync'
                    }
                }
            }
            row.sync = new Date()
        }
    }
}

export interface trigger {
    (tr: Transaction, row: Row): Promise<any>
}

export interface create_update_arg {
    table: string
    action?: 'insert' | 'update' | 'delete'
    get?: (tr: Transaction, x: any) => Promise<any>
    before?: trigger[]
    after?: trigger[]
    detail?: { [index: string]: { link: string, update?: trigger } }
    datos?: string[]
}


export function create_update(x: create_update_arg) {
    x.get = x.get || create_get({ master: `select * from ${x.table} where id=$id` })
    x.before = x.before || []
    x.after = x.after || []

    return async function (tr: Transaction, row: Row) {
        if (x.action == 'update' && !row.action) {
            row.action = 'update'
            row.key = row.id
        }
        for (let before of x.before) await before(tr, row)
        if (x.datos) {
            var datos = {}
            if (x.action == 'update') {
                var t = (await tr.request('select datos from ' + x.table + ' where id=$id'))[0]
                if (t) datos = t.datos
            }
            for (let i of x.datos) {
                if (row[i] !== undefined) {
                    row.datos = row.datos || datos
                    row.datos[i] = row[i]
                    delete row[i]
                }
            }
        }

        await table_update(tr, { table: x.table, data: row })
        if (row.detail) {
            if (!x.detail) return Promise.reject(`create_update: ha enviado un detalle que no esta configurado`)
            for (let i in row.detail) {
                if (!x.detail[i]) return Promise.reject(`create_update: ha enviado un detalle ${i} que no esta configurado`)
                var { update, link } = x.detail[i]
                update = update || create_update({ table: i })

                for (let data of row.detail[i]) {
                    data[link] = row.id || row.key
                    await update(tr, data)
                }
            }
        }

        for (let after of x.after) await after(tr, row)
        return x.get(tr, row.id || row.key)
    }

}



export function jerarquia_trigger(table): trigger {
    return async function (tr: Transaction, row: Row) {
        var id = row.id || row.key;
        var ctrl = (await tr.request(`
            select id from ${table} as ctrl where  ctrl.id<$id and $id like ctrl.id||'%' order by ctrl.id desc limit 1 
        `, { id }))[0];
        ctrl = ctrl ? ctrl.id : null
        row.control = ctrl;
    }
}
export const api_table: { [index: string]: TableApi } = {}

export interface TableApiArg {
    get?: create_get_arg
    before?: trigger[]
    update?: create_update_arg | ((tr: Transaction, row: Row) => Promise<any>)
    after?: trigger[]
    alias?: string
    datos?: string[]
    detail?: { [index: string]: { link: string, update?: trigger } }

}

export class TableApi<GET = any> extends Obj {
    get: (tr: Transaction, id?: any) => Promise<any>
    update: (tr: Transaction, row: Row) => Promise<GET>
    alias: string;

    constructor(readonly table: string, opts: TableApiArg = {}) {
        super()

        this.get = create_get(opts.get || { master: `select * from ${table} where id=$id or md5(id)=$id limit 1`, datos: opts.datos })
        this.alias = opts.alias || table
        if (opts.update instanceof Function) this.update = opts.update
        else {
            this.update = create_update(opts.update || { table, datos: opts.datos, before: opts.before, after: opts.after, detail: opts.detail })
        }

        if (api_table[this.alias]) throw `TableApi: la tabla ${table} ya ha sido instanciada`
        api_table[this.alias] = this
    }

    async pull_updates(tr: Transaction, empresa: string, sync = null) {
        await tr.schema(empresa)
        var list = await tr.request(`select id from ${this.table} where $sync is null or sync>$sync `, { sync })
        var r = [];
        for (let row of list) {
            r.push(await this.get(tr, row.id))
        }
        return r
    }

    async push_updates(tr: Transaction, empresa: string, updates: Row[]) {

        for (let data of updates) {

            data.action = 'sync'
            await table_update(tr, { table: this.table, data })
            if (data.detail) {
                for (let i in data.detail) {
                    for (let detail of data.detail[i]) {
                        detail.action = 'sync'
                        table_update(tr, { table: i, data: detail })
                    }
                }
            }
        }
    }

    async sync(tr: Transaction, empresa: string) {
        await tr.schema(empresa);
        await tr.request(`insert into sync(id) select $tabla where not exists(select * from sync where id=$tabla)`, { tabla: this.table })
        var { last_sync, current_sync } = (await tr.request(`select coalesce(sync,'')  as last_sync,current_timestamp::varchar current_sync from sync where id=$tabla`, { tabla: this.table }))[0]

        //  obteniendo los datos
        var push: Row[] = await this.pull_updates(tr, empresa, last_sync)
        var pull: Row[] = await source('sync').request(`/${this.table}/pull_updates`, { empresa, sync: last_sync })

        await source('sync').request(`/${this.table}/push_updates`, { updates: push, empresa })

        await this.push_updates(tr, empresa, pull);
        if (pull.length || push.length) await tr.request(`update sync set sync=$current_sync where id=$tabla`, { tabla: this.table, current_sync })
        await tr.commit()
        if (pull.length) console.info(`Se descargaron ${pull.length} actualizaciones de ${this.table}`)
        if (push.length) console.info(`Se subieron ${push.length} actualizaciones de ${this.table}`)


    }
    async load(tr: Transaction, row: Row[]) {
        for (let x of row) {
            x.action = 'update'
            x.key = x.id
            if (x.id) {
                let existe = (await tr.request(`select id from ${this.table} where id=$id`, x))[0]
                if (!existe) {
                    x.action = 'insert'
                }

            }
            await this.update(tr, x)
        }
    }

}




