import { Form } from "../../../api/form";
import { Obj } from "../../../api/obj";
import template from './firma.html'
import SignaturePad from "signature_pad";
import { layout } from "../../../components/layout";
import { upload } from "../../../api/http";

export default class extends Obj implements Form {
    static template = template
    title = 'Firma'
    dom: Element

    firma: SignaturePad


    init(x) {
        var canvas: any = $('canvas', this.dom)[0]
        this.firma = new SignaturePad(canvas);

        return new Promise<void>((ok, fail) => {
            this.on('accept', function (firma) {
                ok(firma)
            })
            this.on('cancel', fail)
            // this.on('error', app.notify)
        })

    }

    async aceptar() {
        if (this.firma.isEmpty()) {
            layout.notify('Se requiere su firma para continuar')
            return
        }
        var url = await this.firma.toDataURL()
        var file = await this.srcToFile(url, 'firma.png', 'image/png')
        url = (await upload([file]))[0]
        this.trigger('accept', url)
    }
    cancelar() {
        this.trigger('cancel')
    }

    srcToFile(src, fileName, mimeType) {
        return (fetch(src)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], fileName, { type: mimeType }); })
        );
    }
}