import { get_control } from "./get_control";
import { renderize_text } from "./util";
import { input } from "./input";
import { create_element } from "./util";
import { repeat } from "./for";

const session_token = '8C49C2F4-D26C-4E16-A177-31C7F92D5DC3'
const empresa_token = '0fe9262f-d52d-40ca-a2a1-40f1cafb8fe3'

//  INTERFACE DE CONSTRUCTOR DE COMPONENT
export interface ConstructorComponent {
    new(): any
    template: string
}

//  INTERFACE LISTA DE COMPONETES
interface component_list {
    [index: string]: ConstructorComponent
}

//  LISTA DE COMPONENTES
const components: component_list = {}
const cache_components: any = {};
const contexts: any = {}


//  INICIA LA APLICACION
export function start(application: string) {
    //  obteniendo tag app
    const app = document.getElementById('app');
    if (!app) throw `Debe especificar el tag app desde donde inicializa el sistema`;

    //  verificando que existe el compoenente main
    if (!components.main) throw `No ha agregado el componente principal main`;
    session.app = application


    session.load(localStorage.getItem(session_token) || sessionStorage.getItem(session_token))
    session.on('logged', () => {
        if (session.keep_session) {
            localStorage.setItem(session_token, session.token)
        } else {
            sessionStorage.setItem(session_token, session.token)
        }
    })
    session.on('logoff', () => {
        sessionStorage.removeItem(session_token)
        localStorage.removeItem(session_token)
    })
    var dom = start_component(components.main, app)
    app.append(dom)
    router.start()
}

//  INICIALIZA LOS COMPONENTES
export function start_component(component: ConstructorComponent, wrapper?: HTMLElement, parent?: any) {
    //  creando elemento 
    if (!component.template) {
        console.error('No ha definido template en ' + component.toString())
        throw ('No ha definido template en ' + component.toString())
    }
    var dom = create_element(component.template);

    //  inicializando el componente
    var context = new component();
    context.dom = dom;

    //  registrandolo
    var cid = next_cid()
    dom.setAttribute('cid', cid)
    contexts[cid] = context
    context._cid = cid

    //  renderizando componente
    dom = render(dom, context)
    if (context.setup) context.setup(dom, wrapper)

    //  corriendo configuracion

    //  comunicando desde el componente hasta el contexto
    var props: string[] = context.props
    if (props) {
        watch_dom(dom, (prop, v) => {

            if (props.find(x => x == prop)) {

                var control = get_control(v, parent);

                if (typeof context[prop] == 'boolean' && v !== undefined) context[prop] = (v === '' || control.value)
                else if (context[prop] instanceof Function) context[prop] = control.fn;
                else context[prop] = control.value

            }
        })
    }

    if (wrapper) {
        //  traduciendo la arroba que no puede ser copiada
        wrapper.getAttributeNames().filter(x => x[0] == '@').forEach(prop => {
            wrapper.setAttribute('c-event-' + prop.substr(1), wrapper.getAttribute(prop))
            wrapper.removeAttribute(prop)
        })


        //  comunicando de la envoltura con componente
        wrapper.getAttributeNames().forEach(prop => {
            var value = wrapper.getAttribute(prop);
            if (prop == 'class') {
                if (value) {
                    value.split(' ').forEach(x => {
                        if (x) dom.classList.add(x)
                    });
                }
            } else {
                dom.setAttribute(prop, value)
            }
            wrapper.removeAttribute(prop)
        })
    }

    return dom
}


//  RENDERIZA EL ELEMENTO Y SU CONTENIDO
function render(dom: HTMLElement, context: any) {
    //  si es un nodetext
    if (dom instanceof Element == false) {
        text(dom, context)
        return dom
    }

    if (dom.hasAttribute('c-for')) {
        repeat(dom, dom.getAttribute('c-for'), context)
        return dom
    }

    var tag = dom.tagName.toLowerCase();
    var n: HTMLElement;

    //  si el elemento es un component
    if (components[tag]) {
        //  se crea el componente
        n = start_component(components[tag], dom, context);
        attributes(n, context)
        events(n, context, dom)
    }

    //  si el elemento no es un componente
    else {

        //  renderizando los attributos
        attributes(dom, context)
        events(dom, context)
    }
    var renderizar: HTMLElement[] = [];
    dom.childNodes.forEach((x: HTMLElement) => {
        renderizar.push(x)
    })

    renderizar.forEach((x: HTMLElement) => {

        render(x, context)
    })

    var nodes: HTMLElement[] = []
    dom.childNodes.forEach((x) => {
        nodes.push(x as any)
    })


    //  renderizando los elementos dentro del dom
    var last: HTMLElement;
    var content: HTMLElement;
    if (n) content = last = n.querySelector('content')


    if (n) {
        nodes.forEach((x) => {
            //  si se genero un componente se mueve el contenido hacia el componente
            x.remove();
            if (last) last.after(x)
            else n.append(x);
            last = (x as any)
        })

        if (n) dom.replaceWith(n)
        if (content) content.remove()
    }
    return n || dom
}



//  RENDERIZA LOS TEXTNODE
function text(node: Node, context: any) {

    if (node.nodeType != node.TEXT_NODE || !node.nodeValue) return

    //	crea una variable para contener el texto a transformar sin cambios
    const template = node.nodeValue;

    renderize_text(template, context, (res) => node.nodeValue = res)
}



function events(dom: HTMLElement, context: any, wrapper?: HTMLElement) {
    wrapper = dom
    //	obtiene el listado de atributos del elementeo
    wrapper.getAttributeNames()

        //	filtra aquellos elementes que comienzen con @
        .filter(x => x[0] == '@' || x.substr(0, 8) == 'c-event-')

        //	realiza el recorrido por los resultados
        .forEach(function (attr) {
            //	obtiene la funcion especificada en la propiedad del contexto
            var fn = get_control(wrapper.getAttribute(attr), context).fn
            wrapper.removeAttribute(attr)
            var event = attr[0] == '@' ? attr.substring(1) : attr.substring(8)

            var fn_wrapper = (x) => {
                if (fn(x) === false) {
                    x.stopPropagation()
                    x.preventDefault()
                }
            }

            if (event == 'enter') {
                dom.addEventListener('keydown', (x) => {
                    if (x.key == 'Enter') fn_wrapper(x)
                })
            } else {
                if (event == 'click') {
                    dom.style.cursor = 'pointer'
                }
                //	carga el evento
                dom.addEventListener(event, fn_wrapper)
            }
        })
}

//  RENDERIZA LOS ATRIBUTOS
function attributes(dom: HTMLElement, context: any) {

    //  haciendo dinamicos los attributos que comienzan con :
    dom.getAttributeNames().filter(x => x[0] == ':').forEach(i => {
        var prop = i.substr(1);
        var expr = dom.getAttribute(i)
        dom.removeAttribute(i)

        if (renderize_text(expr, context, (v) => {
            dom.setAttribute(prop, v)
        })) return

        var refresh = (value) => {
            dom.setAttribute(prop, value)
        }

        var control = get_control(expr, context)
        if (control.value instanceof Type.Instance) control = get_control('value', control.value);

        control.watch(refresh)
        refresh(control.value)
    })

    var cache = get_context(dom)
    var props = cache.props

    //  para implementar manejadores de propiedades
    var run_attr = (prop, value) => {
        if (attrs_fns[prop]) attrs_fns[prop](dom, value, context)
    }

    //  para escuchar cambios a propiedades en el dom y pasarselas a los manejadores de propieades
    watch_dom(dom, run_attr)

    //  inicializando los manejadores
    dom.getAttributeNames().forEach(prop => {
        run_attr(prop, dom.getAttribute(prop))
    })
}


function watch_dom(dom: HTMLElement, fn) {
    const cache = get_context(dom);
    if (!cache._observer) {
        cache._observer = cache._observer || new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.type == "attributes") {

                    for (let x of cache._fns) x(mutation.attributeName, dom.getAttribute(mutation.attributeName))
                }
            });
        });

        cache._observer.observe(dom, {
            attributes: true
        });
        cache._fns = [];
    }
    cache._fns.push(fn)
}

//  GENERA UN CACHE PARA RENDERIZAR LOS ATRIBUTOS DE UN ELEMENTO
function get_context(dom: HTMLElement) {
    var cid = get_cid(dom)
    contexts[cid] = contexts[cid] || {};
    return contexts[cid]
}

//  CONTIENTE TODAS LAS FUNCIONES DE RENDERIZADO DE ATTRIBUTOS
const attrs_fns = {
    'active-buffer'(dom: HTMLElement, value, context) {
        var fn = () => {
            context.parent.parent.save(context.$item)
        }
        dom.addEventListener('change', fn)
    },
    focus(dom: HTMLElement, value: string) {

        setTimeout(() => {
            if (dom instanceof HTMLInputElement || dom instanceof HTMLSelectElement) dom.focus()
            else {
                var x = component(dom);
                if (x instanceof Control.Text) x.dom.querySelector('input').focus()
            }
        }, 200)
    },
    position(dom: HTMLElement, value: string, context: any) {
        var pos = {
            top: 'top-0',
            center: 'top-50 translate-middle-y',
            bottom: 'bottom-0',
            start: 'start-0',
            middle: 'start-50 translate-middle-x',
            end: 'end-0',
            'middle-center': 'top-50 start-50 translate-middle',
        }
        var cache = get_context(dom)
        if (cache._position) {
            cache._position.split(' ').forEach(x => {
                if (pos[x]) pos[x].split(' ').forEach(x => dom.classList.remove(x));
            });
        }

        if (value) {
            dom.classList.add('position-absolute')
            value.split(' ').forEach(x => {
                if (pos[x]) pos[x].split(' ').forEach(x => dom.classList.add(x));
                if (x == 'top') {

                }
            });
            cache._position = value
        } else {
            dom.classList.remove('position-absolute')
        }
    },
    visible(dom: HTMLElement, src: string, context: any) {
        var value = get_control(src, context).value

        var cache = get_context(dom)
        cache._display = cache._display || (dom.style.display == 'none !important' || dom.style.display == 'none' ? '' : dom.style.display || '')

        //  si se quiere mostrar
        if (value) {
            //  si esta oculto
            if (dom.style.display == 'none !important' || dom.style.display == 'none') {
                dom.style.display = cache._display
            }
        }

        //  si se quiere ocultar
        else {

            // si no esta oculto
            if (dom.style.display != 'none' && dom.style.display != 'none !important') {
                cache._display = dom.style.display
                dom.style.setProperty("display", "none", "important")
            }
        }
    },
    'extend-to-bottom'(dom: HTMLElement, value: any) {
        var extend = () => {
            var window_height = window.innerHeight
            var top = dom.getBoundingClientRect().top
            dom.style.height = (window_height - top - 5) - value + 'px'
        }
        window.addEventListener('resize', extend)
        on_ready(extend)
    },

    size(dom: HTMLElement, v) {
        var value = get_control<number[]>(v).value
        var cache = get_context(dom)
        if (cache.props && cache.props.find(x => x == 'size')) return
        //  remueve la clase anterior
        if (cache.size) cache.size.split(' ').forEach(x => dom.classList.remove(x));

        var tipo = ['-sm', '-md', '-lg', '-xl']

        //  segmenta 
        cache.size = value.map((x, i) => 'col' + tipo[i] + '-' + x).join(' ')

        cache.size.split(' ').forEach(x => dom.classList.add(x))

    },
    enabled(dom: HTMLElement, v) {
        var value = get_control(v).value
        if (value == undefined) value = true
        if (!value) dom.setAttribute('disabled', '')
        else dom.removeAttribute('disabled')
    },
    'c-class'(dom: HTMLElement, src, context) {
        dom.removeAttribute('c-class')
        apply_class(dom, context, src)
    },
    'c-style'(dom: HTMLHRElement, src, context: any) {
        if (!src) return
        dom.removeAttribute('c-style')

        function refresh(set) {
            if (typeof set != 'object') return console.error(`c-style: la expresion <<${set}>> no resulta en un objeto `)

            for (let i in set) {
                if (set[i] != undefined) {
                    dom.style[i] = set[i]
                }
            }
        }
        refresh(get_control(src, context, refresh).value)
    },
    src(dom: HTMLElement, src: string, context: any) {
        if (dom instanceof HTMLImageElement) return
        if (dom instanceof HTMLInputElement || dom instanceof HTMLSelectElement) {
            input.bind(dom, context)
        }
        else {
            var cache = get_context(dom)
            if (cache._src == src) return
            cache._src = src

            var control: any = get_control(src, context);

            if (control.value instanceof Type.Instance) control = control.value
            cache.control = control
        }
        if (context.parent) {
            var search = context.parent
            if (search instanceof Search == false) search = search.parent;

            if (search instanceof Search) {
                dom.addEventListener('change', (e) => {
                    search.save(context.$item)
                })
                dom.addEventListener('keydown', (e) => {
                    if (e.key == 'ArrowDown' || e.key == 'ArrowUp') e.preventDefault()
                })
            }

        }
    },
    jc(dom: HTMLElement) {
        align(dom, 'jc')
    },
    ac(dom: HTMLElement) {
        align(dom, 'ac')
    },
    at(dom: HTMLElement) {
        var cache = get_context(dom)
        cache._at = cache._at || ''
        if (cache._at) dom.classList.remove(cache._at)
        cache._at = 'text-' + dom.getAttribute('at')
        dom.classList.add(cache._at)
    }

}

function align(dom: HTMLElement, prop: string) {
    var cache = get_context(dom);
    if (cache.ac == undefined) {
        cache.jc = dom.getAttribute('jc') || ''
        cache.ac = dom.getAttribute('ac') || ''
        apply_class(dom, cache, "{{(jc||ac)?'d-flex':''}} {{jc?'justify-content-'+jc:''}} {{ac?'align-items-'+ac:''}}")
    } else {
        cache[prop] = dom.getAttribute(prop)
    }
}

function apply_class(dom: HTMLElement, context: any, src: string) {
    var cache = get_context(dom);

    //  verificando si habia cargado antes
    if (cache._class_watch) cache._class_watch.release()
    cache._class_watch = undefined

    //  si se desmonto la propiedad src es igual a null
    if (!src) return

    //  si es un texto renderizable

    cache._class_watch = renderize_text(src, context, (set) => {
        set = set.replace('  ', ' ')
        if (cache._class) cache._class.split(' ').forEach((x => dom.classList.remove(x)));
        if (set) set.split(' ').forEach(x => dom.classList.add(x))
        cache._class = set
    })

    if (cache._class_watch) return


    function refresh(set) {
        // set = get_control(set, context).value
        if (typeof set != 'object') return console.error(`:class: la expresion <<${set}>> no resulta en un objeto `)
        for (let i in set) {
            var c = i.split(' ')
            if (set[i]) c.forEach(x => dom.classList.add(x));
            else c.forEach(x => dom.classList.remove(x));
        }
    }
    //  si es una expression
    refresh(get_control(src, context, refresh).value)

}

//  AGREGA COMPONENTE AL FRAMEWORK
export function add_component(...args: component_list[]) {
    for (let x of args) {

        for (let name in x) {
            if (components[name.toLowerCase()]) {
                console.error(`ya esta registrado el componente ${name}`);
                continue;
            }
            components[name.toLowerCase()] = x[name];
        }
    }
}

export function release_component(x: HTMLElement) {
    var cid: string;
    x.querySelectorAll('[cid]').forEach(x => {
        cid = x.getAttribute('cid')
        delete cache_components[cid];
    })
    cid = x.getAttribute('cid')
    delete cache_components[cid];
}

//  obtiene un component instanciado
export function component<T>(selector: string | HTMLElement, dom?: HTMLElement): T {
    var cid: string;
    if (selector instanceof HTMLElement) cid = selector.getAttribute('cid');
    else {
        dom = dom || (document as any);
        var target = dom.querySelector(selector);
        if (target) cid = target.getAttribute('cid');
    }
    return contexts[cid]
}


export function get_cid(dom) {
    if (!dom.hasAttribute('cid')) dom.setAttribute('cid', next_cid())
    return dom.getAttribute('cid');
}

export function next_cid() {
    return (next_cid.next++).toString()
}

next_cid.next = 0;


export function is_component(dom: HTMLElement) {
    return !!components[dom.tagName.toLowerCase()]
}

export const render_component = render

import layout from '../components/layout'
import container from '../components/container'
import controls, { Control } from '../components/controls'
import icon from '../components/icon'
import modal from "../components/modal";
import { Type } from "./type";
import session from "./session";
import { router } from "./router";
import scroll from '../components/dynamic-scroll'
import card from '../components/card'
import { on_ready } from "./event";
import notify from '../components/notify'
import toast from '../components/toast'
import tree from '../components/tree'
import { Search } from "./search";
add_component(layout, icon, modal, container, controls, scroll, card, notify, toast, tree)

export interface Position {
    lat: number
    lng: number
}

export function get_position(): Promise<Position> {
    return new Promise((ok) => {
        navigator.geolocation.getCurrentPosition(function (x) {
            ok({ lng: x.coords.longitude, lat: x.coords.latitude })
        });

    })
}
