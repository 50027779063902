import { form, Form } from '../../../api/form'
import session from '../../../api/session'
import { dformat } from '../../../api/util'
import { close_wait, wait } from '../../../components/layout'
import { importer } from '../../sistema/import/import.form'
import { precio } from './precio.class'
import template from './precio.detalle.html'

export default class extends form.search(template, '/precio/detalle', {
    add: '/precio/add',
    no_request: true
}) {
    tcliente = ''
    fecha = ''
    active_add = true;
    async init(x) {


        this.start(x)
        this.tcliente = x.nombre

        this.fecha = dformat(x.fecha)
        return form.done(this)
    }
}