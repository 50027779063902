import { render, clone } from './util'
import { Obj } from './obj'
import { literal } from 'pg-escape'
import { Session } from './faces';


export interface Transaction<T = any[]> {
    session?: Session
    request(sql: string, args?: any): Promise<any[]>;
    schema(schema: string): Promise<any>;
    commit?(): Promise<any>
}

export interface TransactionScope<T = any[]> {
    (fn: Transaction<T>): Promise<T>
}

const db_source: { [index: string]: Db } = {}

export function add_db(x: Db, name = 'main') {
    if (db_source[name]) throw `add_db: ya se ha definido la conexion de datos ${name}`;
    db_source[name] = x;
}

export function db(name = 'main') {
    if (!db_source[name]) throw `add_db: no ha definido la conexion de datos ${name}`;
    return db_source[name]
}

export interface db_session<T = any> {
    request(sql: string, args?: any): Promise<T>
    transaction<T = any[]>(fn: TransactionScope<T>): Promise<T>
}

export class Db extends Obj {

    transaction<T = any[]>(tr: TransactionScope<T>): Promise<T> {
        throw new Error("Debe rescribir este metodo");
    };


    literal(x: any): any {
        if (typeof x == "string") {
            //	quitado mayor y menor que
            x = x.replace("<", "").replace(">", "");
            //	convirtiendo el parametro en literal
            x = literal(x);
        }
        return x;
    }

    request(sql: string, args?: any) {
        return this.transaction(function (tr) {
            return tr.request(sql, args)
        })
    }

    session(session?: Session | string): db_session {
        const parent = this
        return {
            request(sql: string, args?: any) {
                return this.transaction(async function (tr) {
                    return tr.request(sql, args)
                })
            },
            transaction<T = any[]>(fn: TransactionScope<T>): Promise<T> {

                //  funcion scope
                var scope = async function (tr) {
                    var schema: string;
                    //  si se pasa el esquema
                    if (typeof session == 'string') {
                        schema = session;
                        session
                    }
                    //  si se pasa la session
                    else if (typeof session == 'object') {
                        schema = session.empresa.id
                        tr.session = session
                    }

                    if (schema) await tr.schema(schema)
                    return fn.call(session, tr)
                }

                //  pasando this
                if (typeof session == 'object') scope = scope.bind(session)

                return parent.transaction(scope)
            }
        }
    }


    render(sql: string, args: Object = {}): string {
        if (args && typeof args != 'object') throw `el parametro enviado <<${args}>> debe ser un objeto para la consulta\n${sql}`
        //	realiza una copia de los datos
        var x: any = clone(args);

        //	convirtiendo el arreglo en objeto
        if (x instanceof Array) {
            var n: any = {};
            for (var i in (x as Array<any>)) n[i + ""] = x[i];
            x = n;
        }

        //	asegura los parametros
        for (var i in x) {
            //	quita los puntos y coma de los parametros los parametros especificados
            if ((typeof x[i]) == "string") {
                if (i == "order" || i == "torder" || i == "offset" || i == "limit") {
                    x[i] = x[i].replace(/;/g, "");
                }
                else if (typeof x[i] == null) x[i] = null
                else {
                    x[i] = this.literal(x[i]);
                }
            } else if ((typeof x[i]) == "object") {
                x[i] = JSON.stringify(x[i]);
            }
        }
        sql = render(sql, x);
        return sql;
    }

}
