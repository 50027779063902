import { move } from '../api/util';
import { Obj } from '../api/obj';
import { component } from '../api/component';

export function dynamic_scroll(dom: HTMLElement) {
    return component<DynamicScroll>('.dynamic-scroll', dom)
}

export class DynamicScroll extends Obj {
    static template = `
        <div class="dynamic-scroll">
            <div class="row justify-content-center dynamic-scroll-waiting invisible">
                <div class="loader">
                    
                </div>
            </div>
            <content></content>
        </div>
    `

    props = ['for-more']

    dom: HTMLElement
    'for-more' = () => undefined
    active = true;
    setup() {

        //  vigilando la propiedad for-more
        this.watch('for-more', (v) => {

            //  capturando el div wating
            var waiting: HTMLElement = this.dom.querySelector('.dynamic-scroll-waiting');


            //  indica que ya esta en el limite
            var limit = false;
            this.dom.addEventListener('scroll', () => {
                move(waiting).full_center()

                //  el borde inferior de contenedor
                var container_bottom = this.dom.getBoundingClientRect().bottom;

                //  el ultimo elemento
                var last = $(this.dom).children().last()[0];

                //  el borde inferior del ultimo elemento
                var last_bottom = last.getBoundingClientRect().bottom;

                //  camparando la posicion de los bordes
                if (last_bottom < container_bottom) {

                    //  evita que se ejecute mas de una vez cuando esta en el limite
                    if (limit) return
                    limit = true;

                    //  captura el resultado de la funcion
                    var promise = this['for-more']();
                    if (!promise || !promise.then) throw `dynamic-scroll: for-more debe ser una funcion que retorne una promesa`

                    //  hace visible el indicador waiting
                    if (this.active) waiting.classList.remove('invisible')

                    promise
                        //  reacciona cuando se cumple la promesa
                        .then(function (r) {
                            //  oculta el waiting cuando  se completa
                            waiting.classList.add('invisible')

                            //  si la promesa devuelve falso eso indica que no hay mas datos
                            if (r === false) {
                                //  oculta a waiting de manera que no ocupe el espacio
                                waiting.classList.remove('m-5')
                            } else {

                                //  muestra el waiting(sige invisible pero ocupa el espacio)
                                waiting.classList.add('m-5')
                            }
                        })


                } else {
                    //  desactiva el indicador del limite para que cuando vuelva
                    //  a bajar se corra el proceso
                    limit = false
                    waiting.classList.add('m-5')
                }

            })
        })
    }
}

export default {
    'dynamic-scroll': DynamicScroll
}