import { form, Form } from '../../../api/form'
import session from '../../../api/session'
import { close_wait, wait } from '../../../components/layout'
import { merc } from './merc.class'
import template from './merc.edit.html'

export default class extends merc implements Form {
    static template = template
    title = 'Mercancia'
    async init(x) {
        if (x) await this.request(x.id)
        return form.save(this)
    }

}