
const Cast = require('TypeCast');
import { Obj } from './obj';
import compare from 'deep-equal'
import { abort_promise, apromise } from './apromise';
import { List } from './list';

export interface Rule {
    (v?: any): Promise<any>
}

interface Opt<T> {
    required?: boolean,
    title?: string,
    value?: T,
    unique?: boolean,
    no_update?: boolean,
    default?: T,
    [index: string]: any
}

export namespace Type {

    export class Instance<T> extends Obj {
        title = ''
        required: boolean = false;
        readonly no_update;
        value: T;
        protected _reset: T;
        protected rules: Rule[] = [];
        protected promise: Promise<any>;
        private no_trigger_change = false;

        disable = false;
        visible = true;

        constructor(x: Opt<T>, cast: (x: any) => T) {
            super();

            let value: T;
            this.title = x.title || this.title

            this.required = x.required
            if (typeof x.value == 'object') {
                value = x.value
                this._reset = JSON.parse(JSON.stringify(x.value))
            } else {
                this._reset = value = x.value
            }

            this.no_update = x.no_update || false

            Object.defineProperty(this, 'value', {
                set: (v) => {
                    value = cast(v)
                    if (this.no_trigger_change) return;
                    this.valid()
                    this.trigger('change')
                },
                get() {
                    return value
                },
                configurable: true
            })

        }

        reset(v?: T) {

            this.no_trigger_change = true
            this.error_msg = '';
            this._reset = this.value = v;
            this.no_trigger_change = false
            return this
        }

        add_rule(rule: Rule) { this.rules.push(rule); return this }

        old() { return this._reset; }
        changed() { return this.value != this._reset };


        valid() {

            this.error_msg = '';

            //  cancelado llamadas previas 
            if (this.promise) abort_promise(this.promise)
            var promise: Promise<any>;
            var canceled = false;

            //  almacenando la promesa
            this.promise = apromise(async (ok, fail, cancel) => {

                //  para cancelar validacion
                cancel(function () {

                    if (promise) abort_promise(promise)
                    canceled = true
                })

                //  validando requireds
                if (this.required && !this.value) {
                    this.error((this.title || 'Este dato ') + ' es requerido!');
                    return fail(' es requerido!')
                }

                //  recorriendo todas las reglas
                for (var x of this.rules) {
                    if (canceled) return fail();

                    //  obtiene la promesa
                    promise = x(this.value)

                    //  resuelve la promesa
                    try {
                        await promise
                    } catch (e) {
                        if (e) {
                            this.error(e)
                            return fail(e)
                        }
                        return fail()
                    }
                    promise = undefined
                }
                //  liberando el almacen de la promesa
                this.promise = undefined

                //  validando a travez de eventos
                try {
                    await this.trigger('valid')
                } catch (e) {
                    if (e) {
                        this.error(e)
                        return fail(e)
                    }
                    return fail()
                }
                return ok()
            })

            this.promise.catch(e => e)

            //  retornando la promesa almacenada
            return this.promise
        }
    }

    export interface string_opt extends Opt<string> {
        upper?: boolean
    }

    export class String extends Instance<string>{
        constructor(opt?: string_opt) {

            opt = opt || {}
            opt.value = '';
            opt.required = opt.required || false;

            super(opt, (v) => {
                var r: string = Cast.string(v || '')
                if (opt.upper) return r.toLocaleUpperCase()
                return r;
            })
        }
    };


    interface ListOpt extends Opt<string | null> {
        list?: List
    }

    export class Link extends Instance<string>{
        list: List;

        constructor(opt?: ListOpt) {

            opt = opt || {}
            opt.value = null;
            opt.required = opt.required || false;

            var conversion = (x) => {
                // if (this.list && !this.list.get(x)) return null
                return x == null ? null : Cast.string(x)
            }

            super(opt, conversion)

            this.list = opt.list
        }
        selected() {
            return this.list.get(this.value)
        }
    };


    export class Number extends Instance<number>{
        constructor(opt?: Opt<number>) {
            opt = opt || {}

            opt.value = 0;
            opt.required = opt.required || false;

            super(opt, (v) => Cast.number(v || 0))
        }
    };
    export class Boolean extends Instance<boolean>{

        constructor(opt?: Opt<boolean>) {
            opt = opt || {}
            opt.value = false;
            opt.required = opt.required || false;


            super(opt, (v) => Cast.boolean(v || false))
        }
    };
    export class Json extends Instance<any>{
        constructor(opt?: Opt<any>) {
            opt = opt || {}
            opt.value = {};
            opt.required = opt.required || false;


            super(opt, x => x)

        }
    };

    export class Array extends Instance<any[]>{
        constructor(opt?: Opt<any[]>) {
            opt = opt || {}
            var value = opt.value || []
            opt.value = [];
            opt.required = opt.required || false;


            super(opt, x => x)
            //  manejando lo que se introduce en value
            Object.defineProperty(this, 'value', {
                set(v) {
                    v = v || []
                    while (value.length) value.shift();
                    for (let i of v) value.push(i)
                },
                get() {
                    return value
                }
            })

        }
        changed() {
            return !compare(this.value, this._reset)
        };

        reset(v) {
            while (this.value.length) this.value.shift()
            for (let i of v) this.value.push(i)
            this._reset = JSON.parse(JSON.stringify(v))
            return this
        }

    };


    export class Day extends Instance<Date | null>{
        filter_date?: (date: any) => boolean;
        constructor(opt?: Opt<Date | null>) {

            opt = opt || {}
            opt.value = null;
            opt.required = opt.required || false;


            super(opt, function (x) {

                var r = null;
                try {
                    r = new window.Date(x)
                } catch { }
                return r
            })

        }
    };
}


