import { url } from 'inspector';
import { Data } from '../../../api/data';
import { form } from '../../../api/form';
import { StaticList } from '../../../api/list';
import { Obj } from '../../../api/obj';
import { source } from '../../../api/source';
import { Type } from '../../../api/type';
import { replace_array } from '../../../api/util';
import template from './nota.form.html';



export default class extends Data {
    static template = template
    title = "Crear Nota"
    tipo = new Type.Link({ title: 'Tipo Nota', list: new StaticList(['pan', 'queso']) })
    nota = new Type.String({ title: 'Nota' })
    referencia = new Type.String()
    control = new Type.String()
    archivo = new Type.String()
    active_camera = true
    dom: HTMLElement

    init(x) {
        var url
        this.reset(x)
        if (x.tipos) this.tipo.list.load(x.tipos, true)
        var img = this.dom.getElementsByTagName('img')[0]
        this.on('photo', (x) => {
            url = source().url.slice(0, -1) + x;
            img.setAttribute("src", url)

        })
        return new Promise<void>((ok, fail) => {
            this.on('accept', async () => {
                var paquete = this.get()
                paquete.archivo = [url]
                await source().request('/nota/add-nota', paquete)
                ok()
            })
            this.on('cancel', fail)
        })
    }
}