import { form } from '../../../api/form'
import { search_result } from '../../../api/search'
import { source } from '../../../api/source'
import { filter } from '../../sistema/filtro/filtro'
import template from './wms.consulta-promedio.html'
import Excel from 'exceljs'
import { dformat, dia_1ro, download } from '../../../api/util'
import { router } from '../../../api/router'
import { date } from '../../sistema/date/date.class'

export default class extends form.search(template, '/wms.consulta/consulta-promedio') {

    active_filter = true
    active_download = true
    dformat = dformat
    params = { fecdes: date().first_day().add({ month: -3 }).to_date(), fechas: new Date(), tmerc: '', promedio_entrega_status: '' }
    init() {
        this.on('download', async (r) => {
            this.download()
        })
        this.on('filter', async (r) => {
            var r = await filter(this.params)
        })

        this.on('pre-load', (r: search_result) => {
            r.detail.forEach((x) => {
                x.cantidad = parseInt(x.cantidad + '')
                x.danger = parseInt(x.danger + '')
                x.warning = parseInt(x.warning + '')
            })
        })
        return form.done(this)
    }
    ver_detalle(x) {
        router.modal('/wms/inventario-merc', x)
    }
    async download() {
        var r = await source().request('/wms.consulta/consulta-promedio-download', this.params)
        var wb = new Excel.Workbook();
        // PRODUCTOS
        var productos = wb.addWorksheet('Productos')

        // columnas
        productos.columns = [
            { header: 'Tipo Mercancia', key: 'tmercds', width: 15 },
            { header: 'Codigo', key: 'id', width: 15 },
            { header: 'Nombre', key: 'nombre', width: 40 },
            { header: 'Entregada', key: 'entregado', width: 15 },
            { header: 'Promedio', key: 'promedio', width: 15 },
            { header: 'Inventario', key: 'disponible', width: 15 },
            { header: 'Dias', key: 'dias', width: 15 },
            { header: 'Maximo Dias', key: 'tmin', width: 15 },
            { header: 'Minimo Dias', key: 'tmin', width: 15 },
        ];

        // formato a columnas
        productos.getRow(1).font = { bold: true }
        productos.getCell('D1').alignment = { horizontal: 'right' };
        productos.getCell('E1').alignment = { horizontal: 'right' };
        productos.getCell('F1').alignment = { horizontal: 'right' };
        productos.getCell('G1').alignment = { horizontal: 'right' };
        productos.getCell('H1').alignment = { horizontal: 'right' };
        productos.getCell('I1').alignment = { horizontal: 'right' };

        // agregando contenido
        r.forEach((tipo) => {
            tipo.detalle.forEach((producto) => {
                producto.tmercds = tipo.tmercds
                productos.addRow(producto)
            })
        })

        // titulo
        var title = productos.insertRow(1, ['Promedio de entrega'])
        title.font = { bold: true, size: 16 }


        // //  parametros
        productos.insertRow(2, [`Filtro: ${this.params.promedio_entrega_status}, Fecha desde ${dformat(this.params.fecdes)} hasta ${dformat(this.params.fechas)}`])

        //  descargar
        var data = await wb.xlsx.writeBuffer()
        download(new File([data], 'consulta-promedio.xlsx'));
    }
}