import { form } from '../../../api/form'
import { Obj } from '../../../api/obj'
import { router } from '../../../api/router'
import session from '../../../api/session'
import { source } from '../../../api/source'
import { replace_array } from '../../../api/util'
import { layout } from '../../../components/layout'
import { date } from '../../sistema/date/date.class'
import { filter } from '../../sistema/filtro/filtro'
import template from './wms.tarea.resumen.html'


export default class extends form.search(template, '/wms.tarea/resumen', { no_request: true }) {

    confirmar_modal = false
    comentario: string = ''
    confirmar_boton: boolean = false
    active_filter = true
    layout = layout
    params = {
        usuario: '',
        status_tarea: '',
        tipo_tarea: '',
        fecdes: date().first_day().to_date(),
        fechas: new Date()
    }
    async init(x) {
        this.params.usuario = !session.user.funciones.find(x => x == 'Encargado Almacen') ? session.user.id : ''
        this.start()

        this.on('detail', async (x) => {
            if (x.tipo == 'Asignar Orden') await router.modal('/wms/orden/search', { status_tarea_orden: 'Por Asignar' })
            else if (x.tipo == 'Recoger Orden') await router.modal('/wms/despacho-ordenes')
            else if (x.tipo == 'Despachar Orden') await router.modal('/wms/carga-ordenes')
            else if (x.tipo == 'Entregar Orden') await router.modal('/wms/entrega-ordenes')
            else if (x.tipo == 'Asignar Auditoria') await router.modal('/wms/orden/search', { status_tarea_orden: 'Asignar Auditoria' })
            else if (x.tipo == 'Auditar Orden') await router.modal('/wms/orden/search', { status_tarea_orden: 'Por Auditar' })
            else return
            this.request()
        })

        this.on('filter', async () => await filter(this.params))
        return form.done(this)
    }
}

