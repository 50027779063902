import { form, Form } from '../../../api/form'
import session from '../../../api/session'
import { close_wait, wait } from '../../../components/layout'
import { Usuario } from './usuario.class'
import template from './usuario.edit.html'

export default class extends Usuario implements Form {
    static template = template
    title = 'Usuario'
    init(x) {

        if (x) this.request(x.id)
        if (session.user.cliente) {
            this.perfil.visible = false
            this.perfil.value = 'cliente'
            this.entidad.value = session.user.cliente.id
        }

        return form.save(this)
    }

}