import { Perfil } from './perfil.class'
import template from './perfil.edit.html'
import { group } from '../../../api/group';
import { replace_array } from '../../../api/util';
import session from '../../../api/session';
import { form } from '../../../api/form';

export default class extends Perfil {
    static template = template;
    categoria = []
    group = group
    fns: any[] = session.empresa.perfil_fns;
    mostrar_funciones = true

    init(x) {
        if (x) this.request(x.id)

        // this.permiso.on('load', (data) => {
        //     var categoria = group(this.permiso, [
        //         { name: 'categoria' },
        //         { name: 'menu', header: ['descrip'] }
        //     ])
        //     replace_array(this.categoria, categoria)
        // })

        // this.permiso.load(permiso_list.data)
        this.mostrar_funciones = !!session.empresa.perfil_fns.length

        return form.save(this)
    }
}

