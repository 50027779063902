import { Data } from "../../../api/data";
import { form, Form } from "../../../api/form";
import { source } from "../../../api/source";
import { Type } from "../../../api/type";
import { notify } from "../../../components/layout";
import { despachador_almacen_list, operador_almacen_list } from "../inventario/wms.inventario.list";
import template from './wms.orden.asignar.html'
console.info(operador_almacen_list)
export default class extends Data {
    static template = template
    recogida = new Type.Array({ required: true })
    despacho = new Type.Link({ list: despachador_almacen_list, required: true })
    auditoria = new Type.Link({ list: operador_almacen_list, required: false })
    operadores = operador_almacen_list
    orden = ''
    cliente = ''
    status = ''
    constructor() {
        super()
        this.operadores = operador_almacen_list
    }
    async init(x) {
        this.on('error', m => notify.error(m))
        this.on('notify', m => notify.success(m))

        let asignacion = await source().request('/wms.orden/asignacion', { orden: x.id })

        this.orden = x.documento
        this.cliente = x.nombre
        this.status = x.status

        if (asignacion && (asignacion.recogedida || asignacion.despacho || asignacion.auditoria)) {

            this.recogida.value = asignacion.recogida

            this.despacho.value = asignacion.despacho[0]
            if (asignacion.auditoria) {
                this.auditoria.value = asignacion.auditoria[0]
            }
        }
        return form.done(this, async () => {
            if (!this.recogida.value.length) return Promise.reject('Especifique los recogedores')
            await this.valid()
            let enviar = this.get()
            enviar.orden = x.id
            await source().request('/wms.orden/asignar-orden', enviar)
        })
    }
}

