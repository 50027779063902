import { form } from '../../../api/form'
import { Obj } from '../../../api/obj'
import { router } from '../../../api/router'
import session from '../../../api/session'
import { source } from '../../../api/source'
import { replace_array } from '../../../api/util'
import template from './wms.entrega.ordenes.html'


export default class extends Obj {
    static template = template
    ordenes: [] = []
    resumen: number = 0
    pendiente: boolean = false
    ruta: boolean = false
    init() {
        this.on('detail', (x) => {

            router.modal('/wms/entrega-entregar', x)
                .then(() => this.request())

        })
        this.request()
    }


    async request() {
        this.resumen = 0

        let r = await source().request('/wms.entrega/entregas-pendientes', { chofer: session.user.id })

        replace_array(this.ordenes, r)

        if (this.ordenes.length < 1) {



            let ruta = await source().request('/wms.entrega/ruta-pendiente', { chofer: session.user.id })



            if (ruta.length >= 0) {
                this.ruta = true
            }

            for (const x of ruta) {
                this.resumen = this.resumen + 1
            }

        }




    }

    async inicia_ruta() {
        try {
            await source().request('/wms.entrega/inicia-ruta', { chofer: session.user.id, ordenes: this.ordenes })
            this.ruta = false
            this.request()

        } catch (error) {
            this.error(error)
        }

    }

    async cancela_ruta() {
        try {
            await source().request('/wms.entrega/cancela-ruta', { chofer: session.user.id })
            this.ruta = true
            this.request()

        } catch (error) {
            this.error(error)
        }

    }


}

