import { Form, form } from '../../../api/form'
import { Obj } from '../../../api/obj'
import { source } from '../../../api/source'
import { clone, dformat, dia_1ro, download, nformat, ocopy, replace_array } from '../../../api/util'
import template from './ordenv.consulta-tcliente.html'
import { dialog } from '../../../api/dialog'
import Excel from 'exceljs'
import { filter } from '../../sistema/filtro/filtro'


export default class extends Obj implements Form {
    static template = template
    title = 'Consulta'
    data = []
    params = { fecdes: dia_1ro(), fechas: new Date(), status_orden_venta: '' }
    info_modal = false
    active_filter = true
    active_download = true
    active_warning = true

    nformat = nformat
    dformat = dformat
    async request() {
        var r = await source().request('/ordenv-consulta/consulta-tcliente', this.params)
        replace_array(this.data, r.detail)
    }

    async init(x) {
        this.request()
        this.on('filter', async () => {
            await filter(this.params)
            await this.request()
        })
        this.on('warning', () => {
            this.info_modal = true
        })
        this.on('download', () => {
            this.download()
        })
        return form.done(this)
    }



    async download() {
        var wb = new Excel.Workbook();

        // PRODUCTOS
        var productos = wb.addWorksheet('Productos')

        // columnas
        productos.columns = [
            { header: 'Tipo Cliente', key: 'tcliente', width: 40 },
            { header: 'Tipo', key: 'tmerc', width: 40 },
            { header: 'Ordenado', key: 'ordenado', width: 15 },
            { header: 'Entregada', key: 'entregado', width: 15 }
        ];

        // formato a columnas
        productos.getRow(1).font = { bold: true }
        productos.getCell('D1').alignment = { horizontal: 'right' };
        productos.getCell('C1').alignment = { horizontal: 'right' };

        // agregando contenido
        this.data.forEach((tcliente) => {
            tcliente.detalle.forEach((tipo) => {
                tipo.tcliente = tcliente.tcliente
                productos.addRow(tipo)
            })
        })

        // titulo
        var title = productos.insertRow(1, ['Relacion de Entrega de Producto x Tipo de Producto'])
        title.font = { bold: true, size: 16 }

        //  parametros
        productos.insertRow(2, ['Desde: ' + dformat(this.params.fecdes) + ' hasta: ' + dformat(this.params.fechas)])

        // // CLIENTES
        // var clientes = wb.addWorksheet('Clientes')

        // // columnas
        // clientes.columns = [
        //     { header: 'Tipo Cliente', key: 'tcliente', width: 40 },
        //     { header: 'Cliente', key: 'nombre', width: 40 },
        //     { header: 'Ordenado', key: 'ordenado', width: 15 },
        //     { header: 'Entregado', key: 'entregado', width: 15 }
        // ];

        // // formato a columnas
        // clientes.getRow(1).font = { bold: true }
        // clientes.getCell('D1').alignment = { horizontal: 'right' };
        // clientes.getCell('C1').alignment = { horizontal: 'right' };

        // // agregando contenido
        // this.data.forEach((tcliente) => {
        //     tcliente.resumen_cliente.forEach((cliente) => {
        //         cliente.tcliente = tcliente.nombre
        //         clientes.addRow(cliente)
        //     })
        // })

        // // titulo
        // var title = clientes.insertRow(1, ['Relacion de Entrega a Clientes x Tipo de Cliente'])
        // title.font = { bold: true, size: 16 }

        // //  parametros
        // clientes.insertRow(2, ['Desde: ' + dformat(this.params.fecdes) + ' hasta: ' + dformat(this.params.fechas)])

        //  descargar
        var r = await wb.xlsx.writeBuffer()
        download(new File([r], 'consulta-orden-tcliente.xlsx'));

    }
}