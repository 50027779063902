import { layout } from "../../../components/layout";

export function print_page(dom?: HTMLElement) {
    if (dom) {
        var scroll = dom.querySelectorAll('.dynamic-scroll');
        scroll.forEach(x => x.classList.remove('dynamic-scroll'))
    }
    var mode = layout.mode
    layout.mode = 'fullscreen'
    setTimeout(() => {
        print()
        layout.mode = mode

        if (dom) {
            scroll.forEach(x => x.classList.add('dynamic-scroll'))
        }

    }, 150);
}