import { form, Form } from "../../../api/form";
import { router } from "../../../api/router";
import { filter } from "../../sistema/filtro/filtro";
import template from './cliente.search.html'


export default class extends form.search(template, '/cliente/search', {
    // edit: '/cliente/edit',
    add: '/cliente/edit',
}) {
    params = { tcliente: '' }
    active_filter = true

    async init(x) {
        this.on('filter', () => filter(this.params))
        return form.done(this)
    }
    async editar(x) {
        await router.modal('/cliente/edit', x)
        this.request()
    }
}