import { Data } from '../../../api/data';
import { form } from '../../../api/form';
import { dia_mes_list, mes_list } from '../../../api/list';
import { Obj } from '../../../api/obj'
import { router } from '../../../api/router';
import { Search } from '../../../api/search';
import session from '../../../api/session';
import { source } from '../../../api/source';
import { Type } from '../../../api/type';
import { dformat, ocopy, replace_array } from '../../../api/util';
import { layout } from '../../../components/layout';
import { Serial, Ubicacion } from './wms.inventario.class';
import template from './wms.inventario.consulta.html'

export default class extends form.search(template, '/wms.inventario/consulta', { detail: '/wms/inventario-merc' }) {
    static template = template;
    title = 'Inventario'
    display_inventario = true

    ubicacion = new Ubicacion()
    serial = new Serial()
    layout = layout
    session = session
    active_search = true
    dformat = dformat
    setup() {
        this.start()

        //  recibiendo evento de busqueda
        this.on('search', async (v) => {
            this.request({ search: v })
            this.ubicacion.request(v)
            this.serial.request(v)




            this.request_serial(v)

        })
    }

    async request_serial(x) {

        var request = await source().request('/wms.inventario/consulta-serial', { serial: x })
        this.display_inventario = true

        if (request.ubicacion || request.serial) {

            this.display_inventario = false

        }

    }

    async colocar() {
        var r = await router.modal('/wms/inventario-movimiento', { serial: this.ubicacion.id || this.serial.id, accion: 'Colocar' })
        // this.request_serial(this.ubicacion.id || this.serial.id)
        this.notify(r)
        this.serial.request(this.serial.id)
        this.ubicacion.request(this.ubicacion.id)

    }
    async tomar() {
        var r = await source().request('/wms.movimiento/tomar', { serial: this.ubicacion.id || this.serial.id, concepto: 'Movimiento' })
        // this.request_serial(this.ubicacion.id || this.serial.id)

        this.notify(r)
        this.serial.request(this.serial.id)
        this.ubicacion.request(this.ubicacion.id)
    }
}




