import { form, Form } from '../../../api/form'
import session from '../../../api/session'
import { close_wait, wait } from '../../../components/layout'
import { Cliente } from './cliente.class'
import template from './cliente.edit.html'

export default class extends Cliente implements Form {
    static template = template
    title = 'Cliente'
    async init(x) {
        return form.save(this, x)
    }

}