import { move } from "../api/util";
import { Obj } from "../api/obj";


export class Notify extends Obj {
    static template =/*html*/ `
		<div class="notify animate__animated animate__backInDown" 
            c-class="{'animate-out':!open,'animate-in':open}"
            c-style="{display:open?'block':'none'}"
        
        >
			<div class="row">
			<span class="col-10">
				<span class="notify-message">{{message}}</span>
			</span>
			<span class="col-2">
				<i class='fa fa-close notify-close' @click="open=false"></i>
			</span>
			</div>
		</div>
	`
    props = ['align', 'justify', 'message', 'open']
    open = false
    delay = 2000
    align = 'top+50'
    justify = 'center'
    message = 'Mensaje'
    'animate-in' = 'animate__backInDown'
    'animate-out' = 'animate__backOutUp'
    dom: HTMLElement
    setup() {
        var start = false;
        var timeout;
        //	ubica el mensaje en la pantala
        var ubica = () => move(this.dom).vertical(this.align).horizontal(this.justify);
        window.addEventListener('resize', ubica)

        this.watch('open', () => {
            if (parent.open) {
                ubica()
                //	reiniciando el timeout si se llama de nuevo
                if (timeout) clearTimeout(timeout)

                //	inicial el timeout para que se cierre
                timeout = setTimeout(() => {
                    // this.open = false
                }, this.delay);
            }
            else {
                $(this.dom).removeClass(this['animate-in']);
                $(this.dom).addClass(this['animate-out']);
            }
        })

    }
}


export default {
    notify: Notify
}


