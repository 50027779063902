export interface Event {
    on: (name: string, fn: Function) => this;
    trigger: (name: string, args?: any) => Promise<any>
}


export function event_handler(x: any): Event {
    if (x.on) return x;
    var events: any = {};

    x.on = function (name: string, fn: Function) {
        //  crea un almacen para el evento si no esta
        events[name] = events[name] || [];

        //  agrega el evento al almacen
        events[name].push(fn.bind(x));

        return x;
    }.bind(x)

    x.trigger = async function (name: string, args?: any) {
        var r;
        if (!events[name]) return;
        for (var fn of events[name]) {
            r = await fn(args)
            if (r === false) return false
        }
        return r;
    }.bind(x)
    return x
}

export function on_ready(fn) {
    if (document.readyState === "complete" || document.readyState === "interactive") {
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

