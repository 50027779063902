import { form, Form } from '../../../api/form';
import { Obj } from '../../../api/obj';
import template from './impresion.seleccionar-impresora.html'
import Excel from 'exceljs'
import { download } from '../../../api/util';
import { get_file, read_file } from '../../../components/controls';
import { Stream } from 'stream';
import { SSL_OP_TLS_BLOCK_PADDING_BUG } from 'constants';
import { impresora_list } from './impresion.list';
import { Type } from '../../../api/type';

export interface import_args {
    columns: { header: string, key: string }[]
    file_name: string

}

class SetImpresora extends Obj {
    static template = template
    impresora = new Type.Link({ list: impresora_list })
    async init() {
        this.impresora.value = sessionStorage.getItem('impresora')
        return form.done(this, async () => {
            if (!this.impresora.value) return this.error('Seleccione la impresora');
            sessionStorage.setItem('impresora', this.impresora.value)
            return this.impresora.value
        })
    }


}


export async function set_impresora() {
    return await form.modal(SetImpresora)
}
export async function get_impresora() {
    return sessionStorage.getItem('impresora')
}