import { Obj } from "../api/obj";
import { watch } from "../api/watch";

var current_card: Card;

export class Card {
    static template =/*html*/ `
        <div class="card"><content></content></div>`
    props = ['collapse', 'collapsable', 'title']
    collapse = false
    collapsable = false
    title = 'titulo'
    constructor() {
        current_card = this
    }
}

class Header extends Obj {
    static template =/*html*/ `
        <div class="card-header" @click="card.collapse=!card.collapse">
            <div class="row">
                <div class="col">
                    <content></content>
                </div>
                <div class="col-1  me-2" :visible="card.collapsable">
                    <i c-class="fa fa-chevron-{{card.collapse?'down':'up'}}"></i>
                </div>
            </div>
        </div>
    `
    card = current_card
}

class Title {
    static template =/*html*/ `<h5 class="card-title">{{card.title}}</h5>`
    card = current_card
}

class SubTitle extends Obj {
    static template =/*html*/ `<h6 class="card-title"><content></content></h6>`
}

class Link extends Obj {
    static template =/*html*/ `<a class="card-link"><content></content></a>`
}

class Body extends Obj {
    static template =/*html*/ `<div class="card-body" :visible="!card.collapse"><content></content></div>`
    dom: Element
    card = current_card

}

class Text extends Obj {
    static template =/*html*/ `<p class="card-text"><content></content></p>`
}

class Img extends Obj {
    static template =/*html*/ `<img class="card-img-top">`
}

export default {
    card: Card,
    'card-body': Body,
    'card-header': Header,
    'card-title': Title,
    'card-sub-title': SubTitle,
    'card-text': Text,
    'card-link': Link,
    'card-img': Img
}


