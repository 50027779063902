import { create } from 'domain';
import { Modal } from '../components/modal'
import { abort_promise, apromise } from './apromise';
import { component, render_component, start_component } from './component';
import { Data } from './data';
import { event_handler } from './event';
import { Obj } from './obj';
import { create_element } from './util';

interface args {
    title: string
    message: string
    no_btn_close?: boolean
    no_close?: boolean
    prompt?: string
    btns?: { title: string, value?: string, class?: string }[]
}

export class Dialog extends Data {
    modal: Modal;
    dom: HTMLElement

    response(x?: any) {
        this.trigger('response', x)
    }
    cancel() {
        this.trigger('cancel')
    }
    init(x: any) { }

    request(x?: any) {
        if (!this.modal) {
            this.modal = this.modal || component(this.dom);
            if (this.modal instanceof Modal == false) this.modal = component('.modal', this.dom);
            if (this.modal instanceof Modal == false) throw `Dialog: El template suministrado debe ser un modal o contener un modal`
        }

        this.modal.open = true

        return new Promise((ok, fail) => {
            this.on('response', (r) => {
                this.modal.open = false;
                ok(r)
            })
            this.on('cancel', () => {
                this.modal.open = false;
                fail()
            })
        })
    }
}



export function create_dialog(x: args) {

    //  botonera
    var btns = '';

    x.btns = x.btns || [{ value: 'accept', title: "Aceptar", class: 'dialog-accept' }]

    btns = x.btns.map(x => {
        x.value = x.value || x.title
        x.class = x.class || ''
        return `<btn title="${x.title}" @click="respond('${x.value}')" class="${x.class}"></btn>`
    }).join('')

    var template =/*html*/`
        <modal :no-btn-close="no_btn_close" :no-close="no_close" :title='title' :open="open">
            <modal-header>
                <modal-title></modal-title>
            </modal-header>
            <modal-body>
                <p>{{message}}</p>
                <text :title="prompt.title" :visible="!!prompt.title"></text>
            </modal-body>
            <modal-footer>
                ${btns}
            </modal-footer>
        </modal>
    `
    var dialog = {
        title: x.title || 'Confirmacion',
        message: x.message || '',
        open: false,
        no_close: x.no_close,
        no_btn_close: x.no_btn_close,
        prompt: x.prompt || {},

        respond(result) {
            this.trigger('respond', result)
        },

        request(x) {

            this.title = x.title || this.title
            this.message = x.message || this.message

            this.open = true
            return apromise((ok, fail, cancel) => {
                cancel(() => {
                    result = undefined
                    modal.remove()

                    this.open = false
                })

                event_handler(dialog).on('respond', (r) => {
                    this.open = false
                    ok(r)
                })
            })

        }
    }

    var modal: HTMLElement
    var result

    return async function (x: { title?: string, message?: string } = {}) {
        dialog.title = x.title || dialog.title
        dialog.message = x.title || dialog.message
        var modal = create_element(template)
        render_component(modal, dialog)
        document.querySelector('modal-viewer').append(modal)
        var result = await dialog.request(x)
        return result
    }
}

export namespace dialog {
    export const notify = create_dialog({ title: 'Mensaje', message: '' });

    export function modal(template: string, data: any = {}) {
        var dom = create_element(template)
        return new Promise((ok, fail) => {
            var modal: Modal;
            data.accept = (r) => {
                modal.open = false
                ok(r)
            }
            data.cancel = (r) => {
                modal.open = false
                fail(r)
            }
            dom = render_component(dom, data)
            document.querySelector('modal-viewer').append(dom)

            var modal: Modal = component(dom)

            modal.on('dismiss', fail)
            modal.open = true
        })

    }


    export const confirm = (() => {
        const confirm = create_dialog({
            title: 'Mensaje',
            message: '',
            btns: [
                { value: 'accept', title: 'Aceptar', class: "btn-danger" },
                { value: 'cancel', title: 'Cancelar' },
            ]
        });

        return async (message: string, title: string = 'Confirmacion') => {
            return apromise(async (ok, fail, cancel) => {
                var promise = confirm({ message, title })
                cancel(() => abort_promise(promise));
                var result = await promise;
                if (result == 'cancel') return fail();
                ok()
            })
        }

    })();

    interface DialogConstructor {
        new(): Dialog
        template: string
    }


    export function instance(x: DialogConstructor) {

        return function (arg: any) {
            var dom = create_element(x.template)
            var dialog = new Dialog();
            dialog.init(arg)
            return dialog.request(arg)
        }
    }

}