import { Entidad, entidad_face } from "../../entidad/entidad/entidad.class";
import { tcliente_list } from "../tcliente/tcliente.list";
import { zona_face } from "../zona/zona.class";

export interface cliente_face extends entidad_face {
    zona: zona_face;
    tmerc: string[];
}


export class Cliente extends Entidad {

    constructor() {
        super('cliente')
        this.tipo.list = tcliente_list
    }
}
