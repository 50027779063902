import { router } from '../../api/router'
import inventario from './inventario/wms.inventario.consulta'
import inventario_movimiento from './movimiento/wms.movimiento.form'
import inventario_merc from './inventario/wms.inventario.merc'
import recepcion_ordenes from './recepcion/wms.recepcion.ordenes'
import recepcion_recibir from '../wms/recepcion/wms.recepcion.recibir'
import recepcion_contar from '../wms/recepcion/wms.recepcion.contar'
import despacho_ordenes from './despacho/wms.despacho.ordenes'
import despacho_recoger from '../wms/despacho/wms.despacho.recoger'
import carga_ordenes from './carga/wms.carga.ordenes'
import entrega_ordenes from './entrega/wms.entrega.ordenes'
import entrega_entregar from './entrega/wms.entrega.entregar'
import toma_ubicacion from './toma_ubicacion/wms.toma_ubicacion.proceso'
import imprimir from './imprimir/wms.imprimir'
import etiqueta from './etiqueta/wms.etiqueta'
import consulta_tcliente from '../ordenv/consulta/ordenv.consulta-tcliente'
import consulta_cliente from '../ordenv/consulta/ordenv.consulta-cliente'
import consulta_vencimiento from './consulta/wms.consulta-vencimiento'
import consulta_promedio from './consulta/wms.consulta-promedio'
import consulta_orden from '../ordenv/ordenv/ordenv.search'
import consulta_orden_detalle from '../ordenv/ordenv/ordenv.detalle'
import consulta from './consulta/wms.consulta-main'
import ajuste from './ajuste/wms.ajuste'
import facturas from '../erp/factura/factura.search'
import factura from '../erp/factura/factura.detalle'

import tarea_resumen from './tareas/wms.tarea.resumen'

import orden_search from './orden/wms.orden.search'
import orden_asignar from './orden/wms.orden.asignar'
import orden_auditar from './orden/wms.orden.auditar'


import '../cliente/cliente.routes';


router.add([
    { path: '/wms/inventario-consulta', source: inventario },
    { path: '/wms/inventario-movimiento', source: inventario_movimiento },
    { path: '/wms/inventario-merc', source: inventario_merc },
    { path: '/wms/recepcion-ordenes', source: recepcion_ordenes },
    { path: '/wms/recepcion-recibir', source: recepcion_recibir },
    { path: '/wms/recepcion-contar', source: recepcion_contar },
    { path: '/wms/despacho-ordenes', source: despacho_ordenes },
    { path: '/wms/despacho-recoger', source: despacho_recoger },
    { path: '/wms/carga-ordenes', source: carga_ordenes },
    { path: '/wms/entrega-ordenes', source: entrega_ordenes },
    { path: '/wms/entrega-entregar', source: entrega_entregar },
    { path: '/wms/toma_ubicacion', source: toma_ubicacion },
    { path: '/wms/imprimir', source: imprimir },
    { path: '/wms/etiqueta', source: etiqueta },
    { path: '/wms/consulta-tcliente', source: consulta_tcliente },
    { path: '/wms/consulta-cliente', source: consulta_cliente },
    { path: '/wms/consulta-promedio', source: consulta_promedio },
    { path: '/wms/consulta-vencimiento', source: consulta_vencimiento },
    { path: '/wms/consulta-orden', source: consulta_orden },
    { path: '/ordenv/detalle', source: consulta_orden_detalle },
    { path: '/wms/consulta', source: consulta },
    { path: '/wms/ajuste', source: ajuste },
    { path: '/wms/tarea/resumen', source: tarea_resumen },
    { path: '/wms/facturas', source: facturas },
    { path: '/wms/facturas.detalle', source: factura },
    { path: '/wms/orden/search', source: orden_search },
    { path: '/wms/orden/asignar', source: orden_asignar },
    { path: '/wms/orden/auditar', source: orden_auditar },
])
