import { Table } from "../../../api/table";
import { Type } from "../../../api/type";
import { dia_semana_list } from "../../../api/util";

export interface zona_face {
    id: string
    nombre: string
    control: string
    datos: string
    chofer: string
    sync: string
    dia_visita: number[]
    dia_entrega: number[]
    area: any[]
    inactivo: boolean
}

export class Zona extends Table {
    nombre = new Type.String()
    dia_entrega = new Type.Array();
    dia_visita = new Type.Array();
    semana = dia_semana_list
    constructor() {
        super('zona')
    }
}
