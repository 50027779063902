import { apromise } from './apromise';
import { Obj } from './obj'
import { Type, Rule } from './type'
import { abort_promise } from './apromise';

export class Data extends Obj {
    private promise: Promise<any>
    private rules: Rule[] = [];
    constructor() {
        super()
    }
    dfault() {
        this.forMember(Type.Instance, function (x) {
            x.dfault()
        })
        return this;
    }
    reset(v: any = {}) {
        this.forMember(Type.Instance, function (x, name) {
            x.reset(v[name]);
        })
        return this;
    }
    changes() {
        var r = undefined
        this.forMember(Type.Instance, function (x, name) {
            if (!x.no_update && x.changed()) {
                r = r || {};
                r[name] = x.value
            }
        })
        return r;
    }
    get() {
        var r: any = {}
        this.forMember(Type.Instance, function (x, name) {
            r[name] = x.value
        })
        return r;
    }
    set(v: any) {
        var r = {}
        this.forMember(Type.Instance, function (x, name) {
            if (v[name] != undefined) {
                x.value = v[name]
            }
        })
        return r;
    }

    add_rule(rule: Rule) { this.rules.push(rule); return this }

    valid() {
        if (this.promise) abort_promise(this.promise);

        var canceled = false;
        var promise: Promise<any>;
        this.promise = apromise(async (ok, fail, cancel) => {

            var promise: Promise<any>;
            var canceled = false;

            cancel(function () {
                if (promise) abort_promise(promise)
                canceled = true;
            })
            for (let i in this) {
                let campo: Type.Instance<any> = <any>this[i]
                if (campo instanceof Type.Instance == false) continue;

                if (canceled) fail()
                let title = campo.title || i;
                promise = campo.valid()
                try {
                    await promise
                } catch (e) {
                    if (e) {
                        this.error(title + ': ' + e)
                        fail(title + ': ' + e)
                    }
                    else fail(title + ': Error desconocido')
                }

            }
            for (var campo of this.members<Type.Instance<any>>(Type.Instance).reverse()) {
            }


            //  recorriendo todas las reglas
            for (var x of this.rules) {
                if (canceled) return fail();

                //  obtiene la promesa
                promise = x()

                //  resuelve la promesa
                try {
                    await promise
                } catch (e) {
                    if (e) {
                        this.error(e)
                        return fail(e)
                    }
                    return fail()
                }
                promise = undefined
            }


            this.promise = undefined
            return ok()
        })

        return this.promise
    }
}
