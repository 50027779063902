import { abort_promise, apromise, is_aborted } from "./apromise";
import { Data } from "./data";
import { Matrix } from "./matrix";
import { source, Source } from "./source";
import { Type } from "./type";
import { toProperCase } from "./util";

export interface TableConstructor {
    new(): Table
}
export interface TableOpt {
    detail?: boolean
    title?: string
}

//  CLASE TABLE
export class Table extends Data {
    private source: Source
    private unique: string[][] = [];

    private action: 'insert' | 'update' | 'delete' = 'insert';

    readonly id: Type.String;

    private update_promise: Promise<any>;
    has_changes = false
    constructor(readonly table: string, opt: TableOpt = {}) {
        super()
        this.source = source()
        this.id = new Type.String({ required: !opt.detail, unique: !opt.detail })
        this.title = opt.title || toProperCase(table)
        if (!opt.detail) this.add_unique('id', ['id'])

        //  para actualizar el indicador de cambios en la tabla
        setTimeout(() => {
            this.forMember(Type.Instance, x => {
                x.on('change', () => {
                    var has_changes = false
                    this.forMember(Type.Instance, (x) => {
                        if (x.changed()) has_changes = true
                    })
                    this.has_changes = has_changes
                })
            })
            this.forMember(Detail, (x) => {
                x.on('error', (e) => { this.error(e) })
                x.on('notify', (e) => { this.notify(e) })
            })
        }, 200);
        this.reset()
    }

    inserted() { return this.action == 'insert' }
    updated() { return this.action == 'update' }

    add_unique(name: string, unique: string[]) {

        //  variables
        var table = this;
        var error = false
        var promise: Promise<any>;

        //  validando que existan 
        for (let i of unique) {
            if (!(table[i] instanceof Type.Instance)) {
                console.error(`add_unique: no existe el campo ${i}`)
                return;
            }
        }

        unique.forEach(function (i) {

            var field: Type.Instance<any> = table[i]

            var constraint = function () {
                if (promise) {
                    abort_promise(promise)
                    promise = undefined
                }
                if (table.action == 'update' && field.old() == field.value) return Promise.resolve()

                //  recogiendo los datos a evaluar
                var data: any = {};
                unique.forEach(function (i) {
                    data[i] = table[i].value
                })
                data.table = table.table
                data.key = table.id.old();
                data.action = table.action;
                data.id = table.id.value;
                var request;

                // realizando consulta
                promise = apromise(function (ok, fail, cancel) {
                    var p = promise
                    var x = { name, data }
                    var request = table.source.request(`/${table.table}/unique`, x)
                        .then(ok)
                        .catch(function (e) {
                            if (is_aborted(p)) return ok()
                            fail(`${table.table} ${name}: Ya existe este valor!`)
                        })

                    cancel(function () {
                        abort_promise(request)
                    })

                })
                return promise;
            }

            field.add_rule(constraint)
        })
        if (error) return this
        this.unique[name] = unique;
        return this
    }

    reset(x: any = {}) {
        this.action = (x == undefined || !x.id) ? 'insert' : 'update';
        this.has_changes = false
        return super.reset(x);
    }
    changes() {
        var r: any = super.changes();
        if (!r && (this.action == 'delete' || this.action == 'insert')) r = {}
        if (r) {
            r.action = this.action
            if (r.action == 'update' || r.action == 'delete') r.key = this.id.old()
        }
        return r;
    }
    async valid(): Promise<any> {
        const parent = this;
        await super.valid()
        for (let x of this.members<Detail>(Detail)) {
            await x.valid()
        }
        await this.trigger('valid')
    }

    async update(): Promise<any> {
        const parent = this
        var row: any = this.changes();
        this.forMember(Detail, function (x: Detail, name: string) {
            var r: any = x.changes()
            if (r) {
                row = row || {};
                row.detail = row.detail || {};
                row.detail[name] = r
            }
        })

        if (row) {
            row.action = this.action
            row.key = this.id.old();
        }

        else {
            if (this.inserted()) {
                const error = 'No ha especificado datos a guardar'
                this.error(error)
                return Promise.reject(error)
            }
            else {
                parent.trigger('update', parent.changes())
                return Promise.resolve();
            }
        }
        parent.trigger('pre-update', row)
        await this.valid()
        const r = await parent.source.request(parent.table + '/update', row)
        parent.trigger('update', parent.changes())
        return r;
    }

    undo() {
        this.reset()
        this.trigger('undo')
    }

    request(key?: string) {
        key = key + ''
        const parent: any = this;
        return this.source.request(this.table + '/get', { id: key })
            .then(function (r: { [index: string]: any, detail: { [index: string]: any[] } }) {
                if (r instanceof Array) console.error(`table.request: ${parent.table}/get debe devolver un objeto y ha devuelto un arreglo`)
                parent.reset(r)
                if (r.detail) {

                    for (let i in r.detail) {
                        if (parent[i] instanceof Matrix) {
                            parent[i].load(r.detail[i]);
                        } else {
                            console.error(`Esta llegando el detalle ${i} para la tabla ${parent.table} sin embargo no existe un objeto que lo reciba`)
                        }
                    }
                }
                parent.trigger('request', r)
            })
    }
    delete() {
        this.action = 'delete'
    }
    deleted() {
        return this.action == 'delete'
    }
}

export interface Constructor<T extends Table> {
    new(): T;
}

export class Detail<T extends Table = Table> extends Matrix<T> {
    constructor(private model: Constructor<T>) {
        super()
    }
    changes() {
        var r: any[];
        this.forEach(function (x: Table) {
            var c = x.changes();

            if (c) {
                r = r || []
                r.push(c)
            }
        })
        return r
    }

    async valid(): Promise<any> {
        const parent = this
        for (let detail of parent) {
            await detail.valid()
        }
    }

    load(data: any[]) {

        while (this.length) this.shift()
        data.forEach((x) => {
            const n = new this.model()

            if (!x.id) n.set(x)
            else n.reset(x);
            n.on('error', (e) => this.error(e))
            n.on('notify', (e) => this.notify(e))
            this.push(n)
        })
        this.trigger('load', data)
    }

    add(data: any = {}) {

        const n = new this.model()
        this.push(n)
        n.set(data)
        n.on('error', (e) => this.error(e))
        n.on('notify', (e) => this.notify(e))
        return n;
    }
    set(data: any[]) {
        for (let set of data) {

            var row = this.find(x => x.id.value == set.id)
            if (!row) {
                this.add(set)
            } else {
                row.set(set)
            }
        }
    }
    remove(i: number) {
        if (i == undefined) return
        if (!this[i]) return
        if (this[i].inserted()) this.splice(i, 1);
        else this[i].delete()
        this.trigger('refresh')
    }
}
