import { form } from '../../../api/form'
import { Obj } from '../../../api/obj'
import { router } from '../../../api/router'
import session from '../../../api/session'
import { source } from '../../../api/source'
import { replace_array } from '../../../api/util'
import { layout } from '../../../components/layout'
import template from './wms.entrega.entregar.html'


export default class extends Obj {
    static template = template
    entidad: string = ''
    mercs: [] = []
    barra: string = ''
    valija_actual: string = ''
    entregado: boolean = false
    excluir_display: boolean = false
    razon: string = ''
    recibido_display: boolean = false
    recibido_por: string = ''
    comentario: string = ''
    layout = layout

    firma: {} = {}

    init(x) {
        this.entidad = x.entidad
        this.recibido_display = true
        this.layout.title = x.cliente
        this.request()

        return new Promise<void>((ok, fail) => {
            this.on('accept', async () => {
                this.firma = await router.modal('/firma')
                if (this.firma) {
                    await source().request('/wms.entrega/facturar', { chofer: session.user.id, entidad: this.entidad, firma: this.firma })
                    ok()
                } else {

                }
            })
            this.on('cancel', fail)
        })
    }

    async request() {
        let r = await source().request('/wms.entrega/list-merc', { recibido_por: this.recibido_por, entidad: this.entidad, chofer: session.user.id })
        replace_array(this.mercs, r)

    }

    async asignar() {
        if (this.recibido_por == '') {
            this.error('Este campo es necesario')

        } else {
            this.recibido_display = false
            this.error('')
        }


    }

    async valid() {
        this.valija_actual = this.barra

        if (this.entregado && (this.barra == this.valija_actual)) {
            this.excluir_display = true

        }


        try {
            await source().request('/wms.entrega/entregar', { entidad: this.entidad, serial: this.barra, recibido_por: this.recibido_por, chofer: session.user.id })
            this.notify(`${this.barra} entregado/a ${this.recibido_por}`)
            this.request()
        } catch (e) {
            this.error(e.error || e)
            if (e.entregado) this.entregado = true


        }

        this.barra = ''

    }

    async excluir() {
        await source().request('/wms.entrega/excluir', { serial: this.valija_actual, razon: this.razon, comentario: this.comentario })
        this.entregado = false
        this.excluir_display = false
        this.valija_actual = ''
        this.request()
        this.notify(`Excluido/a por ${this.razon}`)
    }


}

