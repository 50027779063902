import { form, Form } from "../../../api/form";
import { router } from "../../../api/router";
import { filter } from "../../sistema/filtro/filtro";
import template from './wms.orden.search.html'


export default class extends form.search(template, '/wms.orden/search', {
    no_request: true,
}) {
    params = {
        status_tarea_orden: ''
    }
    active_filter = true
    async init(x) {

        this.on('detail', async (x) => {
            if (x.status !== 'Por Auditar') await router.modal('/wms/orden/asignar', x)
            else await router.modal('/wms/orden/auditar', x)
            this.request()
        })

        if (x && x.status_tarea_orden) this.params.status_tarea_orden = x.status_tarea_orden
        this.start()
        this.on('filter', async () => await filter(this.params))

        return form.done(this, x)
    }
}

