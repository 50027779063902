import { form } from '../../../api/form'
import { clone, dformat, download, get_url_args, nformat } from '../../../api/util'
import { filter } from '../../sistema/filtro/filtro'
import { print_page } from '../../sistema/impresion/impresion.class'
import template from './ordenv.detalle.html'
import Excel from 'exceljs'
import session from '../../../api/session'
import { source } from '../../../api/source'

export default class extends form.search(template, '/ordenv/detalle', { no_request: true }) {
    logo = source().url + session.empresa.logo
    session = session
    params = {
        presentacion_ordenv: 'entregado'
    }

    resume = {
        ordenado: 0,
        ordenado_valor: 0,
        recogido: 0,
        recogido_valor: 0,
        entregado: 0,
        entregado_valor: 0
    }

    display = 'none'
    active_print = true
    active_filter = true
    active_download = true
    nformat = nformat


    init(params) {
        params = params || {}

        if (!params.orden) {
            var args = get_url_args()
            params.orden = args.conduce
            params.presentacion_ordenv = 'despachado'
        }
        if (params.orden) params.limit = 9999999
        this.start(params)

        this.on('filter', () => filter(this.params))
        this.on('download', () => this.descargar())
        this.on('print', () => print_page(this.dom))

        return form.done(this)
    }

    async descargar() {
        var wb = new Excel.Workbook();

        // PRODUCTOS
        var productos = wb.addWorksheet('Productos')

        // columnas
        productos.columns = [
            { header: 'Tipo', key: 'tmerc', width: 40 },
            { header: 'Codigo', key: 'merc', width: 15 },
            { header: 'Nombre', key: 'descrip', width: 40 },
            { header: 'Ordenado', key: 'ordenado', width: 15 },
            { header: 'Total Ordenado', key: 'ordenado_valor', width: 15 },
            { header: 'Despachado', key: 'despachado', width: 15 },
            { header: 'Total Despachado', key: 'despachado_valor', width: 15 },
            { header: 'Entregada', key: 'Entregada', width: 15 },
            { header: 'Total Ordenado', key: 'entregado_valor', width: 15 },
        ];

        productos.eachRow(row => {
            row.eachCell((cell, n) => {
                if (n > 2) cell.alignment = { horizontal: 'right' };
            })
        })
        var data = []
        for (let tipo of this.data[0].detalle) {
            for (let detalle of tipo.detalle) {
                var x = clone(detalle)
                x.tmerc = tipo.tmerc
                productos.addRow(x)
            }
        }

        //  descargar
        var r = await wb.xlsx.writeBuffer()
        download(new File([r], 'ordenv-detalle.xlsx'));
    }
}