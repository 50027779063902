import { form, Form } from '../../../api/form'
import session from '../../../api/session'
import { close_wait, wait } from '../../../components/layout'
import { tCliente } from './tcliente.class'
import template from './tcliente.edit.html'

export default class extends tCliente implements Form {
    static template = template
    title = 'Tipos de Cliente'
    async init(x) {
        if (x) await this.request(x.id)
        return form.save(this)
    }

}