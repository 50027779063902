import { Data } from "../../../api/data";
import { form, Form } from "../../../api/form";
import { source } from "../../../api/source";
import { Type } from "../../../api/type";
import { nformat } from "../../../api/util";
import { notify } from "../../../components/layout";
import { despachador_almacen_list, operador_almacen_list } from "../inventario/wms.inventario.list";
import template from './wms.orden.auditar.html'

export default class extends Data {
    static template = template

    orden = ''
    cliente = ''
    status = ''
    renglones = 0;
    entregado = 0;
    valor = 0;
    auditado = {
        renglones: 0, unidades: 0, valor: 0, orden: ''
    }
    nformat = nformat
    constructor() {
        super()
    }
    async init(x) {
        this.orden = x.documento
        this.cliente = x.nombre
        this.status = x.status
        this.renglones = x.renglones
        this.entregado = x.entregado
        this.valor = x.entregado_valor

        return form.done(this, async () => {

            this.auditado.orden = x.id
            await source().request('/wms.orden/auditar', this.auditado)
        })
    }
}

