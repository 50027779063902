import { Obj } from "../api/obj"

class Container {
    static template =/*html*/`
        <div class="container-fluid" >
        </div>
    `
}

class Row {
    static template =/*html*/`
        <div class="row">
            <content></content>
        </div>
    `
}

class Cell extends Obj {
    static template =/*html*/`
        <div class="col">
            <content></content>
        </div>  
    `
}





export default {
    container: Container,
    row: Row,
    cell: Cell,
}



