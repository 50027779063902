import { form, Form } from '../../../api/form';
import { Obj } from '../../../api/obj';
import template from './import.form.html'
import Excel from 'exceljs'
import { download } from '../../../api/util';
import { get_file, read_file } from '../../../components/controls';

export interface import_args {
    columns: { header: string, key: string, width?: number }[]
    data?: any[]
    file_name: string

}

class Import extends Obj {
    static template = template
    columns: { header: string, key: string }[]
    data = [];
    file_name: string
    async init(x: import_args) {
        this.columns = x.columns
        this.data = x.data || this.data;
        this.file_name = x.file_name || 'guia-de-cargar.xlsx'
        return form.done(this)
    }
    async generar_guia() {
        var wb = new Excel.Workbook()
        var guia = wb.addWorksheet('guia')
        // columnas
        guia.columns = this.columns
        for (let row of this.data) guia.addRow(row)
        var file = await wb.xlsx.writeBuffer()
        download(new File([file], this.file_name));

    }
    async cargar_guia() {
        var file = await get_file()

        let stream = read_file(file[0])


        var wb = new Excel.Workbook()
        await wb.xlsx.load(<any>stream);
        var result = []
        var names = this.columns.map(x => x.key)

        wb.eachSheet((x) => {
            x.eachRow((x: any, i) => {
                if (i == 1) return
                var values: any[] = x.values.splice(1)
                var r = {}
                for (let i in names) r[names[i]] = values[i]

                result.push(r)
            })
        })
        this.trigger('accept', result)
    }
}


export async function importer(x: import_args) {
    return await form.modal(Import, x)
}