import { form } from '../../../api/form'
import { upload } from '../../../api/http';
import { source } from '../../../api/source';
import template from './wms.recepcion.ordenes.html'


export default class extends form.search(template, 'wms.recepcion/ordenes-pendientes', { detail: '/wms/recepcion-recibir' }) {
    dom: HTMLElement;
}

// 847964