import { Obj } from "../../../api/obj"
import { source } from "../../../api/source"
import { ocopy, replace_array } from "../../../api/util"
import { Tracking } from "./wms.inventario.face"

export class Ubicacion {
    id: string = ''
    contenedor: string = ''
    tipo = ''
    capacidad = 0
    createdin: Date = null
    createdby = ''
    movimientos: Tracking[] = []
    inventario: { merc: string, nombre: string, cantidad: number }[] = []
    paletas: { id: string, contenido: { id: string, nombre: string, existe: number }[] }[] = []
    seriales: { id: string, contenedor: string }[] = []
    recibe_de_recepcion: boolean
    contiene = {
        movimientos: false,
        inventario: false,
        paletas: false
    }

    reset(ubicacion: Ubicacion) {
        if (!ubicacion) return
        ocopy(ubicacion, this)

    }

    async request(ubicacion) {

        var r = await source().request('/wms.inventario/get-ubicacion', { ubicacion })
        this.reset(r)

    }
}

export class Serial {
    id: string = ''
    numero: string = ''
    contenedor: string = ''
    ubicacion: string = ''
    merc: string = ''
    nombre: string = ''
    vence: Date = null
    lote: string = ''
    createdin: Date = null
    createdby: string = ''
    tipo_retencion: string = ''
    movimientos: [] = []
    recepciones: {}[] = []
    despachos: {}[] = []
    reset(serial: Serial) {
        if (!serial) return
        ocopy(serial, this)
    }

    async request(serial) {

        var r = await source().request('/wms.inventario/get-serial', { serial })

        this.reset(r)
    }
}

export class Mercancia extends Obj {
    id: string = ''
    nombre: string = ''
    referencia: string = ''
    existe: number = 0
    disponible: number = 0
    maneja_vence = false
    vencimiento: { vence: Date, estado: string, dias: number, detalle: { contenedor: string, existe: number, disponible: number }[] }[] = []



    ubicaciones: { ubicacion: string, cantidad: number }[] = []
    movimiento: { documento: string, fecha: Date, cantidad: number, balance: number }[] = []
    ordenv: { documento: string, fecha: Date, entidad: string, cantidad: number }[] = []
    ordenc: { documento: string, fecha: Date, entidad: string, cantidad: number }[] = []
    reset(mercancia: Mercancia) {
        if (!mercancia) return
        ocopy(mercancia, this)
    }

    async request(mercancia, fecdes: Date, fechas: Date) {
        var r = await source().request('/wms.inventario/get-mercancia', { mercancia, fecdes, fechas })

        this.reset(r)
    }
}