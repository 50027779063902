import { Type } from "./type";
import { ControlSource, get_control } from "./get_control";
import { date } from "../modules/sistema/date/date.class";


export namespace input {
    export function bind(input: HTMLElement | HTMLInputElement | HTMLSelectElement, context: any) {
        if (input instanceof HTMLInputElement == false && input instanceof HTMLSelectElement == false) return
        //  obtiene el source
        const src = input.getAttribute('src');
        if (!src) return
        var control = get_control<any>(src, context);
        if (control.value instanceof Type.Instance) control = get_control('value', control.value)

        if (input instanceof HTMLInputElement) {
            if (typeof control.value == 'boolean') {
                if (input.type != 'checkbox') return console.error(`input.bind: el controlador para tipo boolean debe ser un input tipo checkbox, campo(${src})`);
                check(input, control)
            }
            else if (control.value instanceof Array && input.type == 'checkbox') {
                array_check(input, control)
            }
            else textbox(input, control)
        }
        else if (input instanceof HTMLSelectElement) {
            listbox(input, control)
        }



    }

    export function textbox(input: HTMLInputElement, control: ControlSource) {

        var type = typeof control.value

        if (type == 'number') {

            if (input.type != 'number') return console.error(`input.bind: el controlador para tipo number debe ser un input tipo number, campo(${control.source})`);
            number(input, control)
        }
        else if (type == 'string') {
            if (!['text', 'password'].find(x => x == input.type)) return console.error(`input.bind: el controlador para tipo string debe ser un input tipo text, campo(${control.source})`);
            text(input, control)
        }
        else if (control.value instanceof Date) {
            if (input.type != 'date') return console.error(`input.bind: el controlador para tipo date debe ser un input tipo date, campo(${control.source})`);
            date_input(input, control)
        }

    }
    export function textarea(input: HTMLTextAreaElement, control: ControlSource) {
        text(input, control)
    }

    export function listbox(input: HTMLSelectElement, control: ControlSource) {

        list(input, control)

    }

    export function array_check(input: HTMLInputElement, control: ControlSource<any[]> | Type.Array) {
        var tag = input.getAttribute('tag')

        //  configurando evento para paso de datos al control
        input.addEventListener('change', function (e) {


            if (!tag) throw 'check: Para grabar en un arreglo debe especificar la propiedad tag'


            if (input.checked) {
                control.value.push(tag)
            }
            else {
                while (control.value.indexOf(tag) > -1) control.value.splice(control.value.indexOf(tag), 1)
            }



        });

        //  actualizando el input
        function refresh() {
            input.checked = !!control.value.find((x: any) => x == tag)
        }

        //  creando disparador para actualizar el input
        if (control instanceof Type.Array) control.watch('value', refresh)
        else control.watch(refresh)
        refresh()
    }



    export function check(input: HTMLInputElement, control: ControlSource<boolean>) {
        //  configurando evento para paso de datos al control
        input.addEventListener('change', function (e) {
            control.value = input.checked
        });

        //  actualizando el input
        function refresh() {
            input.checked = control.value
        }

        //  creando disparador para actualizar el input

        if (control instanceof Type.Boolean) control.watch('value', refresh)
        else control.watch(refresh)
        refresh()
    }

    export function text(input: HTMLInputElement | HTMLTextAreaElement, control: ControlSource<string> | Type.Instance<string>) {
        input.addEventListener('keyup', function (e) {
            control.value = input.value
        });
        function refresh() {
            input.value = control.value
        }

        if (control instanceof Type.Instance) control.watch('value', refresh)
        else control.watch(refresh)
        refresh();
    }
    export function list(input: HTMLSelectElement, control: ControlSource<string> | Type.Instance<string>) {
        input.addEventListener('change', function (e) {
            control.value = input.value
        });
        function refresh() {
            input.value = control.value
        }
        if (control instanceof Type.Instance) control.watch('value', refresh)
        else control.watch(refresh)
        setTimeout(refresh, 150);

    }

    export function number(input: HTMLInputElement, control: ControlSource<number> | Type.Instance<number>) {
        input.addEventListener('keyup', function (e) {
            control.value = input.valueAsNumber
        });
        function refresh() {

            input.valueAsNumber = control.value
        }
        if (control instanceof Type.Instance) control.watch('value', refresh)
        else control.watch(refresh)
        refresh();
    }

    export function date_input(input: HTMLInputElement, control: ControlSource<Date> | Type.Instance<Date>) {
        input.addEventListener('change', function (e) {

            control.value = date(input.value).to_date()
        });
        function refresh() {
            input.valueAsDate = control.value
        }
        if (control instanceof Type.Instance) control.watch('value', refresh)
        else control.watch(refresh)
        refresh();
    }

}
// export function list(input: HTMLElement, context: Component) {
//     //  verificando se sea un input
//     if (!$(input).is('select')) return


//     //  obteniendo el control
//     var control = get_source(input, context)
//     if (!control) return

//     //  configurando evento para paso de datos al control
//     $(input).on('change', function (e) {
//         control.value = $(input).val()

//     });

//     //  creando disparador para actualizar el input
//     function refresh() {
//         $(input).val(control.value)
//     }
//     control.watch(refresh)
//     event_handler(context).on('repeat', refresh)

//     //  inicializa los datos en el input
//     refresh();

// }



// export function dateinput(input: HTMLElement, context: Component) {
//     //  verificando se sea un input
//     if (!$(input).is('input[type=date]')) return



//     //  obteniendo el control
//     var control = get_source(input, context)
//     if (!control) return

//     // //  configurando evento para paso de datos al control
//     // var timeout;

//     $(input).removeAttr('type')
//     var datepick = jquery(input).datepicker({
//         uiLibrary: 'bootstrap4',
//         format: 'dd/mm/yyyy',
//         change() {
//             var value = datepick.value().split('/')

//             control.value = new Date(value[2], parseInt(value[1]) - 1, value[0])

//             if (context.parent instanceof Search) {
//                 // context.parent.save(context.item)
//             }

//         },
//         disableDates(date) {
//             if (!control.context.filter_date) return true
//             return control.context.filter_date(date)
//         }
//     })


//     //  creando disparador para actualizar el input
//     function refresh() {
//         if (control.value instanceof Date) {
//             var d = control.value
//             var value = d.getDate().toString().padStart(2, '0') + '/' + (d.getMonth() + 1).toString().padStart(2, '0') + '/' + d.getFullYear()
//             datepick.value(value)
//         }

//     }
//     control.watch(refresh)


//     //  inicializa los datos en el input
//     refresh();

// }





// export class Control extends Obj implements Component {
//     template: string
//     props = ['title', 'error_msg', 'disable', 'tag', 'include']
//     dom: HTMLElement;
//     title = 'Text'
//     tag = ''
//     error_msg = ''
//     disable = false
//     content = []
//     include = '';
//     protected selector = 'input'

//     setup(source: HTMLElement) {
//         const parent = this;
//         //  gestionando src
//         var src = $(source).attr('src')
//         if (!src) return

//         //  gestionando el control
//         var control = get_control<any>(src, this)

//         var input = $(this.selector, this.dom)[0]

//         //  configurando
//         if (control.value instanceof Type.Instance) {

//             //  conectando titulo
//             if (!$(source).attr('title')) $(source).attr('title', src + '.title')

//             //  conectando error_msg
//             if (!$(source).attr('error_msg')) $(source).attr('error_msg', src + '.error_msg')

//             //  conectando enabled
//             if (!$(source).attr('disable')) $(source).attr('disable', src + '.disable')

//             //  conectando hide
//             if (!$(source).attr('hide')) $(source).attr('hide', src + '.hide')


//             //  tratando lista para select
//             var field: Type.Instance<any> = control.value
//             if (field instanceof Type.Link && field.list) {

//                 this.content = field.list.data
//                 $(source).attr('content', src + '.list.data')
//             }

//             $(input).on('focusout', () => {
//                 field.valid()
//             })
//             src = src + '.value'
//         }

//         //  reconfigurando src
//         $(input).attr('src', src)

//         this.watch('disable', function () {
//             $(parent.selector, parent.dom).prop('disabled', parent.disable)
//         })

//         //  tratando tags
//         if ($(input).is('[type=checkbox]')) {

//             parent.watch('tag', function () {
//                 $(input).attr('tag', parent.tag)
//             })

//             //  que funcione haciendo click al label
//             $('label', this.dom).on('click', function () {
//                 $(input).trigger('click');
//                 return false
//             })

//         }



//         return control
//     }
// }

// //  Este es el textbox del sistema
// export class Text extends Control {
//     template = /*html*/`
//         <div class="form-group control-box" c-if="!hide">
//             <label>{{title}}</label>
//             <input type="text" class="form-control" c-class='{"border-danger is-invalid":!!error_msg}'>
//             <small class="form-text text-muted">{{error_msg}}</small>
//         </div>
//     `
// }


// export class Check extends Control {
//     template = /*html*/`
//         <div class="form-group form-check"  c-if="!hide">
//             <input type="checkbox" class="form-check-input">
//             <label class="form-check-label">{{title}}</label>
//         </div>
//     `

// }


// export class List extends Control {
//     template =/*html*/ `
//         <div class="form-group control-box"  c-if="!hide">
//             <label>{{title}}</label>
//             <select class="form-control" c-class='{"border-danger is-invalid":!!error_msg}'>
//                 <option c-for="item in content" :value="item.id" :include="include">
//                     {{item.display||item.nombre}}
//                 </option>
//             </select>
//             <small class="form-text text-muted">{{error_msg}}</small>
//         </div>
//     `
//     selector = 'select'
// }


// export default { text: Text, check: Check, list: List }

