import { add_component, start } from "../../api/component";
import { Http } from "../../api/http";
import { add_source } from "../../api/source";


import './wms.routes'
import main from './pages/main/wms.main'


var http = new Http(location.hostname == 'localhost' ? 'http://localhost:3000/' : 'https://data.chalonasoft.com')
add_source(http)
add_component({ main })
start('wms')

