import { component, render_component } from "../api/component";
import { ControlSource } from "../api/get_control";
import { List, SubList } from "../api/list";
import { Obj } from "../api/obj";
import { create_element } from "../api/util";

var current: List
var root: Tree;

const template =/*html*/`
    <ul :visible="open">
        <tree-node c-for="node in list.data" :key="node.id" :display="node.display||node.nombre"></tree-node>
    </ul>
`


export class NodeTree extends Obj {
    static template =/*html*/`
        <li class="node-tree" >
            <icon :visible="has_node" :name="{{open?'minus':'plus'}}" @click="toggle()" pointer="pointer"></icon>
            <span style="margin-left:23px" :visible="!has_node"></span>
            
            <btn @keyup="keyup($event,dom)" @focusin="select()" style="cursor:pointer">{{display}}</btn>
        </li>
    `
    props = ['key', 'display']
    key = ''
    display = ''
    source = current
    list: SubList
    root: Tree
    dom: HTMLElement
    started = false
    open = false
    has_node = false;
    constructor() {
        super()
        this.root = root
        this.list = new SubList(current, (x, arg) => x.control == arg.key)

        this.watch('key', (key) => {
            this.list.request({ key })
            this.has_node = !!this.source.data.find(x => x.control == key)
        })

    }
    select() {
        var elements = this.root.dom.getElementsByTagName('button')
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.fontWeight = 'normal'
        }

        this.dom.getElementsByTagName('button')[0].style.fontWeight = 'bold'
        var event = new CustomEvent("select", {
            detail: {
                node: this
            }
        });
        this.root.current = this

        this.root.dom.dispatchEvent(event);
    }
    keyup(event, dom: HTMLElement) {
        if (event.key == 'ArrowRight' && this.has_node && !this.open) this.toggle()
        else if (event.key == 'ArrowLeft') {
            if (this.open && this.has_node) this.toggle()
            else {
                if (dom.parentElement && dom.parentElement.parentElement) boton = dom.parentElement.parentElement.getElementsByTagName('button')[0]
            }
        }
        else if (event.key == 'ArrowDown' || event.key == 'ArrowRight') {


            var boton: HTMLElement
            if (!this.open) {
                if (dom.nextElementSibling) boton = dom.nextElementSibling.getElementsByTagName('button')[0]
            } else {
                var li = dom.getElementsByTagName('li')[0]
                if (li) boton = li.getElementsByTagName('button')[0]
            }
            if (!boton) {
                if (dom.parentElement.parentElement.nextElementSibling) boton = dom.parentElement.parentElement.nextElementSibling.getElementsByTagName('button')[0]
            }
        }
        else if (event.key == 'ArrowUp') {
            if (dom.previousElementSibling) {
                var node: NodeTree = component(<any>dom.previousElementSibling)
                while (node.open) {
                    if (node.dom.lastElementChild && node.dom.lastElementChild.lastElementChild) {
                        node = component(<any>node.dom.lastElementChild.lastElementChild)
                    }
                }
                boton = node.dom.getElementsByTagName('button')[0]

                //boton = dom.previousElementSibling.getElementsByTagName('button')[0]
            }
            if (!boton) {
                if (dom.parentElement.parentElement) boton = dom.parentElement.parentElement.getElementsByTagName('button')[0]
            }
        }
        if (boton) boton.focus()

    }
    toggle() {
        if (!this.started) {
            root = this.root
            var lista = create_element(template)
            render_component(lista, this)
            this.dom.append(lista)
            this.started = true

        }
        this.open = !this.open
    }
}



export class Tree extends Obj {
    static template =/*html*/`
        <div class="tree">
        </div>
    `
    control: ControlSource
    dom: HTMLElement
    source: List
    current: NodeTree;
    constructor() {
        super()

        //  capturando control
        this.watch('control', async () => {
            //  validando
            if (this.control.value instanceof List == false) throw ('tree: src debe ser una instancia de List')

            //  haciendo la lista accesible a todos los nodos
            this.source = current = this.control.value

            //  elemento root
            root = this
            var first = new NodeTree()

            first.dom = this.dom
            first.key = null
            first.toggle()

        })
    }
    get(key): NodeTree {
        var dom = this.dom.querySelector(`[key="${key}"]`)
        var node: NodeTree = component(<any>dom)
        return node
    }
    select(key: string) {
        return new Promise<void>((ok, cancel) => {
            var node = this.source.get(key)
            var nodes: { id: string, nombre: string, display: string, control: string }[] = [];
            while (node) {
                nodes.unshift(node)
                var node = this.source.get(node.control)
            }

            var recursivo = () => {
                var n = nodes.shift()
                if (!n) {
                    ok()
                    return
                }

                var li = this.dom.querySelectorAll(`li[key="${n.id}"]`)[0]
                if (li) {
                    var x: NodeTree = component(<any>li)
                    if (x.has_node && x.key != key) {
                        if (!x.open) {
                            x.toggle()
                            setTimeout(() => {
                                recursivo()
                            }, 100);
                        }
                    }
                    else {
                        x.dom.getElementsByTagName('button')[0].focus()
                        ok()
                    }


                }
                // var dom = component()
            }
            recursivo()

        })

    }
}

export default { tree: Tree, 'tree-node': NodeTree }
