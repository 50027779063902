import { PreparedStatement } from 'mssql'
import { Data } from '../../../api/data'
import { create_dialog, dialog } from '../../../api/dialog'
import { form } from '../../../api/form'
import { Obj } from '../../../api/obj'
import { router } from '../../../api/router'
import { source } from '../../../api/source'
import { ocopy } from '../../../api/util'
import { layout } from '../../../components/layout'
import template from './factura.detalle.html'


export default class extends Obj {
    static template = template
    layout = layout
    info: {} = {
        documento: '',
        referencia: '',
        fecha: '',
        entidad: '',
        nombre: '',
        comentario: '',
        valor: '',
        descuento: '',
        neto: '',
        itbis: '',
        total: '',
        ncf: '',
        direccion: '',
        telefono: '',
        email: '',
        createdby: '',
        detalle: []
    }
    async init(x: any) {

        this.layout.title = x.numero
        this.request(x.id)
        return form.done(this)
    }
    async request(x) {

        //donde es?
        var r = await source().request('/wms.facturas/factura-info', { orden: x })
        ocopy(r, this.info)


    }

}