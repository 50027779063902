import { Detail, Table } from '../../../api/table';
import { Type } from "../../../api/type"

export class Perfil extends Table {
    nombre = new Type.String({ required: true })
    inactivo = new Type.Boolean()
    disponible = new Type.Array()
    funciones = new Type.Array()
    // permiso = new Detail(Permiso);

    constructor() {
        super('perfil')
    }
}

class Permiso extends Table {
    menu = new Type.String()
    descrip = new Type.String({ no_update: true })
    categoria = new Type.String({ no_update: true })
    disponible = new Type.Array({ no_update: true })
    permisos = new Type.Array()
    constructor() {
        super('permiso', { detail: true })
    }
}


