const isMobile = require("is-mobile");
import { Router, router } from "../api/router";
import { watch } from "../api/watch";
import template from './layout.html'
import { create_list, SubList } from '../api/list';
import session from "../api/session";
import { Obj } from "../api/obj";


const menu_list = create_list('/menu/list')

const sub_menu = new SubList(menu_list, function (x, args = {}) {
    args.modulo = args.modulo || null;
    return (!layout.menu.short && x.modulo === args.modulo) || (layout.menu.short && x.modulo !== null)
})
var search_timeout;

export interface layout_face {
    mode: 'normal' | 'fullscreen' | 'modal',
    title: string,
    is_mobile: boolean,
    add: { active: boolean },
    download: { active: boolean },
    upload: { active: boolean },
    print: {
        active: boolean
    },
    note: { active: boolean },
    filter: { active: boolean },
    warning: { active: boolean },
    camera: {
        display: boolean,
        take_photo(): Promise<any>
    },
    search: {
        text: string,
        active: boolean,
        display: boolean,
        buscar(): any,
        action(): any
    },
    menu: {
        title: string,
        data: any,
        open: false,
        short: false,
        current: null,
        go(item, dom: HTMLElement): any,
        logoff()
    },
    wait: {
        title: string,
        open: boolean
    },
    notify: {
        message: string,
        open: boolean,
        color: string
    }
};




export const layout: layout_face = {
    mode: 'normal',
    title: 'Sistema',
    is_mobile: isMobile(),
    add: { active: false },
    download: { active: false },
    upload: { active: false },
    print: {
        active: false
    },
    note: { active: false },
    filter: { active: false },
    warning: { active: false },
    camera: {
        display: false,
        async take_photo() {
            var r = await router.modal('/camera')
            router.exec('photo', r)
        }
    },
    search: {
        text: '',
        active: false,
        display: false,
        buscar() {
            if (search_timeout) clearTimeout(search_timeout)
            search_timeout = setTimeout(() => {
                var input = <HTMLInputElement>document.getElementById('main-search-text');
                this.text = input.value
            }, 150);
        },
        action() {
            var input: any = document.getElementsByClassName('search-box-input')[0]
            if (this.display && this.text) {
                this.text = '';
                input.value = ''
                input.focus()
                return
            }

            this.display = !this.display
            if (this.display) {
                setTimeout(() => {
                    input.focus()
                }, 10);
            }
        }
    },
    menu: {
        title: 'Menu Principal',
        data: sub_menu.data,
        open: false,
        short: false,
        current: null,
        go(item, dom: HTMLElement) {
            this.current = item
            if (!item) {
                this.title = 'Menu Principal'
                return sub_menu.request({ modulo: null })
            }
            else if (!item.url) {
                this.title = item.titulo
                return sub_menu.request({ modulo: item.id })
            }
            else {
                router.go(item.url)
                document.querySelectorAll('.menu-item').forEach(x => x.classList.remove('active'))
                dom.classList.add('active')
            }
        },
        logoff() {
            layout.menu.open = false
            router.exec('logoff')
        }
    },
    wait: {
        title: 'Espere por favor...',
        open: false
    },
    notify: {
        message: '',
        open: false,
        color: 'default'
    }
};

watch(layout.search, 'text', (v) => {
    router.exec('search', v)
})

window.addEventListener('resize', () => {
    layout.is_mobile = isMobile()
})

class Layout extends Obj {
    static template = template
    layout = layout
    router = router
    props = ['menu-short']
    //  = false
    dom: HTMLElement;
    constructor() {
        super()
        this['menu-short'] = false
        this.watch('menu-short', (v) => {
            this.layout.menu.short = v
            sub_menu.request()
        })

    }
    setup() {
        var input = this.dom.getElementsByClassName('search-box-input')[0]
        input.addEventListener('focusout', () => {
            if (!layout.search.text) {
                layout.search.display = false
            }
        })
    }

}

export default {
    layout: Layout,
}

export function wait(title?: string) {
    layout.wait.open = true
    layout.wait.title = title || 'Espere por favor..'
}
export function close_wait() {
    layout.wait.open = false

}



export const notify = {
    show(message, color) {
        layout.notify.color = color
        layout.notify.message = message
        setTimeout(() => {
            layout.notify.open = true
            setTimeout(() => {
                layout.notify.open = false
            }, 3000);
        }, 200);

    },
    info(message) {
        notify.show(message, 'info')
    },
    error(message) {
        notify.show(message, 'danger')
    },
    success(message) {
        notify.show(message, 'success')
    }

}
