import { type } from "jquery";
import { Table } from "../../../api/table";
import { Type } from "../../../api/type";
import { tmerc_list } from "../../merc/tmerc/tmerc.list";

export interface tentidad_face {
    id: string
    nombre: string
    contador: number
    manual: string
    funcion: string
    data: any
}

export class tEntidad extends Table {
    nombre = new Type.String()
    // contador = new Type.Link()
    funcion = new Type.Array()
    tmerc = new Type.Array()
    datos = new Type.Json()
    tmercs = tmerc_list

    reset(x: any = {}) {
        return super.reset(x);
    }
}
