import { appendFile } from 'fs';
import { form } from '../../../api/form'
import { upload } from '../../../api/http';
import session from '../../../api/session';
import { source } from '../../../api/source';
import template from './wms.despacho.ordenes.html'


export default class extends form.search(template, 'wms.despacho/ordenes-pendientes', { detail: '/wms/despacho-recoger' }) {
    acceso_actualizar = true;
    procesando_actualizacion = false

    titulo_boton = 'Cargar Actualizaciones de Ordenes'
    async actualizar() {

        if (session.user.id != 'florentino' && session.user.id != 'warlin') {
            alert('Acceso denegado')
            return
        };


        if (!confirm('Esta apunto de actualizar las ordenes de compras\neste proceso puede tardar varios minutos y relentizar el sistema\n\núselo con mesura!!!\n\n¿Esta seguro que desea continuar?')) return;
        this.procesando_actualizacion = true;
        this.titulo_boton = 'Espere mientras se realiza el proceso'
        try {
            await source().request('wms.despacho/actualizar')
            alert('Operacion completada')
            location.reload();
        } catch (e) {
            alert('Fallo la actualizacion intente de nuevo')
        }
        this.titulo_boton = 'Cargar Actualizaciones de Ordenes'
        this.procesando_actualizacion = false;
    }
}