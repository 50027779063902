import { PreparedStatement } from 'mssql'
import { Data } from '../../../api/data'
import { create_dialog, dialog } from '../../../api/dialog'
import { form } from '../../../api/form'
import { dia_mes_list, mes_list } from '../../../api/list'
import { router } from '../../../api/router'
import { Search } from '../../../api/search'
import { source } from '../../../api/source'
import { Type } from '../../../api/type'
import { date_diff, ocopy, replace_array } from '../../../api/util'
import { close_wait, wait, notify } from '../../../components/layout'
import { get_impresora, set_impresora } from '../../sistema/impresion/impresion.seleccionar-impresora'
import { anio_list } from './wms.recepcion.list'
import template from './wms.recepcion.recibir.html'


class Conteo extends Data {
    display = false
    id = new Type.String({ title: 'ID' });
    descrip = new Type.String({ title: 'Nombre' })
    vence = new Type.Day({ title: 'Vence' })
    lote = new Type.String({ title: 'Lote' })
    cantidad = new Type.Number({ title: 'Cantidad' })
    averiadas = new Type.Number({ title: 'Averiadas' })
    dia = new Type.Link({ list: dia_mes_list, title: 'Dia' })
    mes = new Type.Link({ list: mes_list, title: 'Mes' })
    anio = new Type.Link({ list: anio_list, title: 'Año' })

    maneja_vence = new Type.Boolean();
    maneja_lote = new Type.Boolean();
    vida_util = new Type.Number();


    constructor() {
        super();

        var valida_fecha = () => {

            if (['1', '3', '5', '9', '10'].find(x => x == this.mes.value)) {
                if (this.dia.value == '31') {
                    this.dia.value = '30'
                }

                if (this.mes.value == '1') {
                    if (this.dia.value == '30') {
                        this.dia.value = '29'
                    }

                    if (this.dia.value == '29' && this.anio.value && parseInt(this.anio.value) % 4 > 0) {
                        this.dia.value = '28'
                    }
                }
            }
        }

        this.mes.watch('value', valida_fecha)
        this.dia.watch('value', valida_fecha)
        this.anio.watch('value', valida_fecha)
    }
}

const confirmar_vencidas = create_dialog({
    title: 'Confirmacion de Vencimiento',
    message: 'Esta mercancia está vencida o proxima a vencerse, especifique si la va recibirlo como RETENIDO o de forma NORMAL',
    no_btn_close: true,
    no_close: true,
    btns: [
        { value: 'retener', title: 'RETENIDO', class: "btn-success" },
        { value: 'normal', title: 'NORMAL', class: "btn-warning" },
        { value: 'cancelar', title: 'Cancelar', class: "btn-default" },
    ]
});

export default class extends form.search(template, '/wms.recepcion/list-merc', { no_request: true }) {

    orden = { orden: '', suplidor: '', referencia: '', nota: '' };
    conteo = new Conteo();
    layout: any
    hay_diferencias = false
    cerrar_orden_modal = false
    solicitar_confirmacion = new Type.Boolean({ title: 'Desea cerrar esta orden?' })
    diferencias = [];
    confirmacion = '';
    async init(x: any) {
        this.layout.title = 'Orden: ' + x.referencia

        ocopy(x, this.orden)


        this.on('detail', async (x) => {
            ocopy(this.orden, x)
            var r = await router.modal('/wms/recepcion-contar', x)
            await this.request()
            return

            this.conteo.reset()
            this.conteo.set(x)

            if (x.maneja_lote) {
                this.conteo.lote.required = true
            }
            if (x.maneja_vence) {
                var vence = new Date(x.vence)

                this.conteo.dia.value = vence.getDate() + ''
                this.conteo.mes.value = vence.getMonth() + ''
                this.conteo.anio.value = vence.getFullYear() + ''

                this.conteo.anio.required = true
                this.conteo.mes.required = true
                this.conteo.dia.required = true
            }
            this.conteo.display = true
        })
        this.start(x)

        this.on('accept', async () => {

            var r = await source().request('/wms.recepcion/diferencias', this.orden)
            replace_array(this.diferencias, r)
            setTimeout(() => {
                this.cerrar_orden_modal = true
                this.solicitar_confirmacion.value = false
                this.hay_diferencias = !!this.diferencias.length

            }, 100);
        })

        return new Promise<void>(async (ok, cancel) => {
            this.on('cerrar', async () => {
                ok()
            })
            this.on('cancel', async () => {
                ok()
            })
        })
    }

    async paleta_recepcion() {
        try {

            var impresora = await get_impresora()
            if (!impresora) impresora = await set_impresora()

            var r = await source().request('/wms.inventario/crear-paleta', { impresora: impresora })
            this.notify(r)
        } catch (error) {
            this.error(error)
        }


    }


    async cerrar() {
        if (this.solicitar_confirmacion.value) {
            if (this.orden.referencia != this.confirmacion) return this.error('Fallo la confirmacion de la orden')
            try {
                wait('Cerrando Recepcion...')
                await source().request('/wms.recepcion/cerrar', this.orden)
                // notify('Orden Cerrada!')
            } catch (e) {
                this.error(e)
            }
            close_wait()
        }
        this.cerrar_orden_modal = false
        this.trigger('cerrar')

    }
    async foto() {
        this.conteo.display = false
        await router.modal('/camera')
        this.conteo.display = true
    }
    async contar() {
        try {
            await this.conteo.valid()
            if (!this.conteo.cantidad.value && !this.conteo.averiadas.value) {
                this.conteo.cantidad.error('Cantidad es Requerida')
                return Promise.reject('Especifique la cantidad')
            }

            //  calcula la fecha de vencimiento
            this.conteo.vence.value = new Date(parseFloat(this.conteo.anio.value), parseFloat(this.conteo.mes.value), parseFloat(this.conteo.dia.value))


            //  obteniendo los datos
            var data = this.conteo.get()
            data.orden = this.orden
            ocopy(this.orden, data)
            data.tipo_retencion = null

            //  calcula los dias que faltan para de vencimiento
            var dias_vence = date_diff(new Date(), this.conteo.vence.value).days()

            //  determina si hay vencimiento
            data.retenido_vence = false;
            if (dias_vence <= 0 || dias_vence < this.conteo.vida_util.value / 2) {
                this.conteo.display = false
                var confirm = await confirmar_vencidas()
                if (confirm == 'cancelar') return

                if (confirm == 'retener') {
                    data.tipo_retencion = 'VENCIMIENTO'
                }
            }

            //  procesa los datos
            wait('Espere mientras se procesan los datos')
            await source().request('/wms.recepcion/contar', data)
            this.conteo.display = false
            close_wait()
            this.notify('Operacion completada!!!')
            this.request()
        }

        catch (e) {
            this.error(e)
        }


    }

    async mover() {
        router.modal('/wms/inventario-movimiento')

    }
}