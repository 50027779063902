import { domain } from "process";
import { source } from "../../../api/source"
export type date_obj_arg = { year?: number, month?: number, day?: number, hour?: number, minute?: number, second?: number }

export type date_arg = number[] | string | date_obj_arg | DateObj | Date


export class DateObj {
    private data = new Date();
    constructor(x?: date_arg) {
        this.set(x)
    }
    set(x: date_arg) {
        var parts: number[];

        if (x instanceof Date) {
            x = ['getFullYear', 'getMonth', 'getDate', 'getHours', 'getMinutes', 'getSeconds'].map(i => x[i]())
            x[1]++
        }

        if (x instanceof DateObj) x = x.to_array()

        if (typeof x == 'string') {
            var cparts = x.split(' ');
            parts = [].concat(cparts[0].split('-').map(x => parseInt(x)));
            if (cparts[1]) cparts[1].split(':').map(x => parseInt(x.split('.')[0])).forEach(x => parts.push(x));
        }

        if (x instanceof Array) parts = x;

        if (parts) {

            if (parts[1]) parts[1]--

            for (let i = parts.length; i < 6; i++)parts[i] = 0
            this.data.setFullYear(parts[0])
            this.data.setMonth(parts[1])
            this.data.setDate(parts[2])
            this.data.setHours(parts[3])
            this.data.setMinutes(parts[4])
            this.data.setSeconds(parts[5])
        }

        else if (x instanceof Object) {
            for (let i in x) if (this['set_' + i]) this['set_' + i](x[i])
        }

        return this;
    }

    set_year(x: number) {
        this.data.setFullYear(x)
        return this;
    }
    set_month(x: number) {
        this.data.setMonth(x - 1)
        return this;
    }
    set_day(x: number) {
        this.data.setDate(x)
        return this;
    }
    set_hour(x: number) {
        this.data.setHours(x)
        return this;
    }
    set_minute(x: number) {
        this.data.setMinutes(x)
        return this;
    }
    set_second(x: number) {
        this.data.setSeconds(x)
        return this;
    }

    year() {
        return this.data.getFullYear()
    }
    month() {
        return this.data.getMonth() + 1
    }
    day() {
        return this.data.getDate()
    }
    hour() {
        return this.data.getHours()
    }
    minute() {
        return this.data.getMinutes()
    }
    second() {
        return this.data.getSeconds()
    }
    dow() {
        return this.data.getUTCDay()
    }
    add(add: date_obj_arg) {
        var year: number;
        var month: number;

        var r = date(this)

        if (add.day) r.data = new Date(r.data.getTime() + (add.day * 24 * 60 * 60000))

        if (add.month) {
            var year = Math.floor((add.month + this.data.getMonth()) / 12)
            var month = (add.month + this.data.getMonth()) % 12
            if (year) r.set_year(this.data.getFullYear() + year)
            r.set_month(month)
        }

        return r;
    }

    first_day() {
        return date(new Date(this.data.getFullYear(), this.data.getMonth()))
    }

    diff(x?: date_arg) {

        var date: DateObj = x instanceof DateObj ? x : new DateObj(x)

        // To calculate the time difference of two dates 

        return {
            days: () => {
                return Math.floor(date.data.getTime() / (1000 * 3600 * 24)) - Math.floor(this.data.getTime() / (1000 * 3600 * 24))
            },
            minutes() {

            }

        }
    }
    to_array() {
        return ['year', 'month', 'day', 'hour', 'minute', 'second'].map(x => this[x]())
    }
    to_string() {
        return ['year', 'month', 'day'].map(x => this[x]()).map(x => (x + '').padStart(x < 99 ? 2 : 4, '0')).join('-') + ' ' + ['hour', 'minute', 'second'].map(x => this[x]()).map(x => (x + '').padStart(2, '0')).join(':')
    }
    to_date() {
        return new Date(this.data.getTime())
    }

}

function days_of_month(month: number, year: number) {
    var bisiest = !!(year % 4)
    if (month == 1) return !!(year % 4) ? 29 : 28
    if ([3, 5, 8, 10].find(x => x == month)) return 30;
    return 31
}

export function date(x?: date_arg) {
    return new DateObj(x)
}

export async function now() {
    return date(await source().request('/date/now'))
}

