const Cast = require('TypeCast');
import { get_control } from "./get_control";
import { Matrix } from "./matrix";
import { Type } from "./type";
import QRCode from 'qrcode'
import { v4 as uuidv4 } from 'uuid';
import { source } from "./source";
import { date } from "../modules/sistema/date/date.class";
import ejs from 'ejs'
const queryString = require('query-string');
export const uuid = uuidv4


export const dia_semana_list = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']

export function blob2file(theBlob: Blob, fileName: string): File {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
}

export function get_url_args() {
    return queryString.parse(location.search)
}


export function clear_array(x: any[]) {
    if (!x) return
    while (x.length) x.shift()
}

export function get_prop(obj: any, prop: string): any {
    var ls = prop.split('.');

    while (ls.length) {
        prop = ls.shift()
        obj = obj[prop]
        if (!obj) break
    };
    return obj
}

export async function qr(x: string) {
    return QRCode.toDataURL(x)
}

export function array_sum(from: any[], list: string, ...to: any[]) {
    var x = list.split(',')
    to = to || [{}]
    for (let r of to) for (let i of x) r[i] = r[i] || 0;
    from.forEach((row) => {
        for (let i of x) {
            for (let r of to) {
                r[i] += parseFloat(row[i] + '')
            }
        }
    })
    return to;
}

export function download(file: File) {
    var url = window.URL.createObjectURL(file)
    var a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.style.display = 'none';
    a.click();
    a.remove();
    setTimeout(function () {
        return window.URL.revokeObjectURL(url);
    }, 1000);
};

export function date_add(add: { days?: number }, date?: Date) {
    var result = new Date(date || new Date());
    if (add.days) result.setDate(result.getDate() + add.days);
    return result
}

export function dia_1ro(dia = new Date()) {
    return new Date(dia.getFullYear(), dia.getMonth(), 1)
}

export function sort_fn(a, b, reverse = false) {
    if (reverse) return a == b ? 0 : a < b ? 1 : -1
    return a == b ? 0 : a < b ? -1 : 1
}
export function sort_by_expr_fn(expr, reverse = false) {
    return function (a, b) {
        return sort_fn(a[expr], b[expr], reverse)
    }
}

export function ocopy(from: any, to: any = {}) {
    if (from instanceof Array) {
        if (to instanceof Array == false) throw "ocopy: tratando de copiar un arreglo en un objeto que no lo es"
        replace_array(to, from)
    }
    else {
        for (let i in from) {

            if (typeof to[i] == 'function') continue;
            if (to[i] === null || from[i] === null) to[i] = from[i]
            else {
                // if (typeof to[i] != 'undefined' && typeof to[i] != typeof from[i]) throw `ocopy: tipos distintos en ${i}, from: ${from[i]} to ${to[i]}`;
                if (typeof to[i] == 'object' && to[i] instanceof Date == false) {
                    if (typeof from[i] != 'object') throw `ocopy: el destino espera un objeto en la propiedad ${i}`;
                    ocopy(from[i], to[i])
                }
                else if (typeof to[i] == 'undefined' || to[i] instanceof Date) to[i] = from[i]

                else to[i] = Cast[typeof to[i]](from[i])

            }
        }
    }
    return to
}

export function render(text: string, data: Object) {
    var x: any = data;
    text = text.toString();
    for (var i in x) {
        text = text.replace(new RegExp("\\$\\b" + i + "\\b", "g"), x[i]);
    }
    return ejs.render(text, data);
}

export function render_file(file, data) {
    return new Promise((ok, fail) => {
        ejs.renderFile(file, data, {}, (err, r) => {
            if (err) return fail(err)
            ok(r)
        })
    })
}


export function date_diff(date1: Date, date2?: Date) {
    if (!date2) {
        date2 = date1
        date1 = new Date()
    }
    // To calculate the time difference of two dates 
    var diff = date2.getTime() - date1.getTime();

    return {
        days() {
            // To calculate the no. of days between two dates 
            return Math.floor(diff / (1000 * 3600 * 24));
        }
    }
}


export function clone(x: Object) {
    return JSON.parse(JSON.stringify(x));
}

export function move(target: HTMLElement) {
    const box = target.getBoundingClientRect();
    const parent = target.parentElement.parentElement.getBoundingClientRect()
    var result = {
        to_top(fix: number = 0) {
            target.style.top = (5 + fix) + 'px'
            return result;
        },
        to_center(fix: number = 0) {
            target.style.top = (((parent.height - box.height) / 2) + fix) + 'px'
            return result;
        },
        to_bottom(fix: number = 0) {
            target.style.top = (parent.height - box.height - 5 + fix) + 'px'
            return result;
        },
        to_start(fix: number = 0) {
            target.style.left = (5 + fix) + 'px'
            return result;
        },
        to_justify(fix: number = 0) {
            target.style.left = (((parent.width - box.width) / 2) + fix) + 'px'
            return result;
        },
        to_end(fix: number = 0) {
            target.style.left = (parent.width - box.width + 5 + fix) + 'px'
            return result;
        },
        vertical(position: string) {
            var sign = position.search('-') < 0 ? '+' : '-';
            var expr = position.split(sign);
            var fix = parseFloat(sign + (expr[1] || '0'))
            position = expr[0]
            if (position == 'top') result.to_top(fix);
            else if (position == 'center') result.to_center(fix);
            else if (position == 'bottom') result.to_bottom(fix);
            return result
        },
        horizontal(position: string) {
            var sign = position.search('-') < 0 ? '+' : '-';
            var expr = position.split(sign);
            var fix = parseFloat(sign + (expr[1] || '0'))
            position = expr[0]
            if (position == 'start') result.to_start(fix);
            else if (position == 'center') result.to_justify(fix);
            else if (position == 'end') result.to_end(fix)
            return result;
        },
        full_center() {
            return result.to_justify().to_center();
        }

    }
    return result;
}



export function renderize_text(template: string, context: any, fn: (res: string) => void) {
    //	ser realixza un busqueda de todos las expresiones encerradas dobles llaves:  {{expresion}}
    const re = new RegExp('{{.*?}}', 'g')

    var r = template.match(re);

    //	si no se encuentra ningula expresion entonces no hay nada que renderizar
    if (r == null) return false;

    //  contiene todas las funciones de obtencion de datos para este texto
    var get: (() => any)[] = [];

    //  para renderizar el texto
    function render() {
        var i = 0;
        var res = template.replace(re, function (x) {
            return get[i++]()
        })
        fn(res.trim())
    }

    var for_release = [];

    //	realiza un recorrido a traves de cada expresion encontrada
    r.forEach(function (t) {

        //	se obtiene una variable con la expresion sin los corchetes
        var expr = t.replace('{{', '').replace('}}', '');

        //  se procesa la expresion
        var control = get_control<any>(expr, context, render)
        if (control.value instanceof Type.Instance) control = get_control<any>('value', control.value, render)
        for_release.push(control)

        // if (control.value instanceof Type.Instance) control = get_control('value', control.value, render)
        get.push(function () {
            return control.value
        })

    })

    //  inicializa el renderizado
    render()

    return {
        release() {
            for (let control of for_release) control.release()
        }
    }
}

export function create_element(template: string) {
    var dom = document.createElement('template');
    template = template.trim(); // Never return a text node of whitespace as the result
    dom.innerHTML = template;
    return (dom.content.firstElementChild as HTMLElement);
}
export function is_json(str: string) {
    if (!str) return false
    if (/^\s*$/.test(str)) return false;
    str = str.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@');
    str = str.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']');
    str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, '');
    return (/^[\],:{}\s]*$/).test(str);
}

export function is_array(x) {
    return x instanceof Array || x instanceof Matrix
}
export function toProperCase(s: string) {
    return s.toLowerCase().replace(/^(.)|\s(.)/g,
        function ($1) { return $1.toUpperCase(); });
}
export function replace_array(target: any[], replace: any[]) {
    while (target.length) target.shift();
    for (let x of replace) target.push(x);
}
export function iformat(amount, decimals = 2) {
    return nformat(amount, 0)
}
export function nformat(amount, decimals = 2) {
    if (amount == null) return '0'
    if (amount instanceof Type.Instance) amount = amount.value
    var negative = amount < 0 ? '-' : '';

    amount += ''; // por si pasan un numero en vez de un string
    amount = parseFloat(amount.replace(/[^0-9\.]/g, '')); // elimino cualquier cosa que no sea numero o punto


    // si no es un numero o es igual a cero retorno el mismo cero
    if (isNaN(amount) || amount === 0)
        return parseFloat('0').toFixed(decimals);

    // si es mayor o menor que cero retorno el valor formateado como numero
    amount = '' + amount.toFixed(decimals);

    var amount_parts = amount.split('.'),
        regexp = /(\d+)(\d{3})/;

    while (regexp.test(amount_parts[0]))
        amount_parts[0] = amount_parts[0].replace(regexp, '$1' + ',' + '$2');

    return negative + amount_parts.join('.');
}

export function tformat(x) {
    if (x == null) return ''
    if (x instanceof Type.Day) x = x.value
    let r = ''
    if (typeof x == 'string') {
        x = date(x).to_date()
    }
    if (x instanceof Date) {
        var horas = x.getHours()
        var meridiano = 'AM'
        if (horas > 12) {
            horas = horas - 12
            meridiano = 'PM'
        }
        r = dformat(x) + ' ' + horas.toString().padStart(2, '0') + ':' + x.getMinutes().toString().padStart(2, '0') + ' ' + meridiano
    }
    return r;
}


export function dformat(x) {
    if (x == null) return ''
    let r = ''
    if (typeof x == 'string') {
        var a = x.split('-');
        return a[2].substring(0, 2) + '/' + a[1] + '/' + a[0]
    }
    if (x instanceof Date) {
        r = (x.getDate()).toString().padStart(2, '0') + '/' + (x.getMonth() + 1).toString().padStart(2, '0') + '/' + x.getFullYear()
    }
    return r;
}