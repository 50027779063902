import { Data } from "../../../api/data";
import { Obj } from "../../../api/obj";
import session from "../../../api/session";
import { Type } from "../../../api/type";
import { notify } from "../../../components/layout";
import template from './login.form.html'
//  OBJETO 

export default class extends Data {
    static template = template

    user = new Type.String({ required: true, title: 'Usuario' });
    pass = new Type.String({ required: true, title: 'Clave' });
    keep_session = new Type.Boolean()

    fullscreen = true

    init(x: any) {

        return new Promise<void>((ok, fail) => {
            this.on('accept', ok)
            this.on('error', (e) => notify.error(e))
        })
    }

    async accesar() {
        await this.valid();
        try {
            session.keep_session = this.keep_session.value
            await session.login(this.user.value.toLowerCase(), this.pass.value)

            this.trigger('accept')

        } catch (e) {
            this.error(e)
        }
    }

}
