import { form, Form } from '../../../api/form'
import session from '../../../api/session'
import { close_wait, wait } from '../../../components/layout'
import { Zona } from './zona.class'
import template from './zona.edit.html'

export default class extends Zona implements Form {
    static template = template
    title = 'Zonas'
    async init(x) {
        if (x) await this.request(x.id)
        return form.save(this)
    }

}