import { router } from '../../api/router'
import tmerc_search from './tmerc/tmerc.search'
import tmerc_edit from './tmerc/tmerc.edit'
import merc_search from './merc/merc.search'
import merc_edit from './merc/merc.edit'
import precio_search from './precio/precio.search'
import precio_detalle from './precio/precio.detalle'
import precio_add from './precio/precio.add'

router.add([
    { path: '/tmerc/search', source: tmerc_search },
    { path: '/tmerc/edit', source: tmerc_edit },
    { path: '/merc/search', source: merc_search },
    { path: '/merc/edit', source: merc_edit },
    { path: '/precio/search', source: precio_search },
    { path: '/precio/detalle', source: precio_detalle },
    { path: '/precio/add', source: precio_add },
])
