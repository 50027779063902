
//  tipo
export type Type =
  | "string"
  | "number"
  | "boolean"
  | Function
  | Schema
  | Array<Schema | Type>;

export interface rule_args {
  value: any
  context: any
  root: any
  label: string
}
//  regla
export interface Rule {
  (x: rule_args): Promise<any>
}
//  propiedad
export interface Prop {
  type: Type;
  label?: string;
  default?: any;
  required?: boolean;
  rules?: Rule[];
  enum?: any[];
}

//  esquema
export interface Schema {
  [index: string]: Type | Prop;
  rules?: Rule[]
}



export function check(
  data: any,
  schema: Schema | Type | Schema[] | Type[],
  description?: string
): Promise<any> {

  //  ALMACENADO ROOT
  const root = data;

  //  FUNCION INTERNA PARA CONSERVAR A ROOT
  function do_check(data: any,
    schema: Schema | Type | Schema[] | Type[],
    description?: string
  ) {
    return new Promise<void>(async function (ok, fail) {
      //  ******************************************************************
      //  VALIDANDO CONTRA UN ARREGLO
      //  ******************************************************************
      if (schema instanceof Array) {

        //  si data no es un arreglo
        if (data instanceof Array == false) return fail('Debe ser un arreglo')

        //  cambiando variables para facilitar la compilacion por parte de typescript
        const patron: Schema | Type = schema[0];

        //  si se especifico una clase como schema para el arreglo
        if (patron instanceof Function) {
          if (!data.every(x => x instanceof patron)) return fail(`Los elementos del arreglo deben ser instancias de ${patron.name}`)
        }

        //  si se especifico un tipo escalar 
        else if (typeof patron == 'string') {
          if (!data.every(x => typeof x == patron)) return fail(`Los elementos del arreglo deben ser de tipo ${patron}`)
        }

        //  si se especifico un objeto como patron
        else if (patron instanceof Object) {

          try {
            for (let x of data) {
              await do_check(x, patron)

              //  si se especifico una regla para el detalle del arreglo
              if ((patron as Schema).rules) {

                for (var rule of (patron as Schema).rules) await rule({ value: x, context: data, root, label });

              }
            }
          } catch (e) {
            return fail(e)
          }
        }
      }

      //  ******************************************************************
      //  VALIDANDO CONTRA UN OBJETO
      //  ******************************************************************
      else if (schema instanceof Object) {
        for (let n of Object.keys(schema).filter(x => x != 'rules')) {
          //  obtiene el tipo
          var type = schema[n].type || schema[n]
          var label = schema[n].label || n

          // valores por defecto  
          if (typeof data[n] == 'undefined' && schema[n].default != undefined) data[n] = schema[n].default


          if (!data[n] && (schema[n].required /*|| type != 'object'*/)) {
            return fail(label + ' es requerido ')
          }

          //  si es tipo escalar
          else if (data[n] && typeof type == 'string') {

            if (typeof data[n] != type) return fail(label + ' debe ser de tipo ' + type)
          }

          //  si es tipo funcion
          else if (typeof type == 'function') {
            if (data[n] instanceof type == false) fail(label + ' deb ser una instancia de ' + type.name)
          }

          //  si es un arreglo u objeto               
          else if (type instanceof Object) {
            try {
              await do_check(data[n], type, n)
            } catch (e) {
              return fail(e)
            }
          }

          //  validando las reglas
          if (schema[n].rules) {
            var r: true | string;
            for (var rule of schema[n].rules) {
              try {
                await rule({ value: data[n], context: data, root, label });
              }
              catch (e) {
                fail(e)
              }
            }
          }
          //  validando las enumeradores
          if (typeof data[n] != 'undefined' && schema[n].enum && !schema[n].enum.some(x => x == data[n])) {
            return fail(label + ' debe ser uno de estos valores "' + schema[n].enum.join(',') + '"')
          }

        }
      }

      //  ******************************************************************
      // VALIDANDO CONTRA UN ESCALAR
      //  ******************************************************************
      else if (typeof schema == 'string') {
        if (typeof data != schema) return fail('Debe ser de tipo de ' + schema)
      }

      return ok()
    })
      .catch(function (e) {
        if (description) e = description + ': ' + e
        return Promise.reject(e)
      })
  }

  return do_check(data, schema, description)
}

