import { form } from '../../../api/form'
import { Obj } from '../../../api/obj'
import { source } from '../../../api/source'
import { replace_array } from '../../../api/util'
import template from './wms.carga.ordenes.html'


export default class extends Obj {
    static template = template
    chofer: string = ''
    valija: string = ''
    confirmar: boolean = false
    valija_confirmar: string = ''
    cargado: boolean = false
    detalle: [] = []
    pendiente: string = ''

    init() {
        this.get_pendiente()

        return form.done(this)
    }

    async get_pendiente() {
        let r = await source().request('/wms.carga/valija-pendiente')


        this.pendiente = r[0].ordenes


    }

    async valid() {
        try {
            this.notify('')
            this.valija_confirmar = this.valija

            if (this.confirmar && (this.valija == this.valija_confirmar)) {
                this.confirmar = false
                let r = await source().request('/wms.carga/descarga-valija', { valija: this.valija })
                this.notify(r)

            } else {
                let r = await source().request('/wms.carga/get-valija', { valija: this.valija, chofer: this.chofer })

                if (r.detalle) {
                    replace_array(this.detalle, r.detalle)

                    this.notify(r.notify)

                }



            }


        } catch (e) {

            if (e.cargado) {
                this.confirmar = true
                this.error('Escanee de nuevo para descargar esta valija')

            } else {
                this.error(e.error || e)
                if (!!e.error) {
                    this.chofer = ''

                }


            }

        }
        this.valija = ''
        this.get_pendiente()



    }


}

