import { dynamic_scroll } from "../components/dynamic-scroll"
import { close_wait, layout, notify, wait } from "../components/layout"
import { Modal } from "../components/modal"
import { component, render_component } from "./component"
import { event_handler } from "./event"
import { router } from "./router"
import { Search } from "./search"
import { Table } from "./table"
import { create_element } from "./util"

export interface Form {
    title?: string;
    fullscreen?: boolean;
    active_search?: boolean;
    active_setting?: boolean;
    active_download?: boolean;
    active_upload?: boolean;
    active_camera?: boolean
    active_note?: boolean
    active_add?: boolean
    active_filter?: boolean
    active_warning?: boolean
    active_print?: boolean
    dom?: HTMLElement
    init?(arg?: any): Promise<any>
}
export interface ModalForm {
    init(x: any): Promise<any>
    accept?(x?: any)
    cancel?()
}
export interface ModalFormConstructor {
    new(): ModalForm
    template: string
}



export namespace form {
    export async function modal(modal_form: ModalFormConstructor, arg: any = {}): Promise<any> {
        var form = new modal_form();
        if (!form.accept) form.accept = () => { event_handler(form).trigger('accept') }
        if (!form.cancel) form.cancel = () => { event_handler(form).trigger('cancel') }

        var dom = create_element(modal_form.template);
        dom = render_component(dom, form)
        var modal = <Modal>component(dom)
        modal.open = true

        function close() {
            modal.open = false
            dom.remove()
        }

        return new Promise((ok, fail) => {
            dom.addEventListener('hide.bs.modal', () => {
                fail()
                close()
            })

            form.init(arg)
                .then(ok)
                .catch(fail)
                .finally(close)
        })

    }
    export function save(table: Table, x?: any) {
        if (x && x.id) table.request(x.id)
        return new Promise<void>((ok, fail) => {
            table.on('accept', async () => {
                try {
                    await table_update(table)
                    ok()
                } catch (e) {
                    console.error(e)
                }
                close_wait()
            })
            table.on('cancel', fail)
        })
    }

    export async function table_update(table: Table) {
        var error
        wait('Guardando cambios...')
        try {
            await table.update()
            notify.success('Operacion completada!!')
        } catch (e) {
            error = e
            notify.error(e)
        }
        close_wait()
        if (error) return Promise.reject(error)
    }

    export function done(form: any, fn?: (x?: any) => Promise<any>) {

        return new Promise<void>((ok, fail) => {
            event_handler(form).on('accept', async (r) => {

                if (!fn) return ok(r)
                try {
                    var result = await fn(r)
                    ok(result)
                } catch (e) {
                    if (e) form.error(e);

                }

            })
            event_handler(form).on('cancel', fail)
        })
    }

    export function search(template, url: string
        , opt: { edit?: string, detail?: string, add?: string, no_request?: boolean, limit?: number } = {}) {

        return class extends Search implements Form {
            static template = template
            title = "Buscar"
            dom: HTMLElement
            layout = layout
            active_add = !!opt.add

            constructor() {
                super(url, opt)
            }

            setup() {
                this.title = this.dom.getAttribute('title') || 'Buscar'
                this.scroll = dynamic_scroll(this.dom)
                if (!opt.no_request) this.start()
                this.on('error', notify.error)
                this.on('notify', notify.success)
            }
            init(x?: any) {
                return form.done(this)
            }
        }

    }
}

