import { form } from '../../../api/form'
import session from '../../../api/session'
import { dia_1ro } from '../../../api/util'
import { date } from '../../sistema/date/date.class'
import { filter } from '../../sistema/filtro/filtro'
import template from './factura.search.html'


export default class extends form.search(template, '/wms.facturas/list-facturas', { detail: '/factura/detalle' }) {
    params = {
        search: '',
        ft_doc: '',
        fecdes: dia_1ro(),
        fechas: new Date()
    }
    active_filter = true
    cliente_logueado = !!session.user.cliente
    constructor() {
        super()

        this.on('filter', () => filter(this.params))
    }
}
