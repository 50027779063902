
//  inteface del parametro que recibe el constructor de la promesa
interface Action<T> {
    (
        resolve: (result?: T) => void,
        reject: (reason?: any) => void,
        cancel?: (fn: () => void) => void,
    ): void
}

//  devuelve una promesa cancelable
export function apromise<T>(action: Action<T>) {
    //  indicador de cancelado
    var cancelado = false
    var on_cancel;

    //  preparando promesa
    var promise = new Promise<T>(function (ok: any, fail: any) {
        Promise.resolve().then(function () {
            if (cancelado) return
            action(ok, fail, function (fn) {
                on_cancel = fn;
            });
        })
    });

    (promise as any).cancel = function () {
        if (cancelado) return
        cancelado = true
        if (on_cancel) on_cancel();
        (promise as any).canceled = true
    }
    return promise
}

//  cancela una promesa cancelable
export function abort_promise(x: Promise<any>) {
    if (x) {
        let cancel = (x as any).cancel
        if (cancel) {
            cancel();
        }
        (x as any).canceled = true
    }
}

//  devuelve si una promesa ya ha sido cancelada
export function is_aborted(x: Promise<any>) {
    return x && !!(x as any).canceled
}

