import { Type } from "../../../api/type"
// import { perfil_list } from "../perfil/perfil.list"
import { Detail, Table } from "../../../api/table"
import { tmerc_list } from "../tmerc/tmerc.list"

export class merc extends Table {

    tipo = new Type.Link({ required: true, title: 'Tipo', list: tmerc_list })
    nombre = new Type.String({ required: true, title: 'Nombre' })
    referencia = new Type.String({ required: true, title: 'Referencia' })
    inactivo = new Type.Boolean({ title: 'Inactivo' })
    maneja_vence = new Type.Boolean({ title: 'Maneja Vencimiento' })
    maneja_lote = new Type.Boolean({ title: 'Maneja lote' })
    vida_util = new Type.Number({ title: 'Maneja lote' })
    itasa = new Type.Number({ title: 'Itasa' })
    unidad = new Detail(Unidad)
    constructor() {
        super('merc')
    }
}


class Unidad extends Table {
    merc = new Type.Link({ required: true, title: 'Nombre' })
    unidad = new Type.String({ required: true, title: 'Unidad' })
    factor = new Type.Number({ required: true, title: 'Factor' })
    barra = new Type.String({ required: true, title: 'Barra' })
    compra = new Type.String({ required: true, title: 'Compra' })
    venta = new Type.String({ required: true, title: 'Venta' })
    precio = new Type.Number({ required: true, title: 'Precio' })
    constructor() {
        super('unidad')
    }
}