import { form, Form } from '../../../api/form'
import session from '../../../api/session'
import { close_wait, wait } from '../../../components/layout'
import { tmerc } from './tmerc.class'
import template from './tmerc.edit.html'

export default class extends tmerc implements Form {
    static template = template
    title = 'Tipo de Mercancia'
    init(x) {
        if (x) this.request(x.id)
        return form.save(this)
    }

}