import { form } from '../../../api/form'
import { Obj } from '../../../api/obj'
import { router } from '../../../api/router'
import session from '../../../api/session'
import { source } from '../../../api/source'
import { replace_array } from '../../../api/util'
import { layout } from '../../../components/layout'
import template from './wms.imprimir.html'


export default class extends Obj {
    static template = template
    layout = layout
    serial: string = ''
    init() {

    }


    async valid() {

    }


}

