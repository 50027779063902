import { Obj } from './obj'

export class Matrix<T = any> extends Obj implements Array<T> {
    constructor() {
        super()
    }
    [n: number]: T;
    length: number;
    pop(): T {
        return Array.prototype.pop.apply(this, arguments);
    }
    push(...items: T[]): number {
        return Array.prototype.push.apply(this, arguments);
    }
    concat(...items: ConcatArray<T>[]): T[];
    concat(...items: (T | ConcatArray<T>)[]): T[];
    concat(...items: any[]) {
        return Array.prototype.concat.apply(this, arguments);
    }
    join(separator?: string): string {
        return Array.prototype.join.apply(this, arguments);
    }
    reverse(): T[] {
        return Array.prototype.reverse.apply(this, arguments);
    }
    shift(): T {
        return Array.prototype.shift.apply(this, arguments);
    }
    slice(start?: number, end?: number): T[] {
        return Array.prototype.slice.apply(this, arguments);
    }
    sort(compareFn?: (a: T, b: T) => number): this {
        Array.prototype.sort.apply(this, arguments);
        return this;
    }
    splice(start: number, deleteCount?: number): T[];
    splice(start: number, deleteCount: number, ...items: T[]): T[];
    splice(start: any, deleteCount?: any, ...rest: any[]) {
        return Array.prototype.splice.apply(this, arguments)
    }
    unshift(...items: T[]): number {
        return Array.prototype.unshift.apply(this, arguments)
    }
    indexOf(searchElement: T, fromIndex?: number): number {
        return Array.prototype.indexOf.apply(this, arguments)
    }
    lastIndexOf(searchElement: T, fromIndex?: number): number {
        return Array.prototype.lastIndexOf.apply(this, arguments)
    }
    every<S extends T>(predicate: (value: T, index: number, array: T[]) => value is S, thisArg?: any): this is S[];
    every(predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: any): boolean;
    every(predicate: any, thisArg?: any) {
        return Array.prototype.every.apply(this, arguments)
    }
    some(predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: any): boolean {
        return Array.prototype.some.apply(this, arguments)
    }
    forEach(callbackfn: (value: T, index: number, array: T[]) => void, thisArg?: any): void {
        return Array.prototype.forEach.apply(this, arguments)
    }
    map<U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: any): U[] {
        return Array.prototype.map.apply(this, arguments)
    }
    filter<S extends T>(predicate: (value: T, index: number, array: T[]) => value is S, thisArg?: any): S[];
    filter(predicate: (value: T, index: number, array: T[]) => unknown, thisArg?: any): T[];
    filter(predicate: any, thisArg?: any) {
        return Array.prototype.filter.apply(this, arguments)

    }
    reduce(callbackfn: (previousValue: T, currentValue: T, currentIndex: number, array: T[]) => T): T;
    reduce(callbackfn: (previousValue: T, currentValue: T, currentIndex: number, array: T[]) => T, initialValue: T): T;
    reduce<U>(callbackfn: (previousValue: U, currentValue: T, currentIndex: number, array: T[]) => U, initialValue: U): U;
    reduce(callbackfn: any, initialValue?: any) {
        return Array.prototype.reduce.apply(this, arguments)

    }
    reduceRight(callbackfn: (previousValue: T, currentValue: T, currentIndex: number, array: T[]) => T): T;
    reduceRight(callbackfn: (previousValue: T, currentValue: T, currentIndex: number, array: T[]) => T, initialValue: T): T;
    reduceRight<U>(callbackfn: (previousValue: U, currentValue: T, currentIndex: number, array: T[]) => U, initialValue: U): U;
    reduceRight(callbackfn: any, initialValue?: any) {
        return Array.prototype.reduceRight.apply(this, arguments)

    }
    find<S extends T>(predicate: (this: void, value: T, index: number, obj: T[]) => value is S, thisArg?: any): S;
    find(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): T;
    find(predicate: any, thisArg?: any) {
        return Array.prototype.find.apply(this, arguments)

    }
    findIndex(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): number {
        return Array.prototype.findIndex.apply(this, arguments)

    }
    fill(value: T, start?: number, end?: number): this {
        return Array.prototype.fill.apply(this, arguments)

    }
    copyWithin(target: number, start: number, end?: number): this {
        return Array.prototype.copyWithin.apply(this, arguments)

    }
    [Symbol.iterator](): IterableIterator<T> {
        return Array.prototype[Symbol.iterator].apply(this, arguments)
    }
    entries(): IterableIterator<[number, T]> {
        return Array.prototype.entries.apply(this, arguments)

    }
    keys(): IterableIterator<number> {
        return Array.prototype.keys.apply(this, arguments)
    }
    values(): IterableIterator<T> {
        return Array.prototype.values.apply(this, arguments)
    }
    [Symbol.unscopables](): {
        copyWithin: boolean; entries: boolean; fill: boolean; find: boolean; findIndex: boolean;
        //  esto debido a que el api rest puede responder otros clientes que no sean chalona
        keys: boolean; values: boolean;
    } {
        console.error('funcion array no implementada')
        throw new Error('Method not implemented.');
    }
    includes(searchElement: T, fromIndex?: number): boolean {
        return Array.prototype.includes.apply(this, arguments)

    }
}
