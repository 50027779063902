import { array_watch, watch } from './watch'
import { event_handler } from './event'

var equal = require('deep-equal');
/**
 * Representa el objeto principal de chalona* 
 * @class
 * @classdesc Todos los objetos de chalona dependen directamente de este objeto*
 * 
 */

export class Obj {
    private events: any = {};

    error_msg: string = '';
    notify_msg: string = '';

    private event_handler = event_handler({})


    on(name: string, fn: Function): this {
        this.event_handler.on(name, fn)
        return this;
    };
    trigger(name: string, args?: any): any {
        return this.event_handler.trigger(name, args)
    }

    watch(prop: string, fn: (x: any) => any) {
        if (this[prop] instanceof Array) array_watch(this[prop], fn.bind(this))
        else watch(this, prop, fn)
        return this;
    }

    members<T extends Obj = Obj>(fn: Function = Obj): T[] {
        var result: any[] = [];
        this.forMember(fn, function (x: any) {
            result.push(x)
        })
        return result
    }

    forMember(as: Function, fn: Function): void;
    forMember(fn: Function): void;

    /**
     * Permite iterar entre los objetos contenidos, la iteracion permite el uso de promesas
     * @param {function}	as - (opcional) permite filtrar el recorrido por los objetos que dependan de una clase especifica 
     * @param {function}	fn - se ejecuta cuando un objeto es encontrado por el iterador 
     */
    forMember(as: Function, fn?: any): void {
        //	si fn no fue especificado se asume que los objetos requeridos dependen de Obj
        if (!fn) {
            fn = as;
            as = Obj;
        }

        //	variables
        var ls: Array<string> = Object.keys(this); //	contiene las propiedades de objeto

        //	creando lista de objetos a iterar
        for (var i of ls) {
            if ((this as any)[i] instanceof as) {
                var r = fn((this as any)[i], i);
                if (r === false) return;
            }
        }
        return;
    }

    /**
     * Notifica de un error
     * @param {string}	error - recibe el mensaje de error
     */
    error(error: string) {
        this.error_msg = error || '';
        this.notify_msg = ''
        this.trigger("error", error);
    }

    /**
     * Realiza una notificacion
     * @param {string}	msg- recibe el mensaje que se quiere notificar
     */
    notify(msg: string): this {
        this.notify_msg = msg;
        this.error_msg = ''
        this.trigger("notify", msg);
        return this;
    }

    /**
     * Quita el mensaje de error del objeto y el estado del error
     */
    reset_state() {
        this.notify_msg = '';
        this.error_msg = '';
        return this;
    }

    /** 
     * Determina si el objeto contiene una propiedad 
     * @param {string}      name  el nombre del objeto
     * @param {string}		as  (opcional) determina si la propiedad deriva de una clase especifica
     * @returns {any}      	retorna la propiedad en cuestion
    */
    has(name: string, as?: Function): any {
        var r: any = (this as any)[name];
        if (r && as && !(r instanceof as)) r = undefined;
        return r;
    }
    has_event(x: string): boolean {
        return (this.events[x] && this.events[x].length) ? true : false;
    }
}
