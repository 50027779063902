import { tokenize } from 'esprima'
import { fix } from 'mssql'
import { is_array } from './util'
import { array_watch, watch } from './watch'

export interface ControlSource<T = any> {
    context: any,
    source: string,
    value: T,
    fn: (x?: any) => any
    watch: (fn: (v: any) => any) => any
}

/**
 * @sumary
 * 1- Evalua una expresion con relacion a un contexto
 * 2- Permite observar las propiedades en la expresion
 * 3- Permite modificar la propiedad en el contexto si la expresion tiene una sola propiedad
 * @param text recibe el string de la expresion
 * @param context recibe el contexto en el que se quiere evaluar la expresion
 * @param fn recibe la funcion que se usara para observar
 * @returns un objeto {value:any}
 * 
 */
export function get_control<T = any>(text: string, context?: any, fn?: (x?: any) => any): ControlSource<T> {
    if (text === null) throw 'esta pasando en text null a get_control'

    context = context || {}
    if (context.document) throw 'esta pasanda window a get_control'
    var test = (get_control as any).test

    if (test) console.info('text', text)
    if (test) console.info('context', context)

    //  resultado por default
    var result: any = {
        value: text,
        context: context,
        source: 'deconocido',
        fn() { return text },
        release() { },
        watch(fn) {
            Object.values(observable).forEach(function (x: { context: any, text: string }) {
                function wrap() {
                    fn(result.value)
                }
                if (is_array(x.context[x.text])) {
                    array_watch(x.context[x.text], wrap)
                }
                else {
                    watch(x.context, x.text, wrap)
                }
            })
            return result;
        }
    }

    //  obteniendo un arreglo con los componentes de la expresion
    var token = tokenize(text.replace('#', ''))

    //  para contener la expresion corregida
    var fixed: string[] = [];

    //  para contener proiedades observables
    var observable: any = {}

    //  para indicar cuando inicia una propiedad
    var start = 0;

    //  para indicar el contexto durante el recorrido
    var parent = context;

    //  ultimo elemento puesto en fixed
    var last: string
    /**
     * COMIENZA EL RECORRIDO A TRAVES DE LOS ELEMENTOS DE LA EXPRESION
     * la idea es conseguir una expresion corregida que haga referencia a this
     * en todas las propiedades a la que la expresion hace referencia
     * el proceso tambien puede buscar elementos que esten previos al contexto
     * especificado
     */
    var x = token.shift();
    while (x) {
        if (x.value == '$event') x.type = 'String'
        //  si no es un identificador
        if (

            //  si no es un identificador
            x.type != 'Identifier'

            //  si elemento anterior en la expresion es un {
            //  se trata de un objeto descrito que necesita renderizarse
            || last == '{'

        ) {
            fixed.push(last = x.value)

            x = token.shift();
            continue;
        }

        //  1- INICIO DE LECTURA DE UNA PROPIEDAD

        //  marca el punto de inicio de la propiedad
        start = fixed.length

        // comienza la lectura de una propiedad anteponiendo this
        fixed.push(last = 'this.')

        //  2- DETERMINANDO EL CONTEXTO
        //  si la propiedad se encuentra en el contexto
        if (parent[x.value] !== undefined) {
            result.context = parent;


            fixed.push(last = x.value)

            //  verificando si la propiedad es observable
            catch_observable(parent, x.value, fixed, start, observable)
        }

        //  si la propiedad no se encuentra en el contexto 
        //  encontrarla en un contexto previo
        else {

            parent = parent.parent

            while (parent) {
                fixed.push(last = 'parent.')

                if (parent[x.value] !== undefined) {
                    result.context = parent;
                    fixed.push(last = x.value)

                    //  verificando si la propiedad es observable
                    catch_observable(parent, x.value, fixed, start, observable)

                    break
                }
                parent = parent.parent
            }

            if (!parent) return result
        }

        //  2- ANALIZANDO SUB PROPIEDADES
        //  completa la propiedad
        parent = parent[x.value]
        x = token.shift();

        while (x) {
            fixed.push(last = x.value)

            //  trantando propiedades envueltas en corchetes
            if (x.value[0] == "'") {
                x.value = x.value.split("'")[1];
                x.type = 'Identifier'
                if (token[0] && token[0].value == ']') fixed.push(token.shift().value)
            }

            if (x.type != 'Identifier') {

                //  si es un punto o un corchete, continua
                if (x.value == '.' || x.value == '[') {
                    x = token.shift();
                    continue
                }
                //  de lo contrario es una nueva propiedad y concluye la busqueda de subpropiedade
                break
            }

            //  si falla 

            if (parent[x.value] === undefined && token[0] && token[0].value != '||') return result;
            result.context = parent;

            //  verificando si la propiedad es observable
            catch_observable(parent, x.value, fixed, start, observable)

            parent = parent[x.value]
            x = token.shift();
        }


        //  3- REINICIA PARA SEGUIR BUSCANDO PROPIEDADES EN LA EXPRESION
        parent = context
        x = token.shift();
    }

    //  PREPARANDO LOS RESULTADOS
    var descriptor;
    try {
        if (test) console.info('Fixed:', fixed.join(''))
        descriptor = {
            get: result.fn = new Function('$event', `return ${fixed.join('')}`).bind(context),
            set(v) { }  //  esto hace que la propiedad no se pueda escribir 
        }
        result.source = text.split('.').pop()

    }
    catch (e) {
        //  no es una expresion valida
        return result
    }

    /**
     * OBSERVANDO PROPIEDADES
     * esta funcion ademas de evaluar una expresion permite observar las
     * propiedades involucradas en la misma
     */
    if (test) console.info(observable)

    if (fn) result.watch(fn)


    /**
     * PERMITIENDO LA MODIFICACION DE LA PROPIEDAD EN EL CONTEXTO
     * si la expresion consta de una sola propiedad se brinda la posibilidad
     * de modificar esa propiedad desde afuera
     */
    if (Object.keys(observable).length == 1) {
        var edit: any = Object.values(observable).shift();
        descriptor.set = function (v) {
            edit.context[edit.text] = v
        }
    }

    Object.defineProperty(result, 'value', descriptor)


    return result
}

function catch_observable(context, text, fixed, start, observable) {
    var test = (get_control as any).test

    if (['string', 'number', 'boolean'].some(x => x == typeof context[text])
        || context[text] === null
        || context[text] instanceof Array
        || context[text] instanceof Date
    ) {
        var prop = fixed.slice(start).join('')
        if (test) console.info(prop)
        observable[prop] = observable[prop] || { context, text }
    }
}

