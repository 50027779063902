import { Form, form } from '../../../api/form'
import { Obj } from '../../../api/obj'
import { source } from '../../../api/source'
import { clone, dformat, dia_1ro, download, nformat, ocopy, replace_array } from '../../../api/util'
import template from './ordenv.consulta-cliente.html'
import { dialog } from '../../../api/dialog'
import Excel from 'exceljs'
import { filter } from '../../sistema/filtro/filtro'
import { close_wait, wait } from '../../../components/layout'
import { ENGINE_METHOD_CIPHERS } from 'constants'
import { router } from '../../../api/router'


export default class extends form.search(template, '/ordenv-consulta/consulta-cliente', { no_request: true }) implements Form {
    params = { fecdes: dia_1ro(), fechas: new Date(), tcliente: '', cliente: '', status_orden_venta: '' }
    info_modal = false
    active_filter = true
    active_download = true

    dformat = dformat
    async init(x) {

        this.on('filter', async () => {
            await filter(this.params)
            wait()
            await this.request()
        })
        this.on('warning', () => {
            this.info_modal = true
        })
        this.on('download', () => {
            this.download()
        })

        this.on('repeat', () => {
            close_wait()
        })
        wait()
        await this.start()
        close_wait()



        return form.done(this)
    }
    detalle(cliente) {
        var params = ocopy(this.params, {})
        params.cliente = cliente.cliente
        router.modal('/wms/consulta-orden', params)
    }
    async download() {
        var wb = new Excel.Workbook();

        // PRODUCTOS
        var productos = wb.addWorksheet('Productos')

        // columnas
        productos.columns = [
            { header: 'Cliente', key: 'cliente', width: 15 },
            { header: 'Nombre', key: 'nombre', width: 40 },
            { header: 'Tipo', key: 'tmerc', width: 40 },
            { header: 'Codigo', key: 'merc', width: 15 },
            { header: 'Descripcion', key: 'descrip', width: 15 },
            { header: 'Ordenado Cant.', key: 'ordenado', width: 15 },
            { header: 'Ordenado Total', key: 'ordenado_valor', width: 15 },
            { header: 'Despachado Cant', key: 'despachado', width: 15 },
            { header: 'Despachado Total', key: 'despachado_valor', width: 15 },
            { header: 'Entregado Cant', key: 'entregado', width: 15 },
            { header: 'Entregado Total', key: 'entregado_valor', width: 15 }
        ];

        // formato a columnas
        productos.getRow(1).font = { bold: true }
        productos.getCell('D1').alignment = { horizontal: 'right' };
        productos.getCell('C1').alignment = { horizontal: 'right' };

        // agregando contenido
        this.data.forEach((cliente) => {
            cliente.detalle.forEach((tmerc) => {
                tmerc.detalle.forEach((merc) => {
                    merc.cliente = cliente.cliente
                    merc.nombre = cliente.nombre
                    merc.tmerc = tmerc.tmerc
                    productos.addRow(merc)
                })
            })
        })

        // titulo
        var title = productos.insertRow(1, ['Relacion de Entrega de Producto x Tipo de Producto'])
        title.font = { bold: true, size: 16 }

        //  parametros
        productos.insertRow(2, ['Desde: ' + dformat(this.params.fecdes) + ' hasta: ' + dformat(this.params.fechas)])


        //  descargar
        var r = await wb.xlsx.writeBuffer()
        download(new File([r], 'consulta-orden-cliente.xlsx'));

    }
}