import { Obj } from "./obj";
import { abort_promise } from "./apromise";
import { replace_array } from "./util";
import { source } from "./source";
import session from "./session";

export const lists: { [index: string]: List } = {}
export function add_list(x: { [index: string]: List }) {
    for (let name in x) {
        if (lists[name]) throw `add_list: la lista ${name} ha sido definida previamente`
        lists[name] = x[name]
    }
}


export class List extends Obj {
    data: any[] = [];
    protected off_request = false;
    args: any = {};

    get(id: string) {
        return this.data.find(x => x.id == id)
    }

    request(x: any = {}) {

    }


    load(list: any[], refered?: boolean) {
        this.reset()
        list.forEach((x, i) => {
            if (typeof x == 'object') this.data.push(x);
            else this.data.push({ id: refered ? x : i, display: x })
        })
    }
    reset() {
        while (this.data.length) this.data.shift()
    }
}


export class RequestList extends List {
    data: any[] = [];
    private promise: Promise<any> | undefined;
    protected off_request = false;

    constructor(private url: string, args: any = {}) {
        super()
        this.args = args
    }

    get(id: string) {
        return this.data.find(x => x.id == id)
    }

    request(args: any = {}) {
        const parent = this

        //  guardando los parametros recibidos
        this.args = $.extend(this.args, args)

        //  cancelo la promesa anterior 
        if (this.promise) {
            abort_promise(this.promise);
            this.promise = undefined
        }

        //  realizo la consulta

        this.promise = source().request(this.url, this.args)
            .then((r: any[]) => {
                //  se agregan los resultados a data
                replace_array(this.data, r)

                //  se dispara el trigger request
                this.trigger('request', r)
                return r;
            })

        //  retorno los resultados
        return this.promise;
    }
}

export class SubList extends List {
    constructor(private list: List, private filter: (x: any, args: any) => boolean) {
        super()

        list.on('request', () => this.request())
    }
    request(x: any = {}) {

        this.args = $.extend(this.args, x)
        var r = this.list.data.filter((x) => this.filter(x, this.args));
        replace_array(this.data, r)
        this.trigger('request')
    }
}

export class StaticList extends List {
    constructor(list: any[]) {
        super()
        this.load(list)
    }
}

var dia_mes = [];
for (let x = 1; x < 32; x++)dia_mes.push({ id: x, display: x });
export const mes_list = new StaticList(['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'])
export const dia_mes_list = new StaticList(dia_mes)

export function create_list(url: string, args: any = {}) {

    const list = new RequestList(url, args)
    if (args.public) {
        setTimeout(() => {
            list.request({ empresa: 'jf' })
                .then(r => r)
        }, 200);
    } else {
        session.on_logged(function () {
            list.request()
        })
    }

    return list
}
