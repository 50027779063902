import jwt_decode from "jwt-decode";
import { Schema } from "./check";
import { Empresa, Session, User } from "./faces";
import { Obj } from "./obj";
import { source } from "./source";
import { UrlParser } from 'url-params-parser'
import { on_ready } from "./event";
import { ocopy } from "./util";




class SessionObj extends Obj implements Session {

    app = ''
    token: string
    user: User = {
        id: '',
        nombre: '',
        email: '',
        cliente: null,
        empleado: '',
        funciones: [],
        datos: {}
    }
    empresa: Empresa = {
        id: '',
        nombre: '',
        direccion: '',
        telefono: '',
        rnc: '',
        email: '',
        entidad_fns: [],
        perfil_fns: [],
        datos: {},
        sucursal: '',
        logo: ''
    }
    keep_session = false
    logged() {
        return !!this.user.id
    }

    load(token: string) {
        if (!token) return
        this.token = token
        var string: string = this.token;
        var session: any;
        try {
            if (string) session = jwt_decode(string)
            ocopy(session, this)
            this.trigger('logged')

        } catch (e) {
            console.error('get_session: error recuperando la session', e)
        }

    }
    async login(user: string, pass: string, empresa?: string) {

        var r: { token: string } = await source().request('/login/access', { user, pass, empresa, app: this.app })
        this.load(r.token)
    }

    logoff() {
        this.token = undefined
        this.trigger('logoff')
    }

    on_logged(on: () => void) {
        return this.on('logged', on)
    }

}

export default new SessionObj()
