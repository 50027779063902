import { dialog } from '../../../api/dialog';
import session from '../../../api/session';
import { ocopy } from '../../../api/util';
import { tcliente_list } from '../../cliente/tcliente/tcliente.list';
import { ft_doc_list } from '../../erp/doc/doc.list';
import { tmerc_list } from '../../merc/tmerc/tmerc.list'
import { empleado_list } from '../../rh/empleado/empleado.list'
import template from './filtro.html'

var dom: HTMLElement
export async function filter(data: any = {}) {
    var filtro = {
        params: {
            tmerc: '',
            vence_status: '',
            empleado: '',
            ft_doc: '',
            status_orden_venta: '',
            promedio_entrega_status: '',
            presentacion_ordenv: '',
            documento_ordenv: '',
            status_tarea: '',
            tipo_tarea: '',
            status_tarea_orden: ''
        },
        active: {
            fecdes: false,
            fechas: false,
            vence_status: false,
            tcliente: false,
            tmerc: false,
            empleado: false,
            status_orden_venta: false,
            ft_doc: false,
            promedio_entrega_status: false,
            presentacion_ordenv: false,
            documento_ordenv: false,
            status_tarea: false,
            tipo_tarea: false,
            status_tarea_orden: false
        },
        tcliente_list,
        tmerc_list,
        empleado_list,
        ft_doc_list
    }

    ocopy(data, filtro.params)
    for (let i in data) filtro.active[i] = data[i] !== undefined
    if (session.user.cliente) filtro.active.tcliente = false
    await dialog.modal(template, filtro)
    for (let i in data) data[i] = filtro.params[i]

    return data

}