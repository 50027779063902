import isMobile from "../node_modules/is-mobile/index";
import { is_component, render_component, start, start_component } from "./component";
import { event_handler } from "./event";
import { get_control } from "./get_control";
import { Type } from "./type";
import { create_element, dformat, iformat, is_array, is_json, nformat, tformat } from "./util";

export function repeat(target: HTMLElement, definition: string, context: any) {



    //  si no tiene retorna falso
    if (!definition) return false;
    target.removeAttribute('c-for')

    var include: any = target.getAttribute('include')
    target.removeAttribute('include')


    if (is_json(include)) include = JSON.parse(include)


    //  obtiene las partes de la definicion
    var part = definition.split(' in ');
    if (part.length < 2) throw 'Especificacion incorrecta en c-for, Ej. c-for="item in iterado"'


    //  obtiene el obeto sobre el cual se va a iterar
    var control = get_control<any[]>(part[1], context)

    // if (control.value instanceof Type.Array) control = get_control('value', control.value)

    // if (control.value instanceof Type.Array) control = get_control<any[]>(part[1] + '.value', context)
    var iterador: any[] = control.value
    if (iterador instanceof Type.Array) iterador = iterador.value

    if (!is_array(iterador)) return console.error(`cfor: la propiedad especificada con los datos no es un arreglo en "${definition}"`)

    //	obtiene los identificadores de las variables item e index que se van a renderizar
    var identificador = part[0].trim().replace('(', '').replace(')', '').split(',')
    var item = identificador[0].trim()
    var index = (identificador[1] || '$index').trim();

    //  obtiene el template
    var template = target.outerHTML

    //  si el template es un componente se provee de una envoltura
    //  verifica si un componente
    var template_is_component = is_component(target);
    if (template_is_component) template = '<span>' + target.outerHTML + '</span>'

    //  obtiene el parent 
    var contenedor = target.parentElement
    var reference = target.previousElementSibling

    //  elimina el elemento que sirvio para el template
    target.remove()

    //  para almacenar los elementos para poder removerlos
    var elements: HTMLElement[] = [];

    //	define la funcion que se va a disparar con cada cambio que se hace al arreglo
    function fn() {

        event_handler(context).trigger('pre-repeat', iterador)

        //	limpia el contenedor
        for (let e of elements) e.remove()

        var parent = reference;

        function add(x: any, i: number) {
            // if (x instanceof Table && x.deleted()) return

            var node: any = {
                dformat,
                nformat,
                iformat,
                tformat,
                [item]: x,
                [index]: i,
                $item: x,
                parent: context,

            }

            var dom = create_element(template)
            node.dom = dom
            var n = render_component(dom, node)



            //  si el template es un componente ya se la ha provisto una envoltura temporal
            //  y componente ya instanciado es sacado de su envoltura
            if (template_is_component) {
                n = (n.firstChild as HTMLElement)
                n.remove()
            }


            //  se agrega el elemento al arreglo de elementos
            elements.push(n);

            //	inserta el elemento en el contenedor
            if (parent) {
                parent.after(n)
            }
            else {
                contenedor.append(n)
            }

            parent = n;
            var event = isMobile() ? 'click' : 'dblclick'
            n.addEventListener(event, function () {
                context.trigger('detail', x)
                return false
            })

        }

        //  para incluir algo adicional
        if (include) add(include, -1);

        //  carga la lista
        iterador.forEach((x, i) => {
            add(x, i)
        })

        if (context.control instanceof Type.Link) {
            var select = context.dom.getElementsByTagName('select')[0]
            if (select) {
                select.value = context.control.value
            }
        }

        event_handler(context).trigger('repeat', iterador)
    }

    //	controlador asincrono, para que los cambios se apliquen 
    //	cuando finalmente se terminen los cambios realizados al arreglo
    var async;
    //	coloca el trigger a la modificacion del arreglo
    control.watch(function () {
        //	si previamente alaceno e timeout se cancela
        if (async) {
            clearTimeout(async)
            async = undefined
        }
        //	carga el timeout con la funcion que carga los elementos en el dom
        async = setTimeout(function () {
            fn()
            async = undefined
        }, 100)
    })


    // event_handler(iterador).on('refresh', fn)

    //	inicializa la directiva
    fn()
    return true;
}

