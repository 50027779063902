var current_toast: Toast;

export class Toast {
  static template = /*html*/`
    <div class="toast-container">
        <div class="toast fade" c-class="{show:open}">
            <content></content>
        </div>
    </div>
    `
  props = ['open']
  open = false;
  constructor() {
    current_toast = this
  }
}

class ToastHeader {
  static template = /*html*/`
    <div class="toast-header">
      <content></content>
    </div>
  `
  toast: Toast
  constructor() {
    this.toast = current_toast
  }
}

class ToastBody {
  static template = /*html*/`
    <div class="toast-body">
        Hello, world! This is a toast message.
    </div>
    `
  toast: Toast
  constructor() {
    this.toast = current_toast
  }
}

export default { toast: Toast, 'toast-header': ToastHeader }