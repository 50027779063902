import { layout } from "../components/layout";
import { component, ConstructorComponent, release_component, start_component } from "./component";
import { dialog } from "./dialog";
import { event_handler, on_ready } from "./event";
import { Form } from "./form";
import { Obj } from "./obj";
import { Search } from "./search";
import session from "./session";

export interface Route {
    path: string
    source: ConstructorComponent
}
interface Instance {
    path: string
    context: any
    form: HTMLElement
}
export class Router extends Obj {
    private routes: { [index: string]: Route } = {}
    private current: Instance;
    private modal_pending: Instance[] = []

    constructor() {
        super();
        //  manejando errores
        this.on('error', (e) => console.info(e))
    }

    start() {
        if (!Object.keys(this.routes).length) return;
        // haciendo que se cargue la direccion actual
        on_ready(() => {
            this.go(document.location.pathname)
            window.addEventListener('popstate', (event) => {
                this.go(document.location.pathname)
            });
        })
    }

    add(...args: Route[][]) {
        for (let routes of args) {
            for (let route of routes) {
                this.routes[route.path] = route
            }
        }
    }

    private async instance(path: string, args: any) {

        path = path || '/'
        //  si no se ha definido la direccion

        if (!this.routes[path]) {
            console.error(`go: no esta registrada la direccion ${path}`)
            return
        }
        if (path != '/login' && path != '/registro' && path != '/coordenadas' && !session.logged()) {
            await this.modal('/login')
        }

        //  verificando que si esta logueado no pueda ingresar a login
        else if (path == '/login' && session.logged()) {
            path = '/'
        }

        //  si ya esta en la direccion
        if (this.current && this.current.path == path) return Promise.resolve()


        //  crea la nueva ventana
        var form = start_component(this.routes[path].source);

        var context = component<Form>(form)

        var view = document.getElementById('router-view')

        return {

            go: () => {
                //  quitando la anterior
                if (this.current) {
                    release_component(this.current.form)
                    this.current.form.remove()
                }

                //  agrega la nueva ventana
                this.current = { path, form, context };
                view.append(form)
                if (document.location.pathname.toLowerCase() != path.toLowerCase()) history.pushState({}, path, path)

                //  actualizando el modo del layout
                layout.mode = context.fullscreen ? 'fullscreen' : 'normal'
                this.profile(context)
                if (context.init) context.init()
            },

            modal: async () => {

                //  verificando si se especifico el metodo modal
                if (context.init instanceof Function == false) return console.error('router.modal: No ha especificado un metodo modal para formulario')

                //  removiendo el formulario montado actualmente
                if (this.modal_pending[0]) {
                    this.modal_pending[0].form.remove()
                } else if (this.current) this.current.form.remove();

                view.append(form)

                //  agregando el nuevo formulario al conjunto modales pendientes
                this.modal_pending.unshift({ path, form, context })

                //  actualizando el modo del layout
                layout.mode = context.fullscreen ? 'fullscreen' : 'modal'
                this.profile(context)

                //  ejecuntado modal y esperando respuesta 
                try {
                    var promise = context.init(args)
                    if (promise instanceof Promise == false) return console.error('router.modal: el metodo init debe devolver una promesa')
                    var fail = false
                    var result = await promise
                } catch (e) {
                    console.error(e)
                    fail = true
                }

                //  desmontando formulario
                var release = this.modal_pending.shift();
                release.form.remove()
                release_component(release.form)

                //  si lo anterior era modal
                if (this.modal_pending[0]) {
                    view.append(this.modal_pending[0].form)
                    this.profile(this.modal_pending[0].context)
                }

                //  para un formulario normal
                else {

                    if (this.current) {
                        view.append(this.current.form)
                        this.profile(this.current.context)
                    }
                    layout.mode = context.fullscreen ? 'fullscreen' : 'normal'
                }

                if (fail) return Promise.reject()
                return result
            }
        }
    }

    profile(context: Form) {
        layout.title = context.title || context.dom.getAttribute('title') || 'Sistema'
        layout.search.active = !!context.active_search
        layout.camera.display = !!context.active_camera
        layout.note.display = !!context.active_note
        // layout.setting.active = !!context.active_setting
        layout.download.active = !!context.active_download
        layout.upload.active = !!context.active_upload
        layout.print.active = !!context.active_print
        // layout.upload.active = !!context.active_upload
        layout.add.active = !!context.active_add
        layout.filter.active = !!context.active_filter
        layout.warning.active = !!context.active_warning

    }

    async modal(path: string, args?: any) {
        var instance = await this.instance(path, args)
        if (instance) return await instance.modal()
    }

    async go(path: string, args?: any, modal?: boolean) {
        layout.menu.open = false
        var instance = await this.instance(path, args)
        if (instance) return instance.go()
    }

    async exec(action: string, arg: any = {}) {
        var r;
        var context;

        if (this.modal_pending[0]) context = this.modal_pending[0].context
        else if (this.current) context = this.current.context
        if (!context) return

        // if (typeof context['on_' + action] == 'function') await context['on_' + action](arg)
        await event_handler(context).trigger(action, arg);

        if (action == 'logoff') {

            await dialog.confirm('Esa seguro que desea cerra session?', 'Cerrando session')
            session.logoff()
            location.assign('/')
        }
    }
}


export const router = new Router()