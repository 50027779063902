import { PreparedStatement } from 'mssql'
import { Data } from '../../../api/data'
import { create_dialog, dialog } from '../../../api/dialog'
import { form } from '../../../api/form'
import { Obj } from '../../../api/obj'
import { router } from '../../../api/router'
import session from '../../../api/session'
import { source } from '../../../api/source'
import { ocopy } from '../../../api/util'
import { layout } from '../../../components/layout'
import { get_impresora, set_impresora } from '../../sistema/impresion/impresion.seleccionar-impresora'
import { Info_orden } from './wms.despacho.class'
import template from './wms.despacho.recoger.html'
import mail_template from './wms.preentrega.html'


export default class extends Obj {
    static template = template
    orden: string = ''
    barra: string = ''
    lista: [] = []
    cierre = new Info_orden()
    solicitar_confirmacion: boolean = false
    confirmacion: string = ''
    hay_diferencias: boolean = false
    completo: boolean = false
    valijas: { id: string, display: string }[] = []
    valija_actual: string = '1'
    serial_recogido: string = ''
    layout = layout
    cerrar_orden_modal = false
    init(x: any) {
        this.orden = x.orden

        this.layout.title = x.numero
        this.carga_valijas()
        this.request()
        // this.procesar()
        return new Promise((ok, fail) => {

            this.on('accept', () => this.confirmar_orden())

            this.on('cerrar', ok)
            this.on('cancel', fail)
        })
    }
    async request() {
        var r = await source().request('/wms.despacho/list-merc', { orden: this.orden })
        ocopy(r, this.lista)
        if (this.lista.length >= 0) this.completo = false
        else {
            this.completo = true
            this.notify('')
        }

    }
    async valid() {
        this.notify('Por favor espere...')
        var value = this.barra
        this.barra = '';

        try {

            if (this.serial_recogido == '') {

            }

            var r = await source().request('/wms.despacho/recoger', { orden: this.orden, serial: value, valija: this.valija_actual, ultima_valija: this.valijas[this.valijas.length - 1] })



            this.request()

            this.notify(r)

        } catch (e) {
            if (!e.error) {
                this.error(e)
            }
            if (e.serial) {
                if (e.mode == 'paleta') {
                    if (this.serial_recogido == '') {
                        this.serial_recogido = e.serial
                        this.error('Esta paleta tiene productos recogidos, para remover de la orden escanee de nuevo')

                    } else if (value == this.serial_recogido) {

                        var r = await source().request('/wms.despacho/remover-paleta', { serial: this.serial_recogido })
                        this.notify(r)
                        this.serial_recogido = ''

                    }

                }
                if (e.mode == 'serial') {
                    if (this.serial_recogido == '') {
                        this.serial_recogido = e.serial
                        this.error('Este serial ya fue recogido, para remover de la orden escanee de nuevo')

                    } else if (value == this.serial_recogido) {

                        var r = await source().request('/wms.despacho/remover-serial', { serial: this.serial_recogido })
                        this.notify(r)
                        this.serial_recogido = ''
                        this.request()

                    }
                }
            }

        }
        this.request()

    }

    async paleta_despacho() {
        try {
            var impresora = await get_impresora()
            if (!impresora) impresora = await set_impresora()

            var r = await source().request('/wms.inventario/crear-paleta', { impresora })
            this.notify(r)
        } catch (error) {
            this.error(error)
        }

    }

    async remover_serial() {
        var r = await source().request('/wms.despacho/remover-serial', { serial: this.serial_recogido })
        this.notify(r)
        this.serial_recogido = ''
        this.request()

    }

    async carga_valijas() {
        var r = await source().request('/wms.despacho/carga-valijas', { orden: this.orden })
        if (r.lenght) {
            for (let valija of r) {
                this.valijas.push({ id: valija.valija_numero, display: 'Valija ' + valija.valija_numero })
            }

        } else {
            this.valijas.push({ id: "1", display: 'Valija 1' })

        }

    }

    async agrega_valija() {
        var r: any = this.valijas[this.valijas.length - 1].id
        r = parseInt(r)
        this.valijas.push({ id: (r + 1), display: `Valija ${r + 1}` })

    }

    async confirmar_orden() {
        this.notify('')
        this.cierre.request(this.orden)
        this.cerrar_orden_modal = true

    }

    async cerrar() {
        if (this.confirmacion == this.cierre.referencia || session.user.id == 'pedromateoa') {

            var impresora = await get_impresora()
            if (!impresora) impresora = await set_impresora()

            await source().request('/wms.despacho/cerrar-orden', { orden: this.orden, completo: this.completo, impresora: impresora, mail_template: mail_template })

            this.cerrar_orden_modal = false
            this.trigger('cerrar')

        } else {
            return Promise.reject('La confirmacion no coincide!')
        }
    }

    async mover() {
        router.modal('/wms/inventario-movimiento')

    }

    async procesar() {
        var ordenes = await source().request('/wms.despacho/procesar')

        for (const orden of ordenes) {
            await source().request('/wms.despacho/cerrar-orden', { orden: orden.id, completo: false })

        }

    }

}