import usuario_search from './usuario/usuario.search'
import usuario_edit from './usuario/usuario.edit'
import perfil_search from './perfil/perfil.search'
import perfil_edit from './perfil/perfil.edit'
import login from './login/login.form'
import { router } from '../../api/router'
import camera from './camera/camera.form'
import nota from './nota/nota.form'
import firma from './firma/firma'

// import coordenadas from './coordenadas/coordenadas.form'

router.add([
    { path: '/login', source: login },
    { path: '/usuario/search', source: usuario_search },
    { path: '/usuario/edit', source: usuario_edit },
    { path: '/perfil/search', source: perfil_search },
    { path: '/perfil/edit', source: perfil_edit },
    { path: '/camera', source: camera },
    { path: '/nota', source: nota },
    { path: '/firma', source: firma },
    // { path: '/coordenadas', source: coordenadas },
])
