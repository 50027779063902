import { Type } from "../../../api/type"
import { perfil_list } from "../perfil/perfil.list"
import { Table } from "../../../api/table"

export class Usuario extends Table {

    nombre = new Type.String({ required: true })
    email = new Type.String({ required: true })
    pass = new Type.String({ required: true })
    perfil = new Type.Link({ title: 'Perfil', required: true, list: perfil_list })
    confirmar = new Type.String({ no_update: true })
    inactivo = new Type.Boolean()
    entidad = new Type.Link()
    imagen = new Type.String()

    constructor() {
        super('usuario')
        // this.add_unique('email', ['email'])

        const parent = this;

        //  validando la confirmacion de la clave
        this.confirmar.add_rule(function () {
            if (parent.pass.value != parent.confirmar.value) return Promise.reject('La confirmacion del Passsword es incorrecta');
            return Promise.resolve()
        })
    }
}
