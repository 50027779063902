import { rejects } from "assert";
import { SSL_OP_NO_COMPRESSION } from "constants";
import { serialize } from "v8";
import { form } from "../../../api/form";
import { Obj } from "../../../api/obj";
import { Search } from "../../../api/search";
import session from "../../../api/session";
import { source } from "../../../api/source";
import { Type } from "../../../api/type";
import { replace_array } from "../../../api/util";
import { layout } from "../../../components/layout";
import { Serial, Ubicacion } from "../inventario/wms.inventario.class";
import template from "./wms.movimiento.form.html"



export default class extends Obj {
    static template = template
    mode: 'Tomar' | 'Colocar' = 'Tomar'

    title = "Movimiento"

    tracking = new Search('wms.inventario/user-tracking')
    ubicacion = new Ubicacion()
    layout = layout
    barra = new Type.String();

    colocar_directo: string;


    paleta_confirmar_recogida = ''
    ubicacion_actual = ''
    session = session
    init(x) {
        this.request()
        this.tracking.request()
        if (x) this.colocar_directo = x.serial;

        if (this.colocar_directo) this.mode = 'Colocar'

        return form.done(this)

    }

    async colocar_todo() {
        this.notify('Por favor espere...')
        try {
            var r = await source().request('/wms.movimiento/colocar-todo', { destino: this.ubicacion_actual })
            this.notify(r)
        } catch (e) {
            this.error(e)
        }
        this.request()
    }


    async tomar_todo() {
        try {
            var r = await source().request('/wms.movimiento/tomar-todo', { origen: this.ubicacion_actual })
            this.notify(r)
        } catch (e) {
            this.error(e)
        }
        // Cuando toma va al inventario del usuario para que vea lo que tomo
        this.cargar_usuario()
    }


    async cargar_usuario() {
        this.mode = 'Tomar'
        this.ubicacion_actual = ''
        this.notify('')
        this.request()
    }

    async request() {
        this.tracking.request()
        this.ubicacion.request(this.ubicacion_actual || session.user.id)
    }

    async valid() {

        //  obtiene el valor a consultar y limpia el input
        this.notify('')
        this.notify('Por favor espere...')


        var value = this.barra.value
        this.barra.value = ''

        //  si se paso un parametro por el init indicando colocar un serial
        //  un serial, esto actualmente se hace de la ventana de inventario
        if (this.colocar_directo) {
            try {
                var r = await source().request('wms.movimiento/colocar', {
                    serial: this.colocar_directo,
                    hasta: value,
                    concepto: 'Movimiento'
                })
                this.notify(r)
                this.trigger('accept', r)
            }
            catch (e) {
                this.error(e)
            }
            return;
        }

        //  realiza un request general para determinar de que se trata el requerimiento
        var request: { serial: Serial, ubicacion: Ubicacion, usuario: Ubicacion } = await source().request('/wms.inventario/consulta-serial', { serial: value })



        //  si no es un seria ni una ubicacion
        if (!request.serial && !request.ubicacion) {
            this.error('No existe este serial')
            return
        }

        if (request.serial) {
            if (request.serial.contenedor == session.user.id) {
                this.error('Ya lo tienes cargado')
                return
            }

        }

        try {

            //  condiciones para tomar
            if (
                //   si es un serial y no lo tiene cargado el usuario
                (request.serial && (request.serial.contenedor != session.user.id && request.serial.ubicacion != session.user.id))
                ||
                //  si esta confirmando que quiere recoger una paleta
                (request.ubicacion && this.ubicacion_actual == request.ubicacion.id && request.ubicacion.tipo == 'PALETA')
            ) {

                try {
                    var r = await source().request('/wms.movimiento/tomar', { serial: value, concepto: 'Movimiento' })
                    this.cargar_usuario()
                    this.request()
                    this.notify(r)

                } catch (e) {
                    this.error(e)
                }

            }

            //  condiciones para colocar
            else if (

                //  ya tengo mi ubicacion actual 
                !!this.ubicacion_actual
                && (

                    //  si es un serial y lo tengo cargado
                    (request.serial && (request.serial.contenedor == session.user.id || request.serial.ubicacion == session.user.id))
                    ||
                    //  si es una paleta y la tengo cargada
                    (request.ubicacion && request.ubicacion.contenedor == session.user.id)
                )
            ) {

                try {
                    var r = await source().request('/wms.movimiento/colocar', { serial: value, hasta: this.ubicacion_actual, concepto: 'Movimiento' })
                    this.notify(r)
                    this.request()

                } catch (e) {
                    this.error(e)
                }


            }

            //  cambiando a modo colocar
            else if (request.ubicacion) {

                this.mode = 'Colocar'
                this.ubicacion_actual = request.ubicacion.id
                this.request()
                if (request.ubicacion.tipo == 'PALETA') {
                    this.notify('Escanear de nuevo para recoger')
                } else {
                    this.notify('Escanee lo que desea colocar')
                }
            }

        } catch (e) {
            if (e) this.error(e)
        }

    }
}