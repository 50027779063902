const http = require('node-fetch');
import AbortController from 'abort-controller';

import { apromise } from './apromise';
import session from './session';
import { source, Source } from './source';

export class Http implements Source {


    constructor(readonly url: string = '') { }

    request(url: string, x: any = {}) {
        if (x && typeof x != 'object') throw 'http.request: debe pasar un objeto como parametro'
        const parent = this;

        return apromise(function (ok, fail, cancel) {

            const controller = new AbortController();
            cancel(function () {
                controller.abort()
            })

            var headers: any = {}

            if (!x.append) {
                x = JSON.stringify(x)
                headers['Content-Type'] = 'application/json'
            }

            var token = session.token
            if (token) headers.Authorization = 'Bearer ' + token;
            var status;
            http(new URL(url, parent.url), {
                method: 'POST',
                signal: controller.signal,
                body: x,
                headers
            })
                .then(async function (r) {
                    status = r.status
                    var content_type = r.headers.get('content-type')
                    if (content_type && content_type.indexOf('json') > -1) {
                        return r.json()
                    }
                    return r.text()
                })

                .then(function (r) {
                    if (status != 200) {
                        return Promise.reject(r)
                    }
                    return r;
                })
                .then(ok)
                .catch(function (e) {
                    console.error(e)
                    fail(e)
                })
        })


    }
}

export async function upload(files: File[] | FileList) {
    var data = new FormData();
    for (let i in files) {
        data.append(files[i].name, files[i]);
    }
    return await source().request('/upload', data)
}
