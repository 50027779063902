import { Table } from "../../../api/table";
import { Type } from "../../../api/type";
import { tentidad_list } from "../tentidad/tentidad.list";
import { tdoc_list } from "./entidad.list";

import { tentidad_face } from "../../entidad/tentidad/tentidad.class";
import { zona_face } from "../zona/zona.class";

export interface entidad_face {
    id: string
    tipo: tentidad_face
    nombre: string
    direccion: string
    tel_principal: string
    tel_directo: string
    tel_privado: string
    email: string
    tdoc: string
    doc: string
    ncf: string
    exento: string
    vendedor: string
    entidad: string
    inactivo: string
    listap: string
    zona: zona_face
    datos: string
    razon_social: string
    geo_reference: string
    sync: string
    cargo: string
    imagen: string
}
export class Entidad extends Table {
    nombre = new Type.String()
    tipo = new Type.Link({ list: tentidad_list })
    tdoc = new Type.Link({ list: tdoc_list })
    direccion = new Type.String()
    tel_principal = new Type.String()
    tel_directo = new Type.String()
    tel_privado = new Type.String()
    email = new Type.String()
    doc = new Type.String()
    inactivo = new Type.Boolean()
    imagen = new Type.String()
    constructor(table) {
        super(table)
    }
}