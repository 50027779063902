export function watch(target: any, prop: string, fn: Function) {

    //	si no existe la variable donde se almacenaran todos los disparadores
    if (!target.__triggers) Object.defineProperty(target, '__triggers', { enumerable: false, value: {} });

    //	si no esta el apartado para la propiedad
    if (!target.__triggers[prop]) {
        var value = target[prop];

        //	se define el objeto almacenador
        Object.defineProperty(target.__triggers, prop, { enumerable: false, value: { no_recuseive: false, fn: [] } })

        var descriptor = Object.getOwnPropertyDescriptor(target, prop)

        //	se redefine la proiedad con getters y setters que permitan el bind
        Object.defineProperty(target, prop, {
            get() {
                if (descriptor && descriptor.get) return descriptor.get()

                return value;
            },
            set(v) {
                if (descriptor && descriptor.set) {
                    descriptor.set(v)
                } else {
                    value = v;
                }
                if (target.__triggers[prop].no_recuseive) return

                target.__triggers[prop].no_recuseive = true
                target.__triggers[prop].fn.forEach((x: any) => x(v))
                target.__triggers[prop].no_recuseive = false
            }
        })

    }
    //	almacena el trigger
    target.__triggers[prop].fn.push(fn)

    //	los dispara de forma inicial
    //fn()

}
export function array_watch(x: any[], fn: Function) {
    var target: any = x;

    //	si no existe la variable donde se almacenaran todos los disparadores
    if (!target.__triggers) {
        Object.defineProperty(target, '__triggers', { enumerable: false, value: {} });

        //	contiene todas las funciones que afectan a un arreglo
        var m = ['push', 'pop', 'shift', 'unshift', 'copyWithin', 'fill', 'reverse', 'sort', 'splice']

        //	sobre escribe las funciones de manera que estas al ser invocadas 
        //	dispare los eventos almacenados 
        m.forEach((i: any) => {
            Object.defineProperty(target, i, {
                enumerable: false, value: function () {
                    var r = Array.prototype[i].apply(target, arguments);
                    target.__triggers.length.forEach((x: any) => x())
                    return r;
                }
            })
        })
    }
    //	almacena el evento
    target.__triggers.length = target.__triggers.length || []
    target.__triggers.length.push(fn)

}
